import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosStar } from "react-icons/io";
import { RiMenu2Fill } from "react-icons/ri";
import { VscAccount, VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";

import { OpenNavBurger } from "../../Redux/actions/style.action";
import ListingVeloNavShopify from "./listingVeloNavShopify";
import "../Header/header.css";
import logovelomad from "../../Assets/velomad.png";
import posterVideoConcept from "../../Assets/posterVideoConcept.jpg";
import videoConcept from "../../Assets/videoConcept.mp4";
import imgNouveaute from "../../Assets/zoom-urban-elite-profil-lifestyle.jpg";
import imgCasque from "../../Assets/casque.png";
import imgBagage from "../../Assets/bagages.png";
import imgAntivol from "../../Assets/antivol.jpeg";

export default function HeaderShopifyVelomad() {
    const dispatch = useDispatch();
    const openNavBurger = useSelector((state) => state.style.openNavBurger);

    const [showVelo, setShowVelo] = useState(true);
    const [showAccessoire, setShowAccessoire] = useState(false);
    const [showQui, setShowQui] = useState(false);
    const [showAide, setShowAide] = useState(false);
    const [showLoc, setShowLoc] = useState(false);
    const [showRecond, setShowRecond] = useState(false);

    const [showNavVelo, setShowNavVelo] = useState(false);
    const [showNavAcc, setShowNavAcc] = useState(false);
    const [showNavLoc, setShowNavLoc] = useState(false);
    const [showNavRecond, setShowNavRecond] = useState(false);

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const detectDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        if (window.innerWidth > 1050) {
            dispatch(OpenNavBurger(false));
        }
    };
    // load on charge page
    useEffect(() => {
        window.addEventListener("load", detectDimension);
        return () => {
            window.removeEventListener("load", detectDimension);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // refresh calcul on screen resize
    useEffect(() => {
        window.addEventListener("resize", detectDimension);
        return () => {
            window.removeEventListener("resize", detectDimension);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSize]);

    return (
        <header>
            <nav className="nav-desktop">
                <div className="nav-left">
                    <a
                        href="https://levelomad.com"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(true);
                            setShowNavAcc(false);
                            setShowNavLoc(false);
                            setShowNavRecond(false);
                        }}
                    >
                        <img
                            src={logovelomad}
                            alt="Logo - VeloMad"
                            className="logo_velo"
                        />
                    </a>
                    <a
                        href="https://levelomad.com/"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(true);
                            setShowNavAcc(false);
                            setShowNavLoc(false);
                            setShowNavRecond(false);
                        }}
                    >
                        Vélos électriques
                    </a>
                    <a
                        href="https://levelomad.com/collections/velos-doccasions"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(false);
                            setShowNavAcc(false);
                            setShowNavLoc(false);
                            setShowNavRecond(true);
                        }}
                    >
                        Vélos reconditionnés
                    </a>
                    <a
                        href="https://levelomad.com/collections/accessoires"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(false);
                            setShowNavAcc(true);
                            setShowNavLoc(false);
                            setShowNavRecond(false);
                        }}
                    >
                        Accessoires
                    </a>
                    <a
                        href="https://levelomad.com/pages/concept"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(false);
                            setShowNavAcc(false);
                            setShowNavLoc(false);
                            setShowNavRecond(false);
                        }}
                    >
                        We are Mad
                    </a>

                    <a
                        href="https://levelomad.com/pages/location-longue-duree"
                        rel="noreferrer noopener"
                        onMouseOver={() => {
                            setShowNavVelo(false);
                            setShowNavAcc(false);
                            setShowNavLoc(true);
                            setShowNavRecond(false);
                        }}
                    >
                        Location
                    </a>
                    <a
                        className="mm-header-avis-client "
                        href="https://levelomad.com/pages/tous-les-avis"
                    >
                        <div className="note">
                            4.8
                            <span>
                                <IoIosStar />
                            </span>
                        </div>
                    </a>
                </div>
                <div className="nav-right">
                    <a href="https://maps.levelomad.com/">Essayer le vélo</a>
                    <a
                        href="https://levelomad.com/pages/concept"
                        rel="noreferrer noopener"
                        className="mm-btn-border-white"
                    >
                        Configurer mon vélo
                    </a>
                    <a
                        href="https://levelomad.com/account"
                        rel="noreferrer noopener"
                        className="link-account"
                    >
                        <VscAccount />
                    </a>
                </div>
            </nav>
            {showNavVelo === true && (
                <div
                    className="sub_menu"
                    onMouseLeave={() =>
                        setTimeout(() => setShowNavVelo(false), 200)
                    }
                >
                    <div className="nav-velo-desktop">
                        <div className="nav-velo-desktop-listing">
                            <hr />
                            <h2>VÉLOS ÉLECTRIQUES</h2>
                            <ListingVeloNavShopify />
                            <div className="link-comparateur">
                                <a
                                    href="https://levelomad.com/products/copy-of-light"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Comparateur
                                </a>
                                {" | "}
                                <a
                                    href="https://maps.levelomad.com"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Essayer le vélo
                                </a>
                            </div>
                        </div>
                        <div className="nav-velo-desktop-video">
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <video
                                    playsInline="playsinline"
                                    loop="loop"
                                    autoPlay="autoplay"
                                    muted="muted"
                                    preload="metadata"
                                    poster={posterVideoConcept}
                                >
                                    <source
                                        src={videoConcept}
                                        type="video/mp4"
                                    />
                                    <img src={posterVideoConcept} alt="velos" />
                                </video>
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Pourquoi nous ?</h2>
                                    <p>
                                        Découvrez nos vélos et leurs secrets de
                                        fabrication. Un savoir faire français et
                                        expert.
                                    </p>
                                </div>
                            </a>
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <img src={imgNouveaute} alt="velos" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nouveauté</h2>
                                    <p>Découvrez nos nouveaux vélos premium</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {showNavAcc === true && (
                <div
                    className="sub_menu-acc"
                    onMouseLeave={() =>
                        setTimeout(() => setShowNavAcc(false), 200)
                    }
                >
                    <div
                        className="nav-acc-desktop"
                        onMouseLeave={() =>
                            setTimeout(() => setShowNavAcc(false), 200)
                        }
                    >
                        <div className="nav-acc-desktop-listing">
                            <h2>Accessoires</h2>

                            <div className="link-acc">
                                <a
                                    href="https://levelomad.com/collections/casques"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Casques
                                </a>

                                <a
                                    href="https://levelomad.com/collections/antivols"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Antivols
                                </a>
                                <a
                                    href="https://levelomad.com/collections/bagagerie"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Bagagerie
                                </a>
                                <a
                                    href="https://levelomad.com/collections/nos-accessoires"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Accessoires
                                </a>
                                <a
                                    href="https://levelomad.com/collections/pieces-detachees-1"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Pièces Détachées
                                </a>
                                <div className="button-acc">
                                    <a
                                        href="https://levelomad.com/collections/accessoires"
                                        rel="noreferrer noopener"
                                    >
                                        Tous les accessoires
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="nav-velo-desktop-video">
                            <a
                                href="https://levelomad.com/collections/casques"
                                rel="noreferrer noopener"
                            >
                                <img src={imgCasque} alt="casque" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nos Casques</h2>
                                    <p>
                                        Nos casques, en plus d'assurer votre
                                        sécurité, vous irons à merveille.
                                    </p>
                                </div>
                            </a>
                            <a
                                href="https://levelomad.com/collections/bagagerie"
                                rel="noreferrer noopener"
                            >
                                <img src={imgBagage} alt="bagages" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nos Bagages</h2>
                                    <p>
                                        Mettez toutes les chances de votre côté
                                        pour ne rien oublier.
                                    </p>
                                </div>
                            </a>
                            <a
                                href="https://levelomad.com/collections/antivols"
                                rel="noreferrer noopener"
                            >
                                <img src={imgAntivol} alt="antivols" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nos Antivols</h2>
                                    <p>
                                        La sécurité c'est important, pour toi,
                                        mais aussi pour ton vélo.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {showNavLoc === true && (
                <div
                    className="sub_menu-acc"
                    onMouseLeave={() =>
                        setTimeout(() => setShowNavLoc(false), 200)
                    }
                >
                    <div
                        className="nav-velo-desktop"
                        onMouseLeave={() =>
                            setTimeout(() => setShowNavLoc(false), 200)
                        }
                    >
                        <div
                            className="nav-acc-desktop-listing"
                            style={{ width: "50%" }}
                        >
                            <h2>Location</h2>

                            <div
                                className="link-loc"
                                style={{ height: "320px" }}
                            >
                                <a
                                    href="https://levelomad.com/pages/location-longue-duree-en-entreprise-velo-de-fonction"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    <div>
                                        <h3>
                                            Location longue durée en entreprise
                                        </h3>
                                        <p>
                                            Profitez d'un vélo de fonction pour
                                            vos déplacement maison-travail mais
                                            aussi pour votre usage personnel.
                                        </p>
                                    </div>
                                </a>

                                <a
                                    href="https://levelomad.com/pages/location-longue-duree"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    <div>
                                        <h3>
                                            Location longue durée particulier
                                        </h3>
                                        <p>
                                            Louez votre Velomad sur 2 ou 3 ans.
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className="link-comparateur">
                                <a
                                    href="https://levelomad.com/products/copy-of-light"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Comparateur
                                </a>
                                {" | "}
                                <a
                                    href="https://maps.levelomad.com"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Essayer le vélo
                                </a>
                            </div>
                        </div>
                        <div className="nav-velo-desktop-video">
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <video
                                    playsInline="playsinline"
                                    loop="loop"
                                    autoPlay="autoplay"
                                    muted="muted"
                                    preload="metadata"
                                    poster={posterVideoConcept}
                                >
                                    <source
                                        src={videoConcept}
                                        type="video/mp4"
                                    />
                                    <img src={posterVideoConcept} alt="velos" />
                                </video>
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Pourquoi nous ?</h2>
                                    <p>
                                        Découvrez nos vélos et leurs secrets de
                                        fabrication. Un savoir faire français et
                                        expert.
                                    </p>
                                </div>
                            </a>
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <img src={imgNouveaute} alt="velos" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nouveauté</h2>
                                    <p>Découvrez nos nouveaux vélos premium</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {showNavRecond === true && (
                <div
                    className="sub_menu-acc"
                    onMouseLeave={() =>
                        setTimeout(() => setShowNavRecond(false), 200)
                    }
                >
                    <div
                        className="nav-velo-desktop"
                        onMouseLeave={() =>
                            setTimeout(() => setShowNavRecond(false), 200)
                        }
                    >
                        <div
                            className="nav-acc-desktop-listing"
                            style={{ width: "50%" }}
                        >
                            <h2>VÉLOS RECONDITIONNÉS</h2>

                            <div
                                className="link-loc"
                                style={{ height: "320px" }}
                            >
                                <a
                                    href="https://levelomad.com/collections/velos-doccasions"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    <div>
                                        <h3>Nos vélos reconditionnées</h3>
                                        <p>
                                            Découvrez nos vélos d'occasion prêt
                                            à être réutilisés
                                        </p>
                                    </div>
                                </a>

                                <a
                                    href="https://levelomad.com/pages/nous-reprenons-votre-velomad"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    <div>
                                        <h3>Nous reprenons votre Velomad</h3>
                                        <p>
                                            Vous désirez faire reprendre votre
                                            Velomad, faites nous une demande
                                            d'estimation
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className="link-comparateur">
                                <a
                                    href="https://levelomad.com/products/copy-of-light"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Comparateur
                                </a>
                                {" | "}
                                <a
                                    href="https://maps.levelomad.com"
                                    rel="noreferrer noopener"
                                    className="link-burger-velo"
                                >
                                    Essayer le vélo
                                </a>
                            </div>
                        </div>
                        <div className="nav-velo-desktop-video">
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <video
                                    playsInline="playsinline"
                                    loop="loop"
                                    autoPlay="autoplay"
                                    muted="muted"
                                    preload="metadata"
                                    poster={posterVideoConcept}
                                >
                                    <source
                                        src={videoConcept}
                                        type="video/mp4"
                                    />
                                    <img src={posterVideoConcept} alt="velos" />
                                </video>
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Pourquoi nous ?</h2>
                                    <p>
                                        Découvrez nos vélos et leurs secrets de
                                        fabrication. Un savoir faire français et
                                        expert.
                                    </p>
                                </div>
                            </a>
                            <a
                                href="https://levelomad.com/pages/concept"
                                rel="noreferrer noopener"
                            >
                                <img src={imgNouveaute} alt="velos" />
                                <div className="nav-velo-desktop-video-content">
                                    <h2>Nouveauté</h2>
                                    <p>Découvrez nos nouveaux vélos premium</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}

            <nav className="nav-mobile">
                <div
                    className="nav-icon-burger"
                    onClick={() => dispatch(OpenNavBurger(!openNavBurger))}
                >
                    {openNavBurger === false ? <RiMenu2Fill /> : <VscClose />}
                </div>
                <a href="https://levelomad.com" rel="noreferrer noopener">
                    <img
                        src={
                            "https://levelomad.com/cdn/shop/files/Group_79.png?v=1685631931&width=150"
                        }
                        alt="Logo - VeloMad"
                        className="logo_velo"
                    />
                </a>
                <a
                    href="https://levelomad.com/account"
                    rel="noreferrer noopener"
                    className="link-account-mobile"
                >
                    <VscAccount />
                </a>
            </nav>

            <div
                className={
                    openNavBurger === true
                        ? "burger-menu  "
                        : "burger-menu burger-translate "
                }
            >
                <div
                    className={
                        showVelo === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(!showVelo);
                        setShowAccessoire(false);
                        setShowQui(false);
                        setShowAide(false);
                        setShowLoc(false);
                        setShowRecond(false);
                    }}
                >
                    <h2>VÉLOS ÉLECTRIQUES</h2>{" "}
                    <div className="arrow-burger">
                        {showVelo !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <ListingVeloNavShopify />
                </div>
                <div
                    className={
                        showRecond === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(false);
                        setShowAccessoire(false);
                        setShowQui(false);
                        setShowAide(false);
                        setShowLoc(false);
                        setShowRecond(!showRecond);
                    }}
                >
                    <h2>Vélos reconditionnées</h2>{" "}
                    <div className="arrow-burger">
                        {showRecond !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <div className="burgur-content-text">
                        <a
                            href="https://levelomad.com/collections/velos-doccasions"
                            rel="noreferrer noopener"
                        >
                            Nos vélos reconditonnés
                        </a>
                        <a
                            href="https://levelomad.com/pages/nous-reprenons-votre-velomad"
                            rel="noreferrer noopener"
                        >
                            Nous reprenons votre Velomad
                        </a>
                    </div>
                </div>
                <div
                    className={
                        showAccessoire === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(false);
                        setShowAccessoire(!showAccessoire);
                        setShowQui(false);
                        setShowAide(false);
                        setShowLoc(false);
                        setShowRecond(false);
                    }}
                >
                    <h2>ACCESSOIRES</h2>{" "}
                    <div className="arrow-burger">
                        {showAccessoire !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <div className="burgur-content-text">
                        <a
                            href="https://levelomad.com/collections/antivols"
                            rel="noreferrer noopener"
                        >
                            Antivols
                        </a>
                        <a
                            href="https://levelomad.com/collections/bagagerie"
                            rel="noreferrer noopener"
                        >
                            Bagagerie
                        </a>
                        <a
                            href="https://levelomad.com/collections/casques"
                            rel="noreferrer noopener"
                        >
                            Casques
                        </a>
                        <a
                            href="https://levelomad.com/collections/pieces-detachees-1"
                            rel="noreferrer noopener"
                        >
                            Pièces Détachées
                        </a>
                        <a
                            href="https://levelomad.com/collections/accessoires"
                            rel="noreferrer noopener"
                        >
                            Tous les accessoires
                        </a>
                    </div>
                </div>
                <div
                    className={
                        showQui === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(false);
                        setShowAccessoire(false);
                        setShowQui(!showQui);
                        setShowAide(false);
                        setShowLoc(false);
                        setShowRecond(false);
                    }}
                >
                    <h2>QUI SOMMES-NOUS ?</h2>{" "}
                    <div className="arrow-burger">
                        {showQui !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <div className="burgur-content-text">
                        <a
                            href="https://levelomad.com/pages/concept"
                            rel="noreferrer noopener"
                        >
                            We are Mad
                        </a>
                        <a
                            href="https://levelomad.com/pages/le-vae-officiel-du-tour-de-france"
                            rel="noreferrer noopener"
                        >
                            Le VAE Officiel
                        </a>
                        <a
                            href="https://levelomad.com/pages/reconditionnement-batteries"
                            rel="noreferrer noopener"
                        >
                            Reconditionnement de batteries
                        </a>
                    </div>
                </div>
                <div
                    className={
                        showAide === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(false);
                        setShowAccessoire(false);
                        setShowQui(false);
                        setShowAide(!showAide);
                        setShowLoc(false);
                        setShowRecond(false);
                    }}
                >
                    <h2>AIDE</h2>{" "}
                    <div className="arrow-burger">
                        {showAide !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <div className="burgur-content-text">
                        <a
                            href="https://levelomad.com/pages/service-apres-vente"
                            rel="noreferrer noopener"
                        >
                            Service après vente
                        </a>
                        <a
                            href="https://levelomad.com/pages/contact"
                            rel="noreferrer noopener"
                        >
                            Formulaire de contact
                        </a>
                        <a
                            href="https://levelomad.com/pages/rejoindre-l-equipe-mad"
                            rel="noreferrer noopener"
                        >
                            Rejoindre l'équipe
                        </a>
                    </div>
                </div>
                <div
                    className={
                        showLoc === true
                            ? "burger-velo burger-expand"
                            : "burger-velo"
                    }
                    onClick={() => {
                        setShowVelo(false);
                        setShowAccessoire(false);
                        setShowQui(false);
                        setShowAide(false);
                        setShowLoc(!showLoc);
                        setShowRecond(false);
                    }}
                >
                    <h2>LOCATION</h2>{" "}
                    <div className="arrow-burger">
                        {showLoc !== true ? (
                            <IoIosArrowDown />
                        ) : (
                            <IoIosArrowUp />
                        )}
                    </div>
                    <div className="burgur-content-text">
                        <a
                            href="https://levelomad.com/pages/location-longue-duree-en-entreprise"
                            rel="noreferrer noopener"
                        >
                            Location Longue Durée Entreprise
                        </a>
                        <a
                            href="https://levelomad.com/pages/location-longue-duree"
                            rel="noreferrer noopener"
                        >
                            Location Longue Durée Particulier
                        </a>
                    </div>
                </div>

                <div className="burger-location">
                    <a
                        href="https://levelomad.com/pages/store-locator"
                        rel="noreferrer noopener"
                    >
                        <h2>Essayer le vélo</h2>{" "}
                    </a>
                </div>
                <div className="menu-mobile-btn-bottom">
                    <a
                        href="https://levelomad.com/pages/store-locator"
                        className="link-border-left"
                        rel="noreferrer noopener"
                    >
                        Réserver un essai gratuit
                    </a>

                    <a
                        href="https://levelomad.com/products/copy-of-light"
                        rel="noreferrer noopener"
                    >
                        Configurer mon Vélo
                    </a>
                </div>
            </div>
        </header>
    );
}
