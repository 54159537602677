/* eslint-disable array-callback-return */
import "./form.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import { DropDownSelect } from "lt-dropdown-menu-jquery-to-react";
import { datacountry } from "./country";
import ImageUploader from "react-images-upload";

export default function Coordonnees(props) {
    const dataPanier = useSelector((state) => state.panierSav);
    const [totalAmount, setTotalAmount] = useState(0.0);
    const [sizeControlForm, setSizeControlForme] = useState(0);
    const etatState = useSelector((state) => state.user.etat);
    const garantieState = useSelector((state) => state.user.garantie);
    const [inputTextarea, setInputTextarea] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setTotalAmount(0);
        if (dataPanier !== undefined && dataPanier !== null) {
            dataPanier.map((piece) => {
                piece.id !== undefined &&
                    setTotalAmount(
                        (
                            parseFloat(totalAmount) +
                            parseFloat(piece.variants[0].price * 0.85)
                        ).toFixed(2)
                    );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPanier]);
    const dispatch = useDispatch();
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );
    const selectSku = useSelector((state) => state.veloDetails.modele);
    const sku = useSelector((state) => state.veloDetails.sku);
    const kilometrage = useSelector((state) => state.user.kilometrage);

    const garantieStatus = useSelector((state) => state.user.garantie);
    const garantieEtat = useSelector((state) => state.user.etat);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [confirmForm, setConfirmForm] = useState(false);

    const [confirmSend, setConfirmSend] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);

    const handleFileEvent = (e) => {
        setUploadedFiles(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("name", document.getElementById("name").value);
        formData.append("email", document.getElementById("email").value);
        formData.append("addresse", document.getElementById("addresse").value);
        formData.append("cp", document.getElementById("cp").value);
        formData.append("ville", document.getElementById("ville").value);

        if (props.type === "b2b") {
            formData.append(
                "refInterne",
                document.getElementById("refInterne").value
            );
        } else if (props.type === "reprise") {
            formData.append("refInterne", "Demande Reprise");
        } else {
            formData.append("refInterne", "Client B2C");
        }
        if (props.type !== "reprise") {
            formData.append(
                "livname",
                document.getElementById("livname").value
            );
            formData.append(
                "livaddresse",
                document.getElementById("livaddresse").value
            );
            formData.append("livcp", document.getElementById("livcp").value);
            formData.append(
                "livville",
                document.getElementById("livville").value
            );
            formData.append(
                "country",
                document.getElementById("country").getAttribute("value")
            );
            formData.append(
                "livcountry",
                document.getElementById("livcountry").getAttribute("value")
            );
        }

        formData.append(
            "inputDateBuy",
            document.getElementById("inputDateBuy").value
        );
        formData.append("garantie", garantieStatus);
        formData.append("etat", garantieEtat);
        formData.append("kilometrage", kilometrage);
        if (uploadedFiles !== undefined) {
            formData.append("files", uploadedFiles);
        }
        let numberImg = 1;
        if (uploadedFiles.length >= 1) {
            // eslint-disable-next-line array-callback-return
            uploadedFiles.map((element) => {
                formData.append(
                    "img" + numberImg,
                    uploadedFiles[numberImg - 1],
                    uploadedFiles[numberImg - 1].name
                );
                numberImg += 1;
            });
        }
        let msgArea = document.getElementById("msgArea").value;
        if (props.type !== "reprise") {
            const dataPanierId = [];
            if (dataPanier.length > 0) {
                dataPanier.map((element) => dataPanierId.push(element));
            }

            const dataPan = [];
            if (dataPanier !== undefined && dataPanier !== null) {
                // eslint-disable-next-line array-callback-return
                dataPanier.map((piece) => {
                    if (piece.id !== undefined) {
                        let newPiece = "";
                        newPiece += piece.id;
                        newPiece += " - ";
                        newPiece += piece.title;
                        if (
                            piece.variants !== undefined &&
                            piece.variants !== null &&
                            piece.variants.length >= 1
                        ) {
                            if (piece.variantSelected !== undefined) {
                                newPiece += " ## variant_id : ";
                                newPiece += piece.variantSelected;
                                piece.variants.map((variant) => {
                                    variant.id === piece.variantSelected &&
                                        piece.options[0].name !== "Title" &&
                                        variant.option1 !== "Default Title" &&
                                        (newPiece +=
                                            " - option - " +
                                            piece.options[0].name +
                                            " " +
                                            variant.option1);
                                });
                            }

                            newPiece += " - ";
                            if (garantieStatus === true) {
                                newPiece += " 0 EUR TTC - sous garantie";
                            } else {
                                newPiece += " hors-garantie ";
                            }
                        }
                        newPiece += " ---- ";
                        dataPan.push(newPiece);
                    } else {
                        dataPan.push(
                            "Type de dommage sélectionné: " + piece + " ---- "
                        );
                        msgArea +=
                            "Type de dommage sélectionné: " + piece + " ---- ";
                    }
                });
            }
            formData.append("dataPanier", dataPan);
            if (props.type === "b2c") {
                formData.append(
                    "dataPanier",
                    "Type de dommage sélectionné: " + dataPanier
                );
            }
            formData.append("totalAmount", totalAmount);
        }
        msgArea += "SKU : " + selectSku + " sku : " + sku;
        formData.append("msgArea", msgArea);
        formData.append("selectSku", selectSku + " sku : " + sku);

        async function PostCreateSav(formData) {
            try {
                toast("Demande en cours... Merci de patienter", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 30000,
                });
                setSendClicked(true);

                const response = await fetch(
                    `https://api.levelomad.com/api/sav/v1/create_new_request/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 400) {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.success("Envoi Réussie ! ✅", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    window.scrollTo(0, 360);
                    setConfirmSend(true);
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateSav(formData);
    };
    const controlForm = new Map();
    /// //// FUNCTION SIGNAL TO USER IF DATA SUCCESS OR DATA ERROR ///////////
    // set green background and clean attribute data-error
    const dataChecked = (element) => {
        element.classList.remove("bg-error");
        element.classList.add("form-checked");
    };

    // set red background  and set attribute data-error
    const dataError = (element) => {
        element.focus();
        element.classList.add("bg-error");
        element.classList.remove("form-checked");
    };
    const checkInputText = (element) => {
        if (element !== null && element.value.length < 2) {
            element.setAttribute(
                "data-error",
                `Veuillez entrer 2 caractères ou plus pour le champ ${element.previousElementSibling.innerText}`
            );
            element.focus();
            dataError(element);
            controlForm.delete(element.name);
        } else {
            dataChecked(element);
            controlForm.set(element.name, element.value);
        }
    }; // fct check value into input type="email"
    const checkValueEmail = (element) => {
        // control data with regex mail
        if (
            element !== null &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element.value)
        ) {
            controlForm.delete(element.name);
            element.setAttribute(
                "data-error",
                "Vous devez saisir une adresse mail valide."
            );
            dataError(element);
        } else {
            dataChecked(element);
            controlForm.set(element.name, element.value);
        }
    };
    const checkConfirm = (element) => {
        if (element.target.checked === true) {
            setConfirmForm(true);
            dataChecked(document.getElementById("confirmFormLabel"));
        } else {
            setConfirmForm(false);
            dataError(document.getElementById("confirmFormLabel"));
        }
    };

    const checkForm = () => {
        if (
            document.getElementById("msgArea").value !== null &&
            document.getElementById("msgArea").value.length > 2 &&
            (uploadedFiles.length >= 2) & (garantieState === true)
        ) {
            checkInputText(document.getElementById("name"));
            checkInputText(document.getElementById("addresse"));
            checkInputText(document.getElementById("cp"));
            checkInputText(document.getElementById("ville"));
            checkValueEmail(document.getElementById("email"));
        } else {
            checkInputText(document.getElementById("name"));
            checkInputText(document.getElementById("addresse"));
            checkInputText(document.getElementById("cp"));
            checkInputText(document.getElementById("ville"));
            checkValueEmail(document.getElementById("email"));
        }

        checkInputText(document.getElementById("msgArea"));
        setSizeControlForme(controlForm.size);

        return true;
    };
    return (
        <>
            {(dataPanier !== undefined &&
                dataPanier !== null &&
                dataPanier.length > 0 &&
                etatState !== null &&
                garantieState !== null) ||
            (props.type === "reprise" &&
                etatState !== null &&
                garantieState !== null &&
                kilometrage !== null) ? (
                <>
                    {confirmSend !== true ? (
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            encType="multipart/form-data"
                        >
                            <fieldset>
                                <legend>
                                    {garantieState === true ||
                                    props.type === "" ||
                                    props.type === "reprise" ? (
                                        <span>Pièces Justificatives :</span>
                                    ) : (
                                        <span>Commentaire</span>
                                    )}
                                </legend>
                                <div className="text-center">
                                    <div>
                                        <h3>
                                            {garantieState === true ||
                                            props.type === "" ? (
                                                <span>
                                                    Preuve d'achat et photo{" "}
                                                    {props.type !==
                                                        "reprise" && (
                                                        <>"du défault"</>
                                                    )}{" "}
                                                    <br />
                                                    de votre VELOMAD
                                                </span>
                                            ) : (
                                                <span>Envoi de documents</span>
                                            )}
                                        </h3>

                                        <div className="uploadfile">
                                            <label
                                                className="text-center"
                                                htmlFor="inputDateBuy"
                                            >
                                                Date d'achat :
                                            </label>
                                            <div className="my-2">
                                                <input
                                                    type="date"
                                                    name="date"
                                                    id="inputDateBuy"
                                                    required
                                                    max={
                                                        new Date()
                                                            .toISOString()
                                                            .split("T")[0]
                                                    }
                                                    className="text-center"
                                                />
                                            </div>
                                            <ImageUploader
                                                withIcon={true}
                                                label={
                                                    "Max 5 fichiers, 3MB par fichier, formats acceptés : jpg, jpeg, png, pdf"
                                                }
                                                buttonText="Envoyer plusieurs images"
                                                onChange={(e) =>
                                                    handleFileEvent(e)
                                                }
                                                accept={[
                                                    ".pdf",
                                                    ".jpeg",
                                                    ".jpg",
                                                    ".png",
                                                ]}
                                                withPreview={true}
                                                imgExtension={[
                                                    ".pdf",
                                                    ".jpeg",
                                                    ".jpg",
                                                    ".png",
                                                ]}
                                                fileSizeError={
                                                    "Taille de fichier trop grande Max 3MB"
                                                }
                                                fileTypeError={
                                                    "Type de fichier non autorisé"
                                                }
                                                maxFileSize={3145728}
                                            />
                                            <div className="text-center my-3">
                                                Si votre fichier est trop grand,
                                                voici un site gratuit de
                                                compression en ligne :{" "}
                                                <a
                                                    href="https://squoosh.app"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    squoosh.app
                                                </a>
                                            </div>
                                            {garantieState === true ||
                                            props.type === "" ||
                                            props.type === "reprise" ? (
                                                <>
                                                    <div className="parcoursSelected ">
                                                        Merci de joindre
                                                        OBLIGATOIREMENT votre
                                                        facture, des photos,
                                                        {props.type ===
                                                            "reprise" && (
                                                            <>
                                                                ainsi que la
                                                                photo du
                                                                Compteur
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    Vous pouvez nous joindre
                                                    votre facture, des photos si
                                                    vous le souhaitez
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <hr className="w-50 my-2" />
                                    {uploadedFiles.length > 0 && (
                                        <div className="files_uploaded">
                                            Fichiers prêts à envoyer :{" "}
                                            {uploadedFiles.map((element) => (
                                                <span key={element.name}>
                                                    {element.name}
                                                </span>
                                            ))}
                                            <hr />
                                        </div>
                                    )}
                                    <textarea
                                        type="text"
                                        name="description"
                                        placeholder={
                                            props.type === "reprise"
                                                ? "Un commentaire sur votre demande ? (max 1000 Caractères)"
                                                : "Expliquez-nous le défaut (max 1000 Caractères)"
                                        }
                                        id="msgArea"
                                        rows={15}
                                        onBlur={() =>
                                            checkInputText(
                                                document.getElementById(
                                                    "msgArea"
                                                )
                                            )
                                        }
                                        onChange={(e) =>
                                            setInputTextarea(
                                                e.target.value.length
                                            )
                                        }
                                        maxLength={1000}
                                        required
                                    />
                                    <div>
                                        <small>
                                            Max 1000 Caractères,{" "}
                                            {1000 - inputTextarea} restant
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                            {uploadedFiles.length < 2 &&
                            garantieState === true ? (
                                <div className="text-center mb-4">
                                    Merci de renseigner la date d'achat et{" "}
                                    <br />
                                    joindre la facture avec des photos du défaut
                                    pour poursuivre
                                </div>
                            ) : (
                                <fieldset>
                                    <legend>Vos Coordonnées :</legend>

                                    <div className="coordonnees">
                                        <div className="form-list">
                                            <small>
                                                Adresse de facturation
                                            </small>
                                            {props.type === "" ? (
                                                <>
                                                    <label htmlFor="name">
                                                        Nom et Prénom :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Doe John"
                                                        maxLength={300}
                                                        required
                                                        onBlur={() =>
                                                            checkInputText(
                                                                document.getElementById(
                                                                    "name"
                                                                )
                                                            )
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <label>
                                                        Nom/Raison Sociale :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Nom / Raison sociale"
                                                        maxLength={300}
                                                        required
                                                        onBlur={() =>
                                                            checkInputText(
                                                                document.getElementById(
                                                                    "name"
                                                                )
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}

                                            <label>Addresse : </label>
                                            <input
                                                type="text"
                                                name="addresse"
                                                id="addresse"
                                                className="form-control"
                                                placeholder="Adresse"
                                                maxLength={600}
                                                required
                                                onBlur={() =>
                                                    checkInputText(
                                                        document.getElementById(
                                                            "addresse"
                                                        )
                                                    )
                                                }
                                            />
                                            <label>Code Postal : </label>
                                            <input
                                                type="text"
                                                name="cp"
                                                id="cp"
                                                className="form-control"
                                                placeholder="Code postal"
                                                maxLength={12}
                                                required
                                                onBlur={() =>
                                                    checkInputText(
                                                        document.getElementById(
                                                            "cp"
                                                        )
                                                    )
                                                }
                                            />
                                            <label>Ville : </label>
                                            <input
                                                type="text"
                                                name="ville"
                                                id="ville"
                                                className="form-control"
                                                placeholder="Ville"
                                                maxLength={300}
                                                required
                                                onBlur={() =>
                                                    checkInputText(
                                                        document.getElementById(
                                                            "ville"
                                                        )
                                                    )
                                                }
                                            />
                                            {props.type !== "reprise" && (
                                                <div className="mx-auto">
                                                    {" "}
                                                    <DropDownSelect
                                                        className={
                                                            "form-group "
                                                        }
                                                        id="country"
                                                        data-testid="country"
                                                        title={"Pays : "}
                                                        dataArray={datacountry}
                                                        keyToWatch={"label"}
                                                        keyToValue={"value"}
                                                        keyToValueDefaultValue={
                                                            "FR"
                                                        }
                                                    />
                                                </div>
                                            )}

                                            <label className="mt-4">
                                                Email :{" "}
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="addresse@domaine.fr"
                                                maxLength={300}
                                                required
                                                onBlur={() =>
                                                    checkValueEmail(
                                                        document.getElementById(
                                                            "email"
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                        {props.type !== "reprise" && (
                                            <div className="form-list">
                                                <small>
                                                    Adresse de livraison si
                                                    différente
                                                </small>
                                                <label>
                                                    Nom pour la livraison :{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="livname"
                                                    id="livname"
                                                    className="form-control"
                                                    placeholder="Nom / Raison sociale"
                                                    maxLength={300}
                                                />
                                                <label>
                                                    Adresse de livraison :{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="livaddresse"
                                                    id="livaddresse"
                                                    className="form-control"
                                                    placeholder="Adresse"
                                                    maxLength={600}
                                                />
                                                <label>
                                                    Code Postal de livraison:{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="livcp"
                                                    id="livcp"
                                                    className="form-control"
                                                    placeholder="Code postal"
                                                    maxLength={12}
                                                />
                                                <label>
                                                    Ville de livraison:{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="livville"
                                                    id="livville"
                                                    className="form-control"
                                                    placeholder="Ville"
                                                    maxLength={300}
                                                />
                                                {props.type !== "reprise" && (
                                                    <div className="mx-auto">
                                                        <DropDownSelect
                                                            className={
                                                                "form-group "
                                                            }
                                                            id="livcountry"
                                                            data-testid="livcountry"
                                                            title={
                                                                "Pays de livraison :"
                                                            }
                                                            dataArray={
                                                                datacountry
                                                            }
                                                            keyToWatch={"label"}
                                                            keyToValue={"value"}
                                                            keyToValueDefaultValue={
                                                                "FR"
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                {props.type === "b2b" && (
                                                    <>
                                                        {" "}
                                                        <label className="mt-4">
                                                            Votre référence
                                                            Interne :{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="refInterne"
                                                            id="refInterne"
                                                            className="form-control"
                                                            placeholder="Une référence Interne ? "
                                                            maxLength={300}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="confirmForm mb-4">
                                        <input
                                            id="confirmForm"
                                            type="checkbox"
                                            className=""
                                            onClick={(e) => {
                                                checkConfirm(e);
                                            }}
                                            required
                                        />
                                        <label
                                            htmlFor="confirmForm"
                                            id="confirmFormLabel"
                                            className=""
                                        >
                                            Je donne mon accord pour que mes
                                            données personnelles soient
                                            utilisées dans le cadre de ma
                                            demande.
                                        </label>
                                    </div>
                                </fieldset>
                            )}{" "}
                            {((uploadedFiles.length >= 2 &&
                                garantieState === true) ||
                                garantieState === false) && (
                                <>
                                    {sendClicked === false ? (
                                        <>
                                            {confirmForm === false ? (
                                                <div className="text-center mb-4 ">
                                                    <div className="button_send mx-auto mb-4 bg-grey">
                                                        Merci de valider votre
                                                        consentement avant de
                                                        poursuivre
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {sizeControlForm < 6 ? (
                                                        <div className="text-center mb-4 ">
                                                            <div
                                                                className="button_send mx-auto mb-4 "
                                                                onClick={() =>
                                                                    checkForm()
                                                                }
                                                                onMouseOver={() =>
                                                                    checkForm()
                                                                }
                                                            >
                                                                Valider la
                                                                demande pour
                                                                l'envoyer
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="mx-auto mb-4">
                                                            <button
                                                                className="button_send mb-4"
                                                                type="submit"
                                                            >
                                                                Envoyer la
                                                                demande
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <div className="mx-auto text-center mb-4">
                                            <div className="mb-4 text-center">
                                                Traitement en cours ... Merci de
                                                patienter.
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </form>
                    ) : (
                        <div className="send_success mb-4">
                            <div className="mb-4 text-center">
                                Merci de votre envoi, vous allez recevoir une
                                confirmation par mail. Nous vous recontactons au
                                plus vite.
                            </div>
                        </div>
                    )}
                    <div
                        className="btn-link-web margin-30"
                        onClick={() => {
                            dataVeloSelect !== null &&
                                dispatch(setParcoursSavTitle("Panier"));
                            dispatch(setParcoursSavStep(5));
                        }}
                    >
                        <button>
                            {props.type !== "reprise"
                                ? "Retourner au Panier"
                                : "Retour"}
                        </button>
                    </div>
                </>
            ) : (
                <div className="margin-30">
                    Merci de renseigner les informations demandées dans les
                    onglets précédents pour poursuivre.
                </div>
            )}
        </>
    );
}
