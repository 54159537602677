import LoadingK from "../LoadingK";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BsDatabaseFill } from "react-icons/bs";
import { useSelector } from "react-redux";

export default function SearchNetSuit() {
    const accessToken = useSelector((store) => store.user.accessToken);

    const [resultCustomerNetSuit, setResultCustomerNetSuit] =
        useState(undefined);
    const mailCustomerSearch = useSelector(
        (state) => state.AdminStaff.mailCustomerSearch
    );
    const nameCustomerSearch = useSelector(
        (state) => state.AdminStaff.nameCustomerSearch
    );
    useEffect(() => {
        if (mailCustomerSearch !== null && mailCustomerSearch !== undefined) {
            SearchCustomerMailNetSuit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailCustomerSearch]);
    useEffect(() => {
        if (nameCustomerSearch !== null && nameCustomerSearch !== undefined) {
            SearchCustomerNameNetSuit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameCustomerSearch]);
    async function SearchCustomerMailNetSuit() {
        try {
            setResultCustomerNetSuit(null);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_mail/netsuit/${mailCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerNetSuit(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerNetSuit(undefined);
                toast.error("Panne de recherche dans les fichiers Netsuit", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande NetSuit😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    async function SearchCustomerNameNetSuit() {
        try {
            setResultCustomerNetSuit(null);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_name/netsuit/${nameCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerNetSuit(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerNetSuit(undefined);
                toast.error("Panne de recherche dans les fichiers Netsuit", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande NetSuit😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    return (
        <>
            {resultCustomerNetSuit !== undefined && (
                <div className="mb-4">
                    <div className="title-result mx-auto">
                        Résultat NetSuit <BsDatabaseFill />:
                    </div>
                    {resultCustomerNetSuit === null ? (
                        <div className="mx-auto">
                            <LoadingK />
                        </div>
                    ) : (
                        <div>
                            {resultCustomerNetSuit !== undefined &&
                            resultCustomerNetSuit.length === 0 ? (
                                <div className="text-center">
                                    Aucun Résultat
                                </div>
                            ) : (
                                <>
                                    {resultCustomerNetSuit !== undefined &&
                                    resultCustomerNetSuit !== null &&
                                    resultCustomerNetSuit.length >= 1 ? (
                                        <>
                                            {resultCustomerNetSuit.map(
                                                (element) => (
                                                    <div
                                                        className="result"
                                                        key={
                                                            "netSuit" +
                                                            element[0]
                                                        }
                                                    >
                                                        <span>
                                                            {element[16]} -
                                                            Client N°{" "}
                                                            {element[0]} -{" "}
                                                            {element[2]} -{" "}
                                                            {element[5]}
                                                        </span>
                                                        <span>
                                                            Nom et Prénom :{" "}
                                                            {element[1]}
                                                        </span>
                                                        <br />
                                                        <span>
                                                            {element[15]} -{" "}
                                                            {element[21]} -{" "}
                                                            {element[25]}{" "}
                                                            {element[23]}{" "}
                                                            {element[26]}
                                                        </span>
                                                        <br />
                                                        <span>
                                                            {" "}
                                                            Adresse Expédition :
                                                            {element[27]}
                                                        </span>
                                                        <span>
                                                            Adresse Facturation
                                                            :{element[28]}
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Client depuis :{" "}
                                                            {element[12]}
                                                        </span>
                                                        <span>
                                                            Dernière
                                                            modification :{" "}
                                                            {element[13]}
                                                        </span>
                                                        {element.note !==
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span>
                                                                    Note :{" "}
                                                                    {
                                                                        element.note
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        "Impossible d'extraire la liste"
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
