import LoadingK from "../LoadingK";
import { SearchAddressCustomerPresta } from "../../Utils/FetchPresta";
export default function AddressPresta(props) {
    const { resultAddressPresta } = SearchAddressCustomerPresta(
        props.idAddress
    );

    return (
        <div>
            {resultAddressPresta !== undefined ? (
                <>
                    {resultAddressPresta.length > 0 &&
                        resultAddressPresta.map((address) => (
                            <>
                                {address.company !== "" && (
                                    <div>Companie : {address.company}</div>
                                )}
                                <div>
                                    Nom Prénom : {address.lastname}{" "}
                                    {address.firstname}
                                </div>
                                <div>Addresse : {address.address1}</div>
                                {address.address2 !== "" && (
                                    <div>Complément : {address.address2}</div>
                                )}
                                <div>
                                    Commune : {address.postcode} {address.city}
                                </div>
                                <div>Code Iso Pays : {address.id_country}</div>
                                <div>Phone : {address.phone} </div>
                                {address.other !== "" && (
                                    <div>Détails : {address.other} </div>
                                )}
                            </>
                        ))}
                </>
            ) : (
                <LoadingK />
            )}
        </div>
    );
}
