/* eslint-disable array-callback-return */
import { PieChart, Pie, Sector } from "recharts";
import { useEffect, useState, useCallback } from "react";
import DateSelected from "./DateSelected";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";

const RenderActiveShapeSKU = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name.slice(0, 10)}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`${props.value} vendus`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
const RenderActiveShapeSize = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`${props.value} vendus`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
const RenderActiveShapeColor = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`${props.value} vendu`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
const RenderActiveShapeFinancialStatus = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`${props.value}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
export default function GraphPieChartVelosType(props) {
    const [dataGraphVeloBySku, setDataGraphVeloBySku] = useState(undefined);
    const [dataGraphVeloBySize, setDataGraphVeloBySize] = useState(undefined);
    const [dataGraphVeloByColor, setDataGraphVeloByColor] = useState(undefined);
    const [dataGraphVeloByFinancialStatus, setDataGraphVeloByFinancialStatus] =
        useState(undefined);
    const [activeIndexSku, setActiveIndexSku] = useState(0);
    const [activeIndexSize, setActiveIndexSize] = useState(0);
    const [activeIndexColor, setActiveIndexColor] = useState(0);
    const [activeIndexFinancial, setActiveIndexFinancial] = useState(0);
    const accessToken = useSelector((store) => store.user.accessToken);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [urlCsvOrders, setUrlCsvOrders] = useState(undefined);
    const handleSubmit = (e) => {
        e.preventDefault();

        let table = undefined;

        table = document.getElementById("my-velosDetails");

        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        if (e.target.innerText.includes("Vélos")) {
            formData.append(
                "Title",
                "Détails_Velos_DU_" + dateStart + "_AU_" + dateEnd
            );
        }

        async function PostCreateCSV(formData) {
            if (e.target.innerText.includes("Vélos")) {
                setUrlCsvOrders(undefined);
            }

            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    if (e.target.innerText.includes("Vélos")) {
                        setUrlCsvOrders(data);
                    }
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateCSV(formData);
    };

    const onPieEnterSku = useCallback(
        (_, index) => {
            setActiveIndexSku(index);
        },
        [setActiveIndexSku]
    );
    const onPieEnterSize = useCallback(
        (_, index) => {
            setActiveIndexSize(index);
        },
        [setActiveIndexSize]
    );
    const onPieEnterColor = useCallback(
        (_, index) => {
            setActiveIndexColor(index);
        },
        [setActiveIndexColor]
    );
    const onPieEnterFinancialStatus = useCallback(
        (_, index) => {
            setActiveIndexFinancial(index);
        },
        [setActiveIndexFinancial]
    );
    useEffect(() => {
        let dataGraphReturnVeloBySku = [];
        let dataGraphReturnVeloBySize = [];
        let dataGraphReturnVeloByColor = [];
        let dataGraphReturnVeloByFinancialStatus = [];
        if (props.data !== undefined) {
            Object.keys(props.data).map((key) => {
                dataGraphReturnVeloBySku.push({
                    name: key,
                    value: props.data[key].quantity,
                    title: props.data[key].name,
                });
                if (
                    dataGraphReturnVeloBySize.hasOwnProperty(
                        props.data[key].size
                    )
                ) {
                    dataGraphReturnVeloBySize[props.data[key].size] =
                        dataGraphReturnVeloBySize[props.data[key].size] +
                        props.data[key].quantity;
                } else {
                    dataGraphReturnVeloBySize[props.data[key].size] =
                        props.data[key].quantity;
                }
                if (
                    dataGraphReturnVeloByColor.hasOwnProperty(
                        props.data[key].color.toLowerCase()
                    )
                ) {
                    dataGraphReturnVeloByColor[
                        props.data[key].color.toLowerCase()
                    ] =
                        dataGraphReturnVeloByColor[
                            props.data[key].color.toLowerCase()
                        ] + props.data[key].quantity;
                } else {
                    dataGraphReturnVeloByColor[
                        props.data[key].color.toLowerCase()
                    ] = props.data[key].quantity;
                }
                props.data[key].order.map((order) => {
                    if (
                        dataGraphReturnVeloByFinancialStatus.hasOwnProperty(
                            order.financial_status
                        )
                    ) {
                        dataGraphReturnVeloByFinancialStatus[
                            order.financial_status
                        ] =
                            dataGraphReturnVeloByFinancialStatus[
                                order.financial_status
                            ] + 1;
                    } else {
                        dataGraphReturnVeloByFinancialStatus[
                            order.financial_status
                        ] = 1;
                    }
                });
            });
        }
        let listsize = [];
        Object.keys(dataGraphReturnVeloBySize).map((key) => {
            listsize.push({ name: key, value: dataGraphReturnVeloBySize[key] });
        });
        let listcolor = [];
        Object.keys(dataGraphReturnVeloByColor).map((key) => {
            listcolor.push({
                name: key,
                value: dataGraphReturnVeloByColor[key],
            });
        });
        let financial = [];
        Object.keys(dataGraphReturnVeloByFinancialStatus).map((key) => {
            financial.push({
                name: key,
                value: dataGraphReturnVeloByFinancialStatus[key],
            });
        });
        setDataGraphVeloBySku(dataGraphReturnVeloBySku);
        setDataGraphVeloBySize(listsize);
        setDataGraphVeloByColor(listcolor);
        setDataGraphVeloByFinancialStatus(financial);
    }, [props.data]);

    return (
        <>
            {dataGraphVeloBySku !== undefined &&
                dataGraphVeloBySize !== undefined &&
                dataGraphVeloByColor !== undefined && (
                    <>
                        <div className="graphVeloRow">
                            <div>
                                <h2>SKU</h2>
                                <PieChart
                                    width={500}
                                    height={400}
                                    className="pie"
                                >
                                    <Pie
                                        activeIndex={activeIndexSku}
                                        activeShape={RenderActiveShapeSKU}
                                        data={dataGraphVeloBySku}
                                        cx={250}
                                        cy={200}
                                        innerRadius={80}
                                        outerRadius={90}
                                        fill="#1d3c34"
                                        dataKey="value"
                                        onMouseEnter={onPieEnterSku}
                                    />
                                </PieChart>{" "}
                                {dataGraphVeloBySku[activeIndexSku] !==
                                    undefined && (
                                    <div
                                        className="text-center"
                                        style={{
                                            maxWidth: "300px",
                                            "overflow ": "scroll",
                                            margin: "auto",
                                        }}
                                    >
                                        Vélo concerné :
                                        <br />
                                        {
                                            dataGraphVeloBySku[activeIndexSku]
                                                .title
                                        }
                                    </div>
                                )}
                            </div>
                            <div>
                                <h2>Taille</h2>

                                <PieChart
                                    width={500}
                                    height={400}
                                    className="pie"
                                >
                                    <Pie
                                        activeIndex={activeIndexSize}
                                        activeShape={RenderActiveShapeSize}
                                        data={dataGraphVeloBySize}
                                        cx={250}
                                        cy={200}
                                        innerRadius={80}
                                        outerRadius={90}
                                        fill="#1d3c34"
                                        dataKey="value"
                                        onMouseEnter={onPieEnterSize}
                                    />
                                </PieChart>
                            </div>
                            <div>
                                <h2>Couleur</h2>

                                <PieChart
                                    width={500}
                                    height={400}
                                    className="pie"
                                >
                                    <Pie
                                        activeIndex={activeIndexColor}
                                        activeShape={RenderActiveShapeColor}
                                        data={dataGraphVeloByColor}
                                        cx={250}
                                        cy={200}
                                        innerRadius={80}
                                        outerRadius={90}
                                        fill="#1d3c34"
                                        dataKey="value"
                                        onMouseEnter={onPieEnterColor}
                                    />
                                </PieChart>
                            </div>
                            <div>
                                <h2>Financial</h2>
                                <PieChart
                                    width={500}
                                    height={400}
                                    className="pie"
                                >
                                    <Pie
                                        activeIndex={activeIndexFinancial}
                                        activeShape={
                                            RenderActiveShapeFinancialStatus
                                        }
                                        data={dataGraphVeloByFinancialStatus}
                                        cx={250}
                                        cy={200}
                                        innerRadius={80}
                                        outerRadius={90}
                                        fill="#1d3c34"
                                        dataKey="value"
                                        onMouseEnter={onPieEnterFinancialStatus}
                                    />
                                </PieChart>{" "}
                                {dataGraphVeloByFinancialStatus[
                                    activeIndexFinancial
                                ] !== undefined && (
                                    <div
                                        className="text-center"
                                        style={{
                                            maxWidth: "300px",
                                            "overflow ": "scroll",
                                            margin: "auto",
                                        }}
                                    >
                                        Statut financier :
                                        <br />
                                        {
                                            dataGraphVeloByFinancialStatus[
                                                activeIndexFinancial
                                            ].title
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="text-center f-column">
                            <button
                                className="btn"
                                onClick={(e) => handleSubmit(e)}
                            >
                                Demande d'export Détails Vélos
                            </button>
                            {urlCsvOrders !== undefined && (
                                <a
                                    href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrders.url_filename_stock}?auth_token=${accessToken}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button className="btn-export-csv ">
                                        Obtenir le CSV d'export{" "}
                                        <BsFiletypePdf /> <BsBoxArrowUpRight />
                                    </button>
                                </a>
                            )}
                        </div>
                        <table
                            className="table table-striped table-bordered"
                            id="my-velosDetails"
                        >
                            <thead>
                                <tr>
                                    <th className="bg-table">SKU</th>
                                    <th className="bg-table">Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataGraphVeloBySku.map((element, index) => (
                                    <tr key={index}>
                                        <td>{element.title}</td>
                                        <td>{element.value}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th className="bg-table">Taille</th>
                                    <th className="bg-table">Quantité</th>
                                </tr>
                                {dataGraphVeloBySize.map((element, index) => (
                                    <tr key={index}>
                                        <td>{element.name}</td>
                                        <td>{element.value}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th className="bg-table">Couleur</th>
                                    <th className="bg-table">Quantité</th>
                                </tr>
                                {dataGraphVeloByColor.map((element, index) => (
                                    <tr key={index}>
                                        <td>{element.name}</td>
                                        <td>{element.value}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th className="bg-table">
                                        Statut Financier
                                    </th>
                                    <th className="bg-table">Quantité</th>
                                </tr>
                                {dataGraphVeloByFinancialStatus.map(
                                    (element, index) => (
                                        <tr key={index}>
                                            <td>{element.name}</td>
                                            <td>{element.value}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </>
                )}

            <hr />
        </>
    );
}
