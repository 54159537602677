const initialState = { showModal: null, openNavBurger: false };

export default function style(state = initialState, action) {
    switch (action.type) {
        case "OPEN_MODAL":
            return { ...state, showModal: action.showModal };
        case "CLOSE_MODAL":
            return { ...state, showModal: action.showModal };
        case "OPEN_NAV_BURGER":
            return { ...state, openNavBurger: action.openNavBurger };
        default:
            return state;
    }
}
