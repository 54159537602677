import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import LoadingCycle from "../Sav/LoaderCycle";
import {
    getCustomers,
    getNextCustomers,
} from "../../Redux/actions/stats.actions";

export default function AllUserList() {
    const [next_page_info_customerActual, setNext_page_info_customerActual] =
        useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    const [watchOnlyConsentEmailMark, setWatchOnlyConsentEmailMark] =
        useState(false);
    const [onlyWithEmail, setOnlyWithEmail] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const listing_customers = useSelector((state) => state.Stats.customers);
    const next_page_info_customer = useSelector(
        (state) => state.Stats.next_page_info_customer
    );
    useEffect(() => {
        if (
            next_page_info_customer !== undefined &&
            next_page_info_customer !== next_page_info_customerActual &&
            next_page_info_customer !== false
        ) {
            setNext_page_info_customerActual(next_page_info_customer);
            dispatch(getNextCustomers(next_page_info_customer, accessToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [next_page_info_customer]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (listing_customers === undefined || listing_customers === null) {
            dispatch(getCustomers(accessToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);

    const handleSubmit = (e) => {
        e.preventDefault();
        let table = undefined;

        table = document.getElementById("my-customers");

        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        formData.append("Title", "Export Client Shopify");

        async function PostCreateSav(formData) {
            setUrlCsvOrdersDate(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvOrdersDate(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateSav(formData);
    };
    return (
        <>
            <h2>Tous mes Client Shopify</h2>
            <div className="text-center f-column">
                <button onClick={(e) => handleSubmit(e)}>
                    Demande d'export
                </button>
                {urlCsvOrdersDate !== undefined && (
                    <a
                        href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="btn">
                            Obtenir le CSV d'export <BsFiletypePdf />{" "}
                            <BsBoxArrowUpRight />
                        </button>
                    </a>
                )}
            </div>
            <div>
                <label htmlFor="watchOnlyConsentEmailMark">
                    Voir que les clients avec Email Marketing approuvé ?{" "}
                </label>
                <input
                    id="watchOnlyConsentEmailMark"
                    type="checkbox"
                    className="mx-2 my-0"
                    onClick={(e) => {
                        setWatchOnlyConsentEmailMark(e.target.checked);
                    }}
                />
            </div>

            <div>
                <label htmlFor="onlyWithEmail">
                    Voir que les clients avec un Email ?{" "}
                </label>
                <input
                    id="onlyWithEmail"
                    type="checkbox"
                    className="mx-2 my-0"
                    onClick={(e) => {
                        setOnlyWithEmail(e.target.checked);
                    }}
                />
            </div>

            {listing_customers !== undefined && listing_customers !== null ? (
                <>
                    <div className="text-center">
                        {listing_customers !== null &&
                            listing_customers !== undefined &&
                            listing_customers.length}{" "}
                        Clients
                    </div>
                    <table id="my-customers">
                        <thead>
                            <tr id="td-header">
                                <td>Email</td>
                                <td>Nom</td>
                                <td>Prénom</td>
                                <td>Email Marketing approuvé</td>
                            </tr>
                        </thead>
                        <tbody>
                            {listing_customers.map((cust) => (
                                <>
                                    {watchOnlyConsentEmailMark === true ? (
                                        <>
                                            {cust.email_marketing_consent !==
                                                null &&
                                                cust.email_marketing_consent[
                                                    "state"
                                                ] === "subscribed" && (
                                                    <tr key={cust.id}>
                                                        <td>{cust.email}</td>
                                                        <td>
                                                            {cust.last_name}
                                                        </td>
                                                        <td>
                                                            {cust.first_name}
                                                        </td>
                                                        <td>
                                                            {
                                                                cust
                                                                    .email_marketing_consent[
                                                                    "state"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                        </>
                                    ) : (
                                        <>
                                            {onlyWithEmail === true ? (
                                                <>
                                                    {cust.email !== undefined &&
                                                        cust.email !== null && (
                                                            <tr key={cust.id}>
                                                                <td>
                                                                    {cust.email}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        cust.last_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        cust.first_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {cust.email_marketing_consent !==
                                                                        null &&
                                                                        cust
                                                                            .email_marketing_consent[
                                                                            "state"
                                                                        ]}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </>
                                            ) : (
                                                <tr key={cust.id}>
                                                    <td>{cust.email}</td>
                                                    <td>{cust.last_name}</td>
                                                    <td>{cust.first_name}</td>
                                                    <td>
                                                        {cust.email_marketing_consent !==
                                                            null &&
                                                            cust
                                                                .email_marketing_consent[
                                                                "state"
                                                            ]}
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-center">
                    <small>Environ un délai de 12sec</small>
                    <LoadingCycle />
                </div>
            )}
        </>
    );
}
