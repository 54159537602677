/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from "react-redux";
import {
    getLocations,
    getProductsReparations,
    dicoProductsRepair,
} from "../../Redux/actions/stats.actions";

import { useEffect } from "react";
import "../../Pages/sav.css";

import DateSelected from "./DateSelected";
import TableReparations from "./tableReparation";

export default function EtatReparations() {
    document.title = "VELOMAD - Stats - Etats des Réparations Vendues";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.Stats.locations);
    const orders_date = useSelector((state) => state.Stats.orders_date);
    const productsRepair = useSelector((state) => state.Stats.productsRepair);
    const productsRepairByLocations = useSelector(
        (state) => state.Stats.productsRepairByLocations
    );

    useEffect(() => {
        dispatch(getLocations(accessToken));
        dispatch(getProductsReparations(accessToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            productsRepair !== null &&
            orders_date !== null &&
            locations !== null
        ) {
            dispatch(
                dicoProductsRepair(productsRepair, orders_date, locations)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsRepair, orders_date, locations]);

    return (
        <main>
            <h1 id="selectProducts">Liste des Reparations vendues</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    <DateSelected />

                    {orders_date !== null && orders_date !== undefined && (
                        <>
                            {productsRepairByLocations !== null &&
                                productsRepairByLocations !== undefined && (
                                    <div className="div-table-reparation ">
                                        <TableReparations
                                            title="PDV Paris"
                                            location={88642617625}
                                            productsRepairByLocations={
                                                productsRepairByLocations
                                            }
                                        />
                                        <TableReparations
                                            title="PDV Rouen"
                                            location={88647401753}
                                            productsRepairByLocations={
                                                productsRepairByLocations
                                            }
                                        />
                                    </div>
                                )}
                        </>
                    )}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
