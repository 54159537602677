import "./veloproducts.css";
import { useDispatch, useSelector } from "react-redux";

import {
    getProductSelectVelo,
    selectModele,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import { erasePanier } from "../../Redux/actions/panierSav.actions";

export default function VeloProduct(props) {
    const dispatch = useDispatch();

    const getGammeSelected = useSelector(
        (state) => state.veloDetails.product_select_velo
    );

    return (
        <div
            className={
                getGammeSelected !== undefined &&
                getGammeSelected !== null &&
                getGammeSelected.id === props.product.id
                    ? "velo_product selected"
                    : "velo_product"
            }
            key={"velo_product_selected" + props.product.id}
            onClick={(e) => {
                dispatch(getProductSelectVelo(props.product.id));
                dispatch(selectModele(undefined));
                dispatch(erasePanier());
                dispatch(setParcoursSavTitle("Choisissez votre version"));
                dispatch(setParcoursSavStep(2));
            }}
        >
            {props.product !== undefined && (
                <span className="text-center">
                    {props.product.title.slice(4, props.product.title.length)}
                </span>
            )}
        </div>
    );
}
