/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./gestionMap.css";
import "./stats.css";
import { getDataMap, getStatsMap } from "../Redux/actions/map.actions";

export default function GestionMapsStats() {
    document.title = "VELOMAD | Stats Maps";
    document.description = "Intranet du VELOMAD";
    const dispatch = useDispatch();
    const elements = useSelector((state) => state.Map.elements);
    //const categories = useSelector((state) => state.Map.categories);
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    const statsMaps = useSelector((store) => store.Map.statsMaps);
    const [elementsDico, setElementsDico] = useState({});
    useEffect(() => {
        if (elements === null || elements === undefined) {
            dispatch(getDataMap());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (elements !== null && elements !== undefined) {
            var dico = {};
            elements.map((element) => {
                dico = { ...dico, [element.id]: element };
            });
            setElementsDico(dico);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements]);

    useEffect(() => {
        dispatch(getStatsMap(accessToken));
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getStatsMap(accessToken));
        }, 5000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);
    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Stats Maps</h1>
                    {statsMaps !== null && (
                        <div>
                            {statsMaps !== null &&
                                statsMaps.map((stat) => (
                                    <li key={stat.id}>
                                        {stat.created} -
                                        {elementsDico !== undefined &&
                                            elementsDico[stat.element] !==
                                                undefined &&
                                            elementsDico[stat.element].title !==
                                                undefined && (
                                                <>
                                                    {
                                                        elementsDico[
                                                            stat.element
                                                        ].title
                                                    }{" "}
                                                    -{" "}
                                                    {
                                                        elementsDico[
                                                            stat.element
                                                        ].city
                                                    }
                                                </>
                                            )}
                                    </li>
                                ))}
                        </div>
                    )}
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}{" "}
        </main>
    );
}
