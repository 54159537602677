import LoadingK from "../LoadingK";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { BiCreditCard } from "react-icons/bi";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { SiShopify } from "react-icons/si";
import { useSelector } from "react-redux";

export default function SearchShopi() {
    const [resultCustomerShopi, setResultCustomerShopi] = useState(undefined);
    const [idCustomerShopi, setIdCustomerShopi] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    const [resultOrdersCustomerShopi, setResultOrdersCustomerShopi] =
        useState(undefined);

    const mailCustomerSearch = useSelector(
        (state) => state.AdminStaff.mailCustomerSearch
    );
    const nameCustomerSearch = useSelector(
        (state) => state.AdminStaff.nameCustomerSearch
    );

    useEffect(() => {
        if (mailCustomerSearch !== null && mailCustomerSearch !== undefined) {
            SearchCustomerMailShopi();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailCustomerSearch]);
    useEffect(() => {
        if (nameCustomerSearch !== null && nameCustomerSearch !== undefined) {
            SearchCustomerNameShopi();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameCustomerSearch]);
    async function SearchCustomerMailShopi() {
        try {
            setResultCustomerShopi(null);
            setResultOrdersCustomerShopi(undefined);
            setIdCustomerShopi(undefined);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_mail/shopify/${mailCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerShopi(data);
                try {
                    setIdCustomerShopi(data[0].id);
                } catch {}
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerShopi(undefined);
                toast.error("Panne de Connexion en direct avec API Shopify", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande Shopify😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    async function SearchCustomerNameShopi() {
        try {
            setResultCustomerShopi(null);
            setResultOrdersCustomerShopi(undefined);
            setIdCustomerShopi(undefined);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_name/shopify/${nameCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerShopi(data);
                try {
                    setIdCustomerShopi(data[0].id);
                } catch {}
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerShopi(undefined);
                toast.error("Panne de Connexion en direct avec API Shopify", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande Shopify😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    async function SearchOrdersCustomerShopi() {
        try {
            setResultOrdersCustomerShopi(null);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_orders_curstomer/shopify/${idCustomerShopi}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultOrdersCustomerShopi(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultOrdersCustomerShopi(undefined);
                toast.error("Panne de Connexion en direct avec API Shopify", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error(
                "Oups! Impossible d'envoyer la demande Shopify liste des commandes du clients 😔",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    }
    useEffect(() => {
        if (idCustomerShopi !== null && idCustomerShopi !== undefined) {
            SearchOrdersCustomerShopi();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCustomerShopi, setIdCustomerShopi]);

    return (
        <>
            {resultCustomerShopi !== undefined && (
                <div className="mb-4">
                    <div className="title-result mx-auto">
                        {" "}
                        Résultat Shopify <SiShopify /> :
                    </div>
                    {resultCustomerShopi === null ? (
                        <div className="mx-auto">
                            <LoadingK />
                        </div>
                    ) : (
                        <div>
                            {resultCustomerShopi !== undefined &&
                            resultCustomerShopi.length === 0 ? (
                                <div className="text-center">
                                    Aucun Résultat
                                </div>
                            ) : (
                                <>
                                    {resultCustomerShopi !== undefined &&
                                    resultCustomerShopi !== null &&
                                    resultCustomerShopi.length >= 1 ? (
                                        <>
                                            {resultCustomerShopi.map(
                                                (element) => (
                                                    <div
                                                        className="result"
                                                        key={
                                                            "shopi" + element[0]
                                                        }
                                                    >
                                                        {" "}
                                                        <a
                                                            href={`https://admin.shopify.com/store/velomad/customers/${element.id}/`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <h3>
                                                                {element.email}{" "}
                                                                - Client{" "}
                                                                <SiShopify /> N°{" "}
                                                                {element.id}{" "}
                                                                <BsBoxArrowUpRight />
                                                            </h3>
                                                        </a>
                                                        <div>
                                                            Nombre total de
                                                            commande :{" "}
                                                            {
                                                                element.orders_count
                                                            }
                                                        </div>
                                                        <hr />
                                                        <span>
                                                            Nom :{" "}
                                                            {element.last_name}{" "}
                                                            {" - Prénom : "}
                                                            {element.first_name}
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Phone :{" "}
                                                            {element.phone} -{" "}
                                                            {element.addresses !==
                                                                null &&
                                                                element
                                                                    .addresses
                                                                    .length >=
                                                                    1 &&
                                                                element
                                                                    .addresses[0]
                                                                    .phone}
                                                        </span>
                                                        <br />
                                                        {element.default_address !=
                                                            null && (
                                                            <span>
                                                                Adresse par
                                                                défaut : <br />
                                                                {
                                                                    element
                                                                        .default_address
                                                                        .address1
                                                                }{" "}
                                                                {
                                                                    element
                                                                        .default_address
                                                                        .address1
                                                                }{" "}
                                                                {
                                                                    element
                                                                        .default_address
                                                                        .zip
                                                                }{" "}
                                                                {
                                                                    element
                                                                        .default_address
                                                                        .city
                                                                }{" "}
                                                                {
                                                                    element
                                                                        .default_address
                                                                        .country
                                                                }
                                                            </span>
                                                        )}
                                                        <br />
                                                        <span>
                                                            Client depuis :{" "}
                                                            {element.created_at}
                                                        </span>
                                                        <span>
                                                            Dernière
                                                            modification :{" "}
                                                            {element.updated_at}
                                                        </span>
                                                        {element.note !==
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span>
                                                                    Note :{" "}
                                                                    {
                                                                        element.note
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        "aie"
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {resultOrdersCustomerShopi === null ? (
                        <div className="mx-auto">
                            <LoadingK />
                        </div>
                    ) : (
                        <div>
                            {resultOrdersCustomerShopi !== undefined &&
                            resultOrdersCustomerShopi.length === 0 ? (
                                <div className="text-center">
                                    Aucune Commande Shopify
                                </div>
                            ) : (
                                <>
                                    {resultOrdersCustomerShopi !== undefined &&
                                        resultOrdersCustomerShopi !== null &&
                                        resultOrdersCustomerShopi.length >=
                                            1 && (
                                            <>
                                                {resultOrdersCustomerShopi.map(
                                                    (cmde) => (
                                                        <div
                                                            className="orders"
                                                            key={
                                                                "shopiOrder" +
                                                                cmde.id
                                                            }
                                                        >
                                                            <h3>
                                                                <a
                                                                    href={`https://admin.shopify.com/store/velomad/orders/${cmde.id}/`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Commande{" "}
                                                                    <SiShopify />{" "}
                                                                    {cmde.name}{" "}
                                                                    <BsBoxArrowUpRight />
                                                                </a>
                                                            </h3>
                                                            <div className="order-details">
                                                                <div className="order-details-div">
                                                                    <h3>
                                                                        Détails
                                                                        Commande{" "}
                                                                        {cmde.source_name ===
                                                                            "pos" &&
                                                                            "Point de Vente"}
                                                                        {cmde.source_name ===
                                                                            "web" &&
                                                                            "Site Web"}
                                                                    </h3>
                                                                    <div>
                                                                        Note
                                                                        dans
                                                                        dossier
                                                                        :{" "}
                                                                        {
                                                                            cmde.note
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        Attribut
                                                                        :{" "}
                                                                        {
                                                                            cmde.note_attributes
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <a
                                                                            href={
                                                                                cmde.order_status_url
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="bg-dark-green"
                                                                        >
                                                                            Accès{" "}
                                                                            Panier{" "}
                                                                            <SiShopify />{" "}
                                                                            {
                                                                                cmde.name
                                                                            }{" "}
                                                                            <BsBoxArrowUpRight />
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <hr />
                                                                    <div>
                                                                        <div>
                                                                            Statut
                                                                            Commande
                                                                            :{" "}
                                                                            {cmde.closed_at ===
                                                                                null &&
                                                                                cmde.cancel_reason ===
                                                                                    null && (
                                                                                    <span className="statut statut-pending statut-show statut-text_size">
                                                                                        {" "}
                                                                                        En
                                                                                        cours
                                                                                    </span>
                                                                                )}
                                                                            {cmde.closed_at !==
                                                                                null && (
                                                                                <span className="statut statut-actived statut-show statut-text_size">
                                                                                    Cloturée{" "}
                                                                                    le{" "}
                                                                                    {
                                                                                        cmde.closed_at
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            {cmde.cancel_reason !==
                                                                                null && (
                                                                                <span className="statut statut-expired statut-show statut-text_size">
                                                                                    {" "}
                                                                                    Oui{" "}
                                                                                    {
                                                                                        " => "
                                                                                    }{" "}
                                                                                    {
                                                                                        cmde.cancel_reason
                                                                                    }{" "}
                                                                                    le{" "}
                                                                                    {
                                                                                        cmde.cancelled_at
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            {cmde.tags && (
                                                                                <span className="statut statut-pending statut-show statut-text_size">
                                                                                    {" "}
                                                                                    {
                                                                                        cmde.tags
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>

                                                                        <div>
                                                                            Etat
                                                                            de
                                                                            l'expédition
                                                                            :{" "}
                                                                            {cmde.fulfillment_status ===
                                                                                null && (
                                                                                <span className="statut statut-pending statut-show statut-text_size">
                                                                                    Indéterminé
                                                                                </span>
                                                                            )}
                                                                            {cmde.fulfillment_status ===
                                                                            "fulfilled" ? (
                                                                                <span className="statut statut-actived statut-show statut-text_size">
                                                                                    Expédié
                                                                                    /
                                                                                    Livré
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    {
                                                                                        cmde.fulfillment_status
                                                                                    }
                                                                                </span>
                                                                            )}{" "}
                                                                            -
                                                                            Poid
                                                                            total{" "}
                                                                            {cmde.total_weight /
                                                                                1000}{" "}
                                                                            kg
                                                                        </div>
                                                                        <div>
                                                                            Mode
                                                                            de
                                                                            l'expédition
                                                                            :{" "}
                                                                            {cmde
                                                                                .shipping_lines
                                                                                .length >=
                                                                                0 &&
                                                                            cmde
                                                                                .shipping_lines[0] !==
                                                                                undefined &&
                                                                            cmde
                                                                                .shipping_lines[0] !==
                                                                                null &&
                                                                            cmde
                                                                                .shipping_lines[0]
                                                                                .title ===
                                                                                "Retail - no shipping required" ? (
                                                                                <span className="statut statut-actived statut-show statut-text_size">
                                                                                    Vente
                                                                                    au
                                                                                    détails
                                                                                    -
                                                                                    pas
                                                                                    d'envoi
                                                                                </span>
                                                                            ) : (
                                                                                <span></span>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            N°
                                                                            confirmation{" "}
                                                                            {
                                                                                cmde.confirmation_number
                                                                            }{" "}
                                                                            -
                                                                            Confirmé
                                                                            ?{" "}
                                                                            {cmde.confirmed ===
                                                                            true ? (
                                                                                <span>
                                                                                    Oui
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    Non
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            Créée
                                                                            le :{" "}
                                                                            {
                                                                                cmde.created_at
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <h3>
                                                                        Détails
                                                                        Articles{" "}
                                                                    </h3>
                                                                    <div>
                                                                        <ul>
                                                                            {" "}
                                                                            {cmde.line_items !==
                                                                                undefined &&
                                                                            cmde
                                                                                .line_items
                                                                                .length >
                                                                                0 ? (
                                                                                <>
                                                                                    {cmde.line_items.map(
                                                                                        (
                                                                                            art
                                                                                        ) => (
                                                                                            <>
                                                                                                <li
                                                                                                    key={
                                                                                                        art.id
                                                                                                    }
                                                                                                >
                                                                                                    <span>
                                                                                                        {
                                                                                                            art.name
                                                                                                        }
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span>
                                                                                                        {
                                                                                                            art.variant_title
                                                                                                        }
                                                                                                    </span>{" "}
                                                                                                    <br />
                                                                                                    <span>
                                                                                                        quantité
                                                                                                        x
                                                                                                        {
                                                                                                            art.quantity
                                                                                                        }{" "}
                                                                                                        -
                                                                                                        prix
                                                                                                        :{" "}
                                                                                                        {
                                                                                                            art.price
                                                                                                        }{" "}
                                                                                                        -
                                                                                                        poid
                                                                                                        :{" "}
                                                                                                        {art.grams /
                                                                                                            1000}{" "}
                                                                                                        kg{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span>
                                                                                                        {
                                                                                                            art.sku
                                                                                                        }
                                                                                                    </span>
                                                                                                </li>
                                                                                            </>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                "Aucun article détecté sinon vérifier sur shopify"
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                    <h3>
                                                                        Détails
                                                                        Expédition{" "}
                                                                    </h3>
                                                                    {cmde.shipping_lines !==
                                                                        null &&
                                                                        cmde
                                                                            .shipping_lines
                                                                            .length >=
                                                                            1 && (
                                                                            <div>
                                                                                Moyen
                                                                                d'envoi
                                                                                :{" "}
                                                                                <span className="statut statut-actived statut-show statut-text_size">
                                                                                    {
                                                                                        cmde
                                                                                            .shipping_lines[0]
                                                                                            .code
                                                                                    }
                                                                                </span>{" "}
                                                                                à{" "}
                                                                                {
                                                                                    cmde
                                                                                        .shipping_lines[0]
                                                                                        .price
                                                                                }{" "}
                                                                                {
                                                                                    cmde
                                                                                        .shipping_lines[0]
                                                                                        .price_set
                                                                                        .shop_money
                                                                                        .currency_code
                                                                                }{" "}
                                                                                TTC
                                                                            </div>
                                                                        )}
                                                                    {cmde.shipping_address !==
                                                                        null && (
                                                                        <div>
                                                                            Adresse
                                                                            de
                                                                            Livraison
                                                                            :{" "}
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .name
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .company
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .address1
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .address2
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .zip
                                                                            }{" "}
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .city
                                                                            }{" "}
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .country
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .shipping_address
                                                                                    .phone
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="order-details-div">
                                                                    <h3>
                                                                        Détails
                                                                        Transaction
                                                                    </h3>
                                                                    <div>
                                                                        N°
                                                                        Ticket
                                                                        Panier :{" "}
                                                                        {
                                                                            cmde.checkout_id
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        Moyen de
                                                                        paiement
                                                                        :{" "}
                                                                        {
                                                                            cmde.payment_gateway_names
                                                                        }
                                                                        {cmde.payment_gateway_names.includes(
                                                                            "Card"
                                                                        ) && (
                                                                            <BiCreditCard />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        Etat du
                                                                        paiement
                                                                        :{" "}
                                                                        {cmde.financial_status ===
                                                                            "paid" && (
                                                                            <span className="statut statut-actived statut-show statut-text_size">
                                                                                {" "}
                                                                                Payé
                                                                                en
                                                                                totalité
                                                                            </span>
                                                                        )}
                                                                        {cmde.financial_status ===
                                                                            "partially_paid" && (
                                                                            <span className="statut statut-pending statut-show statut-text_size">
                                                                                {" "}
                                                                                Patiellement
                                                                                Payé
                                                                            </span>
                                                                        )}
                                                                        {cmde.financial_status !==
                                                                            "partially_paid" &&
                                                                            cmde.financial_status !==
                                                                                "paid" && (
                                                                                <span className="statut statut-expired statut-show statut-text_size">
                                                                                    {" "}
                                                                                    Patiellement
                                                                                    Payé
                                                                                </span>
                                                                            )}
                                                                    </div>
                                                                    {cmde.payment_terms !==
                                                                        null && (
                                                                        <div>
                                                                            Reste
                                                                            à
                                                                            payer{" "}
                                                                            <span className="statut statut-pending statut-show statut-text_size">
                                                                                {cmde.payment_terms !==
                                                                                    null &&
                                                                                    cmde
                                                                                        .payment_terms
                                                                                        .payment_schedules !==
                                                                                        undefined &&
                                                                                    cmde
                                                                                        .payment_terms
                                                                                        .payment_schedules !==
                                                                                        null &&
                                                                                    cmde
                                                                                        .payment_terms
                                                                                        .payment_schedules
                                                                                        .length >=
                                                                                        1 && (
                                                                                        <span>
                                                                                            {cmde
                                                                                                .payment_terms
                                                                                                .payment_schedules !==
                                                                                                undefined &&
                                                                                                cmde
                                                                                                    .payment_terms
                                                                                                    .payment_schedules !==
                                                                                                    null &&
                                                                                                cmde
                                                                                                    .payment_terms
                                                                                                    .payment_schedules[0]
                                                                                                    .amount}
                                                                                            {cmde
                                                                                                .payment_terms
                                                                                                .payment_schedules !==
                                                                                                undefined &&
                                                                                                cmde
                                                                                                    .payment_terms
                                                                                                    .payment_schedules !==
                                                                                                    null &&
                                                                                                cmde
                                                                                                    .payment_terms
                                                                                                    .payment_schedules[0]
                                                                                                    .currency}
                                                                                        </span>
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <hr />
                                                                    <div>
                                                                        TOTAL
                                                                        Commande
                                                                        :{" "}
                                                                        {
                                                                            cmde.current_total_price
                                                                        }{" "}
                                                                        {
                                                                            cmde.currency
                                                                        }{" "}
                                                                        TTC (
                                                                        dont TVA{" "}
                                                                        {
                                                                            cmde
                                                                                .current_total_tax_set
                                                                                .shop_money
                                                                                .amount
                                                                        }
                                                                        {
                                                                            cmde
                                                                                .current_total_tax_set
                                                                                .shop_money
                                                                                .currency_code
                                                                        }
                                                                        )
                                                                    </div>
                                                                    <div>
                                                                        TOTAL
                                                                        Réduction
                                                                        :{" "}
                                                                        {
                                                                            cmde.total_discounts
                                                                        }{" "}
                                                                        {
                                                                            cmde.currency
                                                                        }{" "}
                                                                        TTC
                                                                    </div>
                                                                    <hr />

                                                                    <div>
                                                                        {cmde.client_details !==
                                                                            null && (
                                                                            <span>
                                                                                {
                                                                                    cmde
                                                                                        .client_details
                                                                                        .user_agent
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <hr />
                                                                    {cmde.billing_address !==
                                                                        null && (
                                                                        <div>
                                                                            Adresse
                                                                            de
                                                                            facturation
                                                                            :{" "}
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .name
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .company
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .address1
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .address2
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .zip
                                                                            }{" "}
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .city
                                                                            }{" "}
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .country
                                                                            }
                                                                            <br />
                                                                            {
                                                                                cmde
                                                                                    .billing_address
                                                                                    .phone
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
