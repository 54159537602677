import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./sav.css";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/actions/user.actions";

function Logout() {
    document.title = "VELOMAD - Déconnexion ";
    document.description = "Page de déconnexion";
    const dispatch = useDispatch();
    let navigate = useNavigate();

    function redirectLogin() {
        navigate("/");
    }
    useEffect(() => {
        dispatch(logout());
        setTimeout(() => {
            redirectLogin();
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <main>
            <div className="text-center">Vous avez été déconnecté</div>
            <div className="text-center">
                <button className="btn">
                    <Link
                        to="/connexion"
                        style={{ textDecoration: "none" }}
                        className="text-white"
                    >
                        Se Reconnecter
                    </Link>
                </button>
                <button className="btn">
                    <Link
                        to="/"
                        style={{ textDecoration: "none" }}
                        className="text-white"
                    >
                        Retour sur la page SAV
                    </Link>
                </button>
            </div>
        </main>
    );
}

export default Logout;
