import { useSelector } from "react-redux";
import "./maps.css";
import "./sav.css";

import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";

import { Link } from "react-router-dom";

export default function SavChoiceType(props) {
    document.title = "VELOMAD | Type de SAV  ";
    document.description = "Page de demande de SAV " + props.type;
    const openNavBurger = useSelector((state) => state.style.openNavBurger);

    return (
        <>
            {openNavBurger === false && (
                <>
                    <main className="main-sav">
                        <div className="banner-sav"></div>
                        <div className="banner-title">
                            <h1 id="title_sav">
                                Demande <br />
                                SAV
                            </h1>
                        </div>
                        <div className="etat_small">Vous êtes :</div>
                        <div className="statut_garantie">
                            <Link
                                to="/demande-sav-b2b"
                                className={"velo_product"}
                            >
                                Un Client Professionnel - B2B
                            </Link>
                            <Link
                                to="/demande-sav-b2c"
                                className={"velo_product"}
                            >
                                Un Client Particulier - B2C
                            </Link>
                        </div>
                    </main>
                    <SingeFooterShopifyVelomad />
                    <FooterShopifyVelomad />
                </>
            )}
        </>
    );
}
