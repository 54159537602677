import "../../Pages/maps.css";
import "../../Pages/sav.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function GestionReprise() {
    document.title = "VELOMAD | Gestion Reprise";
    document.description = "Intranet du VELOMAD";
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    const [allPrices, setAllPrices] = useState([]);
    const [uniqueListName, setUniqueListName] = useState([]);
    const [etat, setEtat] = useState("");
    const [gamme, setGamme] = useState("");
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [kmMin, setKmMin] = useState(0);
    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const res = await fetch(
                    "https://api.levelomad.com/api/reprise/v1/get_price_reprise/"
                );
                const data = await res.json();
                setAllPrices(data);
            } catch {}
        };

        fetchPrice();
    }, []);

    useEffect(() => {
        const listName = allPrices.map((price) => price.gamme);
        const uniqueListName = [...new Set(listName)];
        setUniqueListName(uniqueListName);
    }, [allPrices]);

    useEffect(() => {
        if (selectedPrice !== null) {
            setGamme(selectedPrice.gamme);
            setEtat(selectedPrice.etat);
            document.getElementById("gamme").value = selectedPrice.gamme ?? "";
            document.getElementById("kmMin").value = selectedPrice.kmMin ?? 0;
            document.getElementById("kmMax").value = selectedPrice.kmMax ?? 0;
            document.getElementById("prixVente").value =
                selectedPrice.prixVente ?? 0;
            document.getElementById("prixReprise").value =
                selectedPrice.prixReprise ?? 0;
        }
    }, [selectedPrice]);

    const handleErase = () => {
        setGamme("");
        setEtat("");
        setSelectedPrice(null);
        document.getElementById("gamme").value = "";
        document.getElementById("kmMin").value = 0;
        document.getElementById("kmMax").value = 0;
        document.getElementById("prixVente").value = 0;
        document.getElementById("prixReprise").value = 0;
    };

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Gestion Prix de Reprise</h1>
                    <small>Filtrage possible par gamme & etat</small>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Gamme</th>
                                    <th>Km</th>
                                    <th>Etat</th>
                                    <th>Prix de Vente</th>
                                    <th>Prix de Reprise</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="Nouvelle Gamme"
                                            maxLength={200}
                                            id="gamme"
                                            onChange={(e) =>
                                                setGamme(e.target.value)
                                            }
                                            defaultValue={
                                                selectedPrice?.gamme ?? ""
                                            }
                                        />
                                        <select
                                            onChange={(e) =>
                                                setGamme(e.target.value)
                                            }
                                        >
                                            <option
                                                value={
                                                    selectedPrice?.gamme ?? ""
                                                }
                                            >
                                                {selectedPrice?.gamme ??
                                                    " ---------"}
                                            </option>
                                            {uniqueListName.map((name) => (
                                                <option
                                                    value={name}
                                                    key={name.id}
                                                >
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="integer"
                                            placeholder="Km Min"
                                            step={100}
                                            min={0}
                                            id="kmMin"
                                            defaultValue={
                                                selectedPrice?.kmMin ?? 0
                                            }
                                            onChange={(e) => {
                                                setKmMin(e.target.value);
                                            }}
                                        />{" "}
                                        -
                                        <input
                                            type="integer"
                                            placeholder="Km Max"
                                            step={100}
                                            min={0}
                                            id="kmMax"
                                            defaultValue={
                                                selectedPrice?.kmMax ?? 0
                                            }
                                        />
                                    </td>
                                    <td>
                                        <select
                                            onChange={(e) =>
                                                setEtat(e.target.value)
                                            }
                                        >
                                            <option
                                                value={
                                                    selectedPrice?.etat ?? ""
                                                }
                                            >
                                                {selectedPrice?.etat ??
                                                    " ---------"}
                                            </option>
                                            <option value={"Parfait"}>
                                                Parfait
                                            </option>
                                            <option value={"Très Bon"}>
                                                Très Bon
                                            </option>
                                            <option value={"Correct"}>
                                                Correct
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="integer"
                                            placeholder="Prix Vente"
                                            step={1}
                                            min={0}
                                            id="prixVente"
                                            defaultValue={
                                                selectedPrice?.prixVente ?? 0
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="integer"
                                            placeholder="Prix Reprise"
                                            step={1}
                                            min={0}
                                            id="prixReprise"
                                            defaultValue={
                                                selectedPrice?.prixReprise ?? 0
                                            }
                                        />
                                    </td>
                                    <td>
                                        {" "}
                                        {selectedPrice === null ? (
                                            <button
                                                onClick={async () => {
                                                    try {
                                                        const res = await fetch(
                                                            "https://api.levelomad.com/api/reprise/v1/gestion_price_reprise/",
                                                            {
                                                                method: "POST",
                                                                headers: {
                                                                    "Content-Type":
                                                                        "application/json",
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                },
                                                                body: JSON.stringify(
                                                                    {
                                                                        gamme: gamme,
                                                                        kmMin: document.getElementById(
                                                                            "kmMin"
                                                                        ).value,
                                                                        kmMax: document.getElementById(
                                                                            "kmMax"
                                                                        ).value,
                                                                        etat: etat,
                                                                        prixVente:
                                                                            document.getElementById(
                                                                                "prixVente"
                                                                            )
                                                                                .value,
                                                                        prixReprise:
                                                                            document.getElementById(
                                                                                "prixReprise"
                                                                            )
                                                                                .value,
                                                                    }
                                                                ),
                                                            }
                                                        );
                                                        const data =
                                                            await res.json();
                                                        if (
                                                            res.status === 202
                                                        ) {
                                                            toast.success(
                                                                "Prix de reprise ajouté",
                                                                {
                                                                    position:
                                                                        toast
                                                                            .POSITION
                                                                            .BOTTOM_RIGHT,
                                                                }
                                                            );
                                                            setAllPrices(data);
                                                        } else {
                                                            toast.error(
                                                                "Une erreur est survenue",
                                                                {
                                                                    position:
                                                                        toast
                                                                            .POSITION
                                                                            .BOTTOM_RIGHT,
                                                                }
                                                            );
                                                        }
                                                    } catch {
                                                        toast.error(
                                                            "Une erreur est survenue",
                                                            {
                                                                position:
                                                                    toast
                                                                        .POSITION
                                                                        .BOTTOM_RIGHT,
                                                            }
                                                        );
                                                    }
                                                }}
                                            >
                                                Ajouter
                                            </button>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={async () => {
                                                        handleErase();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                                <button
                                                    onClick={async () => {
                                                        try {
                                                            const res =
                                                                await fetch(
                                                                    "https://api.levelomad.com/api/reprise/v1/gestion_price_reprise/",
                                                                    {
                                                                        method: "PUT",
                                                                        headers:
                                                                            {
                                                                                "Content-Type":
                                                                                    "application/json",
                                                                                Authorization: `Bearer ${accessToken}`,
                                                                            },
                                                                        body: JSON.stringify(
                                                                            {
                                                                                id: selectedPrice.id,
                                                                                gamme: gamme,
                                                                                kmMin: document.getElementById(
                                                                                    "kmMin"
                                                                                )
                                                                                    .value,
                                                                                kmMax: document.getElementById(
                                                                                    "kmMax"
                                                                                )
                                                                                    .value,
                                                                                etat: etat,
                                                                                prixVente:
                                                                                    document.getElementById(
                                                                                        "prixVente"
                                                                                    )
                                                                                        .value,
                                                                                prixReprise:
                                                                                    document.getElementById(
                                                                                        "prixReprise"
                                                                                    )
                                                                                        .value,
                                                                            }
                                                                        ),
                                                                    }
                                                                );
                                                            const data =
                                                                await res.json();
                                                            if (
                                                                res.status ===
                                                                202
                                                            ) {
                                                                toast.success(
                                                                    "Prix de reprise modifié",
                                                                    {
                                                                        position:
                                                                            toast
                                                                                .POSITION
                                                                                .BOTTOM_RIGHT,
                                                                    }
                                                                );
                                                                setAllPrices(
                                                                    data
                                                                );
                                                                handleErase();
                                                            } else {
                                                                toast.error(
                                                                    "Une erreur est survenue",
                                                                    {
                                                                        position:
                                                                            toast
                                                                                .POSITION
                                                                                .BOTTOM_RIGHT,
                                                                    }
                                                                );
                                                            }
                                                        } catch {
                                                            toast.error(
                                                                "Une erreur est survenue",
                                                                {
                                                                    position:
                                                                        toast
                                                                            .POSITION
                                                                            .BOTTOM_RIGHT,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    onClick={async () => {
                                                        try {
                                                            const res =
                                                                await fetch(
                                                                    "https://api.levelomad.com/api/reprise/v1/gestion_price_reprise/",
                                                                    {
                                                                        method: "DELETE",
                                                                        headers:
                                                                            {
                                                                                "Content-Type":
                                                                                    "application/json",
                                                                                Authorization: `Bearer ${accessToken}`,
                                                                            },
                                                                        body: JSON.stringify(
                                                                            {
                                                                                id: selectedPrice.id,
                                                                            }
                                                                        ),
                                                                    }
                                                                );
                                                            const data =
                                                                await res.json();
                                                            if (
                                                                res.status ===
                                                                202
                                                            ) {
                                                                toast.success(
                                                                    "Prix de reprise supprimé",
                                                                    {
                                                                        position:
                                                                            toast
                                                                                .POSITION
                                                                                .BOTTOM_RIGHT,
                                                                    }
                                                                );
                                                                setAllPrices(
                                                                    data
                                                                );
                                                                handleErase();
                                                            } else {
                                                                toast.error(
                                                                    "Une erreur est survenue",
                                                                    {
                                                                        position:
                                                                            toast
                                                                                .POSITION
                                                                                .BOTTOM_RIGHT,
                                                                    }
                                                                );
                                                            }
                                                        } catch {
                                                            toast.error(
                                                                "Une erreur est survenue",
                                                                {
                                                                    position:
                                                                        toast
                                                                            .POSITION
                                                                            .BOTTOM_RIGHT,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                                {uniqueListName.map((name) => (
                                    <>
                                        {allPrices.map((price) => (
                                            <>
                                                {price.gamme === name &&
                                                    (gamme === "" ||
                                                        price.gamme ===
                                                            gamme) &&
                                                    (etat === "" ||
                                                        price.etat ===
                                                            etat) && (
                                                        <tr key={price.id}>
                                                            <td>
                                                                {price.gamme}
                                                            </td>
                                                            <td>
                                                                {price.kmMin} -{" "}
                                                                {price.kmMax}
                                                            </td>
                                                            <td>
                                                                {price.etat}
                                                            </td>
                                                            <td>
                                                                {
                                                                    price.prixVente
                                                                }
                                                            </td>{" "}
                                                            <td>
                                                                {
                                                                    price.prixReprise
                                                                }
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        handleErase();
                                                                        setTimeout(
                                                                            () => {
                                                                                setSelectedPrice(
                                                                                    price
                                                                                );
                                                                            },
                                                                            1000
                                                                        );
                                                                    }}
                                                                >
                                                                    Modifier
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}{" "}
        </main>
    );
}
