const initialState = {
    next_page_info_customer: undefined,
    orders_date: null,
    dateStart: null,
    dateEnd: null,
    products_by_ids: null,
    locations: null,
    productsRepair: null,
    intervalReturn: null,
    resultDateCount: null,
    new_line_items: null,
    statsTdb: null,
};

export default function Stats(state = initialState, action) {
    switch (action.type) {
        case "GET_LOCATIONS":
            return {
                ...state,
                locations: action.locations,
            };
        case "GET_INVENTORY":
            return {
                ...state,
                inventory: action.inventory,
                inventory_details: action.inventory_details,
            };
        case "ERASE_INVENTORY":
            return {
                ...state,
                inventory: action.inventory,
                inventory_details: action.inventory_details,
            };
        case "GET_PRODUCTS":
            return {
                ...state,
                products: action.products,
                link_inventory_product: action.link_inventory_product,
            };
        case "GET_PRODUCTS_BY_IDS":
            return {
                ...state,
                products_by_ids: action.products_by_ids,
            };
        case "GET_PRODUCTS_REPARATIONS":
            return {
                ...state,
                productsRepair: action.productsRepair,
            };
        case "GET_ORDERS_BY_DATE":
            return {
                ...state,
                orders_date: action.orders_date,
                dateStart: action.dateStart,
                dateEnd: action.dateEnd,
                intervalReturn: action.intervalReturn,
                resultDateCount: action.resultDateCount,
            };
        case "GET_ORDERS_BY_DATE_TDB":
            return {
                ...state,
                statsTdb: action.statsTdb,
            };
        case "ERASE_ORDERS_BY_DATE":
            return {
                ...state,
                orders_date: action.orders_date,
            };
        case "GET_CUSTOMERS":
            return {
                ...state,
                customers: action.customers,
                next_page_info_customer: action.next_page_info_customer,
            };
        case "GET_NEXT_CUSTOMERS":
            return {
                ...state,
                customers: [...state.customers, ...action.customers],
                next_page_info_customer: action.next_page_info_customer,
            };
        case "GET_COLLECTION_VELOS":
            return {
                ...state,
                collection_velos: action.collection_velos,
            };
        case "DICO_PRODUCT_VELO":
            return {
                ...state,
                dicoProductsVelo: action.dicoProductsVelo,
                sumQuantity: action.sumQuantity,
                new_line_items: action.new_line_items,
                dicoSortedLocation: action.dicoSortedLocation,
            };
        case "DICO_PRODUCT_REPAIR":
            return {
                ...state,
                productsRepairByLocations: action.productsRepairByLocations,
            };
        default:
            return state;
    }
}
