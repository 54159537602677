import { toast } from "react-toastify";
import axios from "axios";

export const setBatterieDetect = (state) => {
    return (dispatch) => {
        return dispatch({
            type: "batterieDetect",
            batterieDetect: state,
        });
    };
};

export const setBatterieState = (state) => {
    return (dispatch) => {
        return dispatch({
            type: "batterieState",
            batterieState: state,
        });
    };
};

export const setGarantieState = (garantie) => {
    return (dispatch) => {
        return dispatch({
            type: "GARANTIE",
            garantie: garantie,
        });
    };
};

export const setKilometrage = (km) => {
    return (dispatch) => {
        return dispatch({
            type: "KILOMETRAGE",
            kilometrage: km,
        });
    };
};

export const setEtatState = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "ETAT",
            etat: value,
        });
    };
};

export const setToken = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_TOKEN",
            token: value,
        });
    };
};

export const connectAccountStaff = (mail, pass) => {
    return async (dispatch) => {
        toast.info("Demande de connexion en cours, veuillez patienter...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
        });
        return await axios
            .post(
                `https://api.levelomad.com/api/authentication/login/`,
                {
                    username: mail,
                    password: pass,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "same-origin",
                }
            )
            .then((res) => {
                return dispatch({
                    type: "SEND_LOGIN_ACCESS",
                    accessToken: res.data.access,
                    refreshToken: res.data.refresh,
                });
            })

            .catch((error) => {
                toast.error(
                    "Impossible de se connecter, Verifier vos informations",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: "SEND_LOGIN_ACCESS",
                    user: null,
                    accessToken: null,
                    refreshToken: null,
                });
            });
    };
};

export const getAuthUser = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/authentication/login/user_connected/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    "Content-Type": "application/x-www-form-urlencoded",
                    credentials: "same-origin",
                }
            )
            .then((res) => {
                return dispatch({
                    type: "AUTH_USER",
                    authUser: res.data,
                });
            })

            .catch((error) => {
                toast.error(
                    "Impossible de se connecter, Verifier vos informations",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: "AUTH_USER",
                    authUser: null,
                });
            });
    };
};

export const getListStaff = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/authentication/users/v1/listing/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    "Content-Type": "application/x-www-form-urlencoded",
                    credentials: "same-origin",
                }
            )
            .then((res) => {
                return dispatch({
                    type: "LIST_STAFF",
                    listUser: res.data,
                });
            })

            .catch((error) => {
                toast.error("Impossible d'obtenir la liste du Staff'", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: "LIST_STAFF",
                    listUser: null,
                });
            });
    };
};

export const logout = () => {
    return (dispatch) => {
        return dispatch({
            type: "LOGOUT",
        });
    };
};

export const createUserToEdit = (formData, accessToken, authUser) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "post",
            url: `https://api.levelomad.com/api/authentication/user/v1/create/`,
            data: formData,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                toast.success("C'est créé", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "CREATE_USER",
                    editUser: null,
                });
            })

            .catch((error) => {
                toast.error("Impossible de créer... ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "CREATE_USER",
                });
            });
    };
};
export const putUserToEdit = (user, accessToken, authUser) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };

        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "put",
            url: `https://api.levelomad.com/api/authentication/user/v1/edit/`,
            data: user,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                toast.success("MAJ Enregistrée...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: "PUT_USER_TO_EDIT",
                    editUser: null,
                });
            })

            .catch((error) => {
                toast.error("Impossible de modifier les détails", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "PUT_USER_TO_EDIT",
                    editUser: null,
                });
            });
    };
};
