/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Calendar from "react-calendar";
import "../Stats/calendar.css";
// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import LoadingCycle from "../Sav/LoaderCycle";
import { MdLocalShipping } from "react-icons/md";
import {
    getOrdersByDate,
    eraseOrdersDate,
} from "../../Redux/actions/stats.actions";

export default function CustomersOrdersBike() {
    const accessToken = useSelector((store) => store.user.accessToken);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const dispatch = useDispatch();

    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);

    const handleSubmit = (e) => {
        e.preventDefault();
        let table = undefined;

        table = document.getElementById("my-customers");

        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        formData.append("Title", "Export Client Shopify");

        async function PostCreateSav(formData) {
            setUrlCsvOrdersDate(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvOrdersDate(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateSav(formData);
    };
    const maxDate = new Date();
    maxDate.setDate(new Date().getDate());
    const [dateStart, setDateStart] = useState(undefined);
    const [dateEnd, setDateEnd] = useState(undefined);
    const orders = useSelector((state) => state.Stats.orders_date);
    const [newLinesItems, setNewLinesItems] = useState(undefined);
    function handleSetDate(dateSelected) {
        if (dateSelected !== undefined && dateSelected.length >= 2) {
            let checkInDate = new Date();
            checkInDate.setFullYear(dateSelected[0].getFullYear());
            checkInDate.setMonth(dateSelected[0].getMonth());
            checkInDate.setDate(dateSelected[0].getDate());
            checkInDate = String(checkInDate.toISOString().split("T")[0]);
            setDateStart(checkInDate);
            let checkOutDate = new Date();
            checkOutDate.setFullYear(dateSelected[1].getFullYear());
            checkOutDate.setMonth(dateSelected[1].getMonth());
            checkOutDate.setDate(dateSelected[1].getDate());
            checkOutDate = String(checkOutDate.toISOString().split("T")[0]);
            setDateEnd(checkOutDate);
            dispatch(eraseOrdersDate());
            dispatch(getOrdersByDate(checkInDate, checkOutDate, accessToken));
        }
    }

    useEffect(() => {
        const new_line_items = {};
        if (orders !== null && orders.length > 0) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[order.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                        };
                    });
                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }
                }
            });
        }
        const new_orders = {};
        if (Object.keys(new_line_items).length > 0) {
            Object.keys(new_line_items).map((key) => {
                if (
                    new_line_items[key].quantity > 0 &&
                    new_line_items[key].price > 800
                ) {
                    if (
                        !new_orders.hasOwnProperty(
                            new_line_items[key].order.customer.email
                        )
                    ) {
                        new_orders[new_line_items[key].order.customer.email] =
                            [];
                    }
                    new_orders[new_line_items[key].order.customer.email] =
                        new_line_items[key];
                }
            });
        }
        setNewLinesItems(new_orders);
    }, [orders]);

    return (
        <>
            <h2>Clients ayant achetés un vélo</h2>

            <div className="calendar-customer">
                <div className="mx-auto">
                    <small className="text-center">Date de Période</small>
                </div>
                <Calendar
                    onChange={(e) => handleSetDate(e)}
                    selectRange={true}
                    culture="fr"
                    maxDate={maxDate}
                    showNeighboringMonth={true}
                />
                {dateStart !== undefined && dateEnd !== undefined && (
                    <>
                        <div className="my-2">
                            Du {dateStart} 00h00 <br /> Au {dateEnd} 23h59
                        </div>
                    </>
                )}
            </div>

            <div className="text-center f-column">
                <button onClick={(e) => handleSubmit(e)}>
                    Demande d'export
                </button>
                {urlCsvOrdersDate !== undefined && (
                    <a
                        href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="btn">
                            Obtenir le CSV d'export <BsFiletypePdf />{" "}
                            <BsBoxArrowUpRight />
                        </button>
                    </a>
                )}
            </div>
            <div className="text-center">
                <table id="my-customers" className="table-customer">
                    <thead>
                        <tr id="td-header">
                            <td>Commande</td>
                            <td>Email</td>
                            <td>Nom - Prénom</td>
                            <td>Email Marketing approuvé</td>
                            <td>Date de Commande</td>
                        </tr>
                    </thead>

                    <tbody id={"body-customer-bike"}>
                        {newLinesItems !== undefined &&
                        Object.keys(newLinesItems).length > 0 ? (
                            Object.keys(newLinesItems).map((key, index) => (
                                <>
                                    {parseFloat(newLinesItems[key].quantity) !==
                                        parseFloat(0) &&
                                        newLinesItems[key].price > 800 &&
                                        document.getElementsByClassName(
                                            "customer-mail" + (index - 1)[0]
                                        ) !==
                                            newLinesItems[key].order.customer
                                                .email && (
                                            <tr key={"customer-bike" + index}>
                                                <td>
                                                    {newLinesItems[key].order
                                                        .fulfillment_status ===
                                                    "fulfilled" ? (
                                                        <>
                                                            <a
                                                                href={
                                                                    "https://admin.shopify.com/store/velomad/orders/" +
                                                                    newLinesItems[
                                                                        key
                                                                    ].order.id
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="d-row-space"
                                                            >
                                                                <MdLocalShipping className="text-green" />
                                                                {
                                                                    newLinesItems[
                                                                        key
                                                                    ].order.name
                                                                }
                                                            </a>
                                                            <br />{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a
                                                                href={
                                                                    "https://admin.shopify.com/store/velomad/orders/" +
                                                                    newLinesItems[
                                                                        key
                                                                    ].order.id
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="d-row-space"
                                                            >
                                                                <MdLocalShipping className="text-red" />
                                                                {
                                                                    newLinesItems[
                                                                        key
                                                                    ].order.name
                                                                }
                                                            </a>
                                                            <br />
                                                        </>
                                                    )}
                                                </td>
                                                <td
                                                    className={
                                                        "customer-mail" + index
                                                    }
                                                >
                                                    {
                                                        newLinesItems[key].order
                                                            .customer.email
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        newLinesItems[key].order
                                                            .customer.last_name
                                                    }{" "}
                                                    {
                                                        newLinesItems[key].order
                                                            .customer.first_name
                                                    }
                                                </td>
                                                <td>
                                                    {newLinesItems[key].order
                                                        .customer
                                                        .accepts_marketing ===
                                                    true ? (
                                                        "oui"
                                                    ) : (
                                                        <span className="text-red">
                                                            non
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    {
                                                        newLinesItems[
                                                            key
                                                        ].order.created_at.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                </>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">
                                    <LoadingCycle />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
