const initialState = {
    garantie: null,
    garantieImport: [],
    etat: null,
    accessToken: null,
    authUser: null,
    listUser: null,
    batterieDetect: false,
    batterieState: false,
    kilometrage: null,
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case "batterieDetect":
            return {
                ...state,
                batterieDetect: action.batterieDetect,
            };
        case "batterieState":
            return {
                ...state,
                batterieState: action.batterieState,
            };
        case "GARANTIE":
            return {
                ...state,
                garantie: action.garantie,
            };
        case "ETAT":
            return {
                ...state,
                etat: action.etat,
            };
        case "KILOMETRAGE":
            return {
                ...state,
                kilometrage: action.kilometrage,
            };

        case "SET_TOKEN":
            return {
                ...state,
                token: action.token,
            };
        case "SEND_LOGIN_ACCESS":
            return {
                ...state,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
            };
        case "AUTH_USER":
            return {
                ...state,
                authUser: action.authUser,
            };
        case "LIST_STAFF":
            return {
                ...state,
                listUser: action.listUser,
            };
        case "LOGOUT":
            return {
                ...state,
                authUser: null,
                accessToken: null,
                refreshToken: null,
            };

        default:
            return state;
    }
}
