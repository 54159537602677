/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingCycle from "../Sav/LoaderCycle";
import { BiLogoShopify } from "react-icons/bi";
import DateSelected from "./DateSelected";

export default function EtatArticlesNonExpedies() {
    document.title = "VELOMAD - Stats - Articles non expédiés";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const orders = useSelector((state) => state.Stats.orders_date);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [newLinesItems, setNewLinesItems] = useState({});
    const [totalQuantity, setTotalQauntity] = useState(0.0);

    function add(accumulator, a) {
        return accumulator + a;
    }
    useEffect(() => {
        const new_line_items = {};
        if (orders !== undefined && orders !== null && orders.length > 0) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid" &&
                    order.fulfillment_status !== "fulfilled" &&
                    order.closed_at !== "null"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[item.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                        };
                    });
                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }

                    // detect if fulfilled success ! not cancelled
                    if (order.fulfillments.length > 0) {
                        order.fulfillments.map((fulfillment) => {
                            if (fulfillment.line_items.length > 0) {
                                fulfillment.line_items.map(
                                    (fulfillment_item) => {
                                        if (
                                            new_line_items.hasOwnProperty(
                                                fulfillment_item.id
                                            ) &&
                                            fulfillment.status !== "cancelled"
                                        ) {
                                            new_line_items[
                                                fulfillment_item.id
                                            ].quantity =
                                                new_line_items[
                                                    fulfillment_item.id
                                                ].quantity -
                                                fulfillment_item.quantity;
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            });
            setTimeout(() => {
                const allQuantity = Array.from(
                    document.getElementsByClassName("quantity-all-not-sended")
                );
                const sumQuantity = [];
                if (allQuantity.length > 0) {
                    allQuantity.map((element) => {
                        if (!isNaN(element.innerText)) {
                            sumQuantity.push(parseFloat(element.innerText));
                        }
                    });
                }
                setTotalQauntity(sumQuantity.reduce(add, 0));
            }, 1000);
        }
        setNewLinesItems(new_line_items);
    }, [orders]);

    return (
        <main>
            <h1 id="all-not-sended">Totalites des articles non expedies</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    {" "}
                    <DateSelected />
                    {orders !== null &&
                        orders !== undefined &&
                        orders.length >= 0 && (
                            <>
                                {" "}
                                <p className="text-center mb-4">
                                    <span
                                        className={
                                            "velo-pdv-body-sum-precommande"
                                        }
                                    >
                                        total Articles non expédiés :{" "}
                                        {totalQuantity}
                                    </span>
                                </p>
                                <table
                                    id="all-not-sended"
                                    className="table-center"
                                >
                                    <thead>
                                        <tr className="td-header">
                                            <td>TAG</td>
                                            <td>Produits</td>
                                            <td>Sku</td>
                                            <td>Quantité</td>
                                            <td>Commandes Associées</td>
                                            <td>Mail</td>
                                            <td>Date</td>
                                        </tr>
                                    </thead>
                                    <tbody id={"body-all-not-sended"}>
                                        {Object.keys(newLinesItems).length >
                                        0 ? (
                                            Object.keys(newLinesItems).map(
                                                (key) => (
                                                    <>
                                                        {parseFloat(
                                                            newLinesItems[key]
                                                                .quantity
                                                        ) !== parseFloat(0) && (
                                                            <tr
                                                                key={
                                                                    "all-not-sended" +
                                                                    key
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        newLinesItems[
                                                                            key
                                                                        ].order
                                                                            .tags
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        href={
                                                                            "https://admin.shopify.com/store/velomad//products/" +
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                                .product
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        newLinesItems[
                                                                            key
                                                                        ].sku
                                                                    }
                                                                </td>
                                                                <td className="quantity-all-not-sended">
                                                                    {
                                                                        newLinesItems[
                                                                            key
                                                                        ]
                                                                            .quantity
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    <a
                                                                        href={
                                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                                .order
                                                                                .id
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <BiLogoShopify />
                                                                        {
                                                                            newLinesItems[
                                                                                key
                                                                            ].order.name.split(
                                                                                "#"
                                                                            )[1]
                                                                        }
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        newLinesItems[
                                                                            key
                                                                        ].order
                                                                            .customer
                                                                            .email
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        newLinesItems[
                                                                            key
                                                                        ].order.created_at.split(
                                                                            "T"
                                                                        )[0]
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="8">
                                                    <LoadingCycle />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <p className="text-center mb-4">
                                    <span
                                        className={
                                            "velo-pdv-body-sum-precommande"
                                        }
                                    >
                                        total Articles non expédiés :{" "}
                                        {totalQuantity}
                                    </span>
                                </p>
                            </>
                        )}{" "}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
