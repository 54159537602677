import "./maps.css";
import "./sav.css";
import "./parrainage.css";
import "./ventesprivees.css";
import "../Components/User/form.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import LoadingCycle from "../Components/Sav/LoaderCycle";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";
import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";

export default function PageVentesPrivees2024() {
    document.title = "Ventes Privées 2024 - Velomad";
    document.description =
        "Profitez de nos vente privées pour obtenir des réductions exclusives sur une sélection de produits Velomad.";
    const openNavBurger = useSelector((state) => state.style.openNavBurger);
    const [step, setStep] = useState(1);
    const [inputMail, setInputMail] = useState("");
    const [inputFirstName, setInputFirstName] = useState("");
    const [inputLastName, setInputLastName] = useState("");
    const today = new Date();
    const expire = new Date("2024-06-26 08:00:00 GMT+0200");
    console.log(today, expire);
    const [verifMail, setVerifMail] = useState(false);
    const [confirmInscription, setConfirmInscription] = useState(false);
    const [confirmRGPD, setConfirmRGPD] = useState(false);
    const [finish] = useState(true);
    const [choiceAccount, setChoiceAccount] = useState("noAccount");
    const dataChecked = (element) => {
        element.classList.remove("bg-error");
        element.classList.add("form-checked");
    };

    // set red background  and set attribute data-error
    const dataError = (element) => {
        element.focus();
        element.classList.add("bg-error");
        element.classList.remove("form-checked");
    };
    const checkInputText = (element) => {
        if (element !== null && element.value.length < 2) {
            element.setAttribute(
                "data-error",
                `Veuillez entrer 2 caractères ou plus pour le champ ${element.previousElementSibling.innerText}`
            );
            element.focus();
            dataError(element);
        } else {
            dataChecked(element);
        }
    };
    const checkValueEmail = (element) => {
        // control data with regex mail
        if (
            element !== null &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element.value)
        ) {
            setVerifMail(false);
            setInputMail("");
            element.setAttribute(
                "data-error",
                "Vous devez saisir une adresse mail valide."
            );
            dataError(element);
        } else {
            dataChecked(element);
            setVerifMail(true);
            setInputMail(element.value);
        }
    };
    const checkConfirmInscription = (element) => {
        if (element.target.checked === true) {
            setConfirmInscription(true);
            dataChecked(document.getElementById("confirmFormLabelInscription"));
        } else {
            setConfirmInscription(false);
            dataError(document.getElementById("confirmFormLabelInscription"));
        }
    };
    const checkConfirmRGPD = (element) => {
        if (element.target.checked === true) {
            setConfirmRGPD(true);
            dataChecked(document.getElementById("confirmFormLabel"));
        } else {
            setConfirmRGPD(false);
            dataError(document.getElementById("confirmFormLabel"));
        }
    };
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("mail", inputMail);
        formData.append("nom", inputLastName);
        formData.append("prenom", inputFirstName);
        formData.append("account", choiceAccount);

        async function PostCreateSav(formData) {
            toast.success("Envoi de la demande", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setStep(2);
            try {
                const response = await fetch(
                    `https:///api.levelomad.com/api/ventesprivees/v1/create_new_code/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (data.msg !== undefined) {
                    toast.success("Envoi Réussie ! ✅", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setStep(3);
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {}
        }
        PostCreateSav(formData);
    };
    return (
        <>
            {openNavBurger === false && (
                <>
                    <main className="main-sav ">
                        <div className="banner-vp"></div>
                        <div className="banner-vp-title">
                            <h1>Les ventes privées Velomad</h1>
                        </div>
                        {finish === false ? (
                            <div className=" dimension-main ">
                                <div className="parcours dimension-main">
                                    <h2>
                                        Jusqu'à 400€ de réduction sur une partie
                                        de la gamme Velomad !
                                    </h2>{" "}
                                    <h3>
                                        Offre valable du 19/06/2024 au
                                        26/06/2024 jusqu'à 08h00
                                    </h3>
                                </div>
                                <div className="align-sav">
                                    <p>
                                        Rejoignez notre liste exclusive pour
                                        accéder aux ventes privées et profitez
                                        des meilleures offres avant tout le
                                        monde. En vous inscrivant, vous
                                        bénéficierez de :
                                    </p>
                                    <ul className="list-vp">
                                        <li>
                                            <span className="font-bold">
                                                Réductions exclusives
                                            </span>{" "}
                                            sur une sélection de produits.
                                        </li>
                                        <li>
                                            <span className="font-bold">
                                                Éviter les ruptures de stock{" "}
                                            </span>{" "}
                                            pendant les soldes.
                                        </li>
                                        <li>
                                            <span className="font-bold">
                                                Offres spéciales
                                            </span>{" "}
                                            réservées aux membres inscrits.
                                        </li>
                                    </ul>
                                    <p>
                                        Ne manquez pas cette opportunité unique
                                        de faire des affaires incroyables et
                                        d'obtenir les premiers choix.
                                        Inscrivez-vous dès maintenant pour être
                                        parmi les privilégiés !
                                    </p>
                                    <div className="parcours">
                                        <h2>Comment ça marche ? </h2>
                                    </div>

                                    <ul className="list-vp">
                                        <li>
                                            <span className="font-bold">
                                                Inscrivez-vous
                                            </span>{" "}
                                            en remplissant le formulaire
                                            ci-dessous.
                                        </li>
                                        <li>
                                            <span className="font-bold">
                                                Recvevez votre code de réduction{" "}
                                            </span>{" "}
                                            par email avec votre accès exclusif.
                                        </li>
                                        <li>
                                            <span className="font-bold">
                                                Accédez à notre site
                                            </span>{" "}
                                            et profitez des offres
                                            exceptionnelles.
                                        </li>
                                    </ul>
                                    <p>
                                        Nous respectons votre vie privée et nous
                                        nous engageons à ne jamais partager vos
                                        informations avec des tiers. Consultez
                                        notre [Politique de Confidentialité]
                                        pour en savoir plus.
                                    </p>
                                    <p className="font-bold  text-xl">
                                        Rejoignez-nous dès aujourd'hui et faites
                                        partie de notre communauté VIP !
                                    </p>

                                    <div className="card-vp">
                                        <form
                                            onSubmit={(e) => e.preventDefault()}
                                        >
                                            <div className="d-flex-row">
                                                <div className="fr-checkbox-group">
                                                    <input
                                                        onChange={(e) =>
                                                            setChoiceAccount(
                                                                e.target.value
                                                            )
                                                        }
                                                        type="radio"
                                                        id={"radioNoAccount"}
                                                        name="radioAccount"
                                                        value={"noAccount"}
                                                        className="mouseover"
                                                    />

                                                    <label
                                                        htmlFor={
                                                            "radioNoAccount"
                                                        }
                                                    >
                                                        Je n'ai pas de compte
                                                        client
                                                    </label>
                                                </div>
                                                <div className="fr-checkbox-group">
                                                    <input
                                                        onChange={(e) =>
                                                            setChoiceAccount(
                                                                e.target.value
                                                            )
                                                        }
                                                        type="radio"
                                                        id={"radioYesAccount"}
                                                        name="radioAccount"
                                                        value={
                                                            "radioYesAccount"
                                                        }
                                                        className="mouseover"
                                                    />

                                                    <label
                                                        htmlFor={
                                                            "radioYesAccount"
                                                        }
                                                    >
                                                        Je suis déjà client
                                                    </label>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex-row-center">
                                                    <label htmlFor="email">
                                                        Email :{" "}
                                                    </label>
                                                    <input
                                                        type="email"
                                                        placeholder="Email*"
                                                        className="form-control"
                                                        required
                                                        id="email"
                                                        maxLength={300}
                                                        onBlur={(e) =>
                                                            checkValueEmail(
                                                                e.target
                                                            )
                                                        }
                                                    />
                                                </div>{" "}
                                                {!verifMail && (
                                                    <div className="d-flex-row">
                                                        <p className="text-error">
                                                            Merci de saisir une
                                                            adresse mail valide
                                                            avant de poursuivre.
                                                        </p>
                                                    </div>
                                                )}
                                                <div className="d-flex-row-center">
                                                    <label htmlFor="lastName">
                                                        Nom :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        className="form-control"
                                                        placeholder="Nom"
                                                        maxLength={300}
                                                        required
                                                        onChange={(e) =>
                                                            setInputLastName(
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            checkInputText(
                                                                document.getElementById(
                                                                    "lastName"
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {inputLastName !== "" &&
                                                    inputLastName.length <
                                                        2 && (
                                                        <div className="d-flex-row">
                                                            <p className="text-error">
                                                                Merci de saisir
                                                                votre Nom
                                                            </p>
                                                        </div>
                                                    )}
                                                <div className="d-flex-row-center">
                                                    <label htmlFor="firstName">
                                                        Prénom :{"  "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        className="form-control"
                                                        placeholder="Prénom"
                                                        maxLength={300}
                                                        required
                                                        onChange={(e) =>
                                                            setInputFirstName(
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            checkInputText(
                                                                document.getElementById(
                                                                    "firstName"
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {inputFirstName !== "" &&
                                                    inputFirstName.length <
                                                        2 && (
                                                        <div className="d-flex-row">
                                                            <p className="text-error">
                                                                Merci de saisir
                                                                votre prénom
                                                            </p>
                                                        </div>
                                                    )}
                                                <div className="confirmForm mb-4">
                                                    <input
                                                        id="confirmFormInscription"
                                                        type="checkbox"
                                                        className=""
                                                        onClick={(e) => {
                                                            checkConfirmInscription(
                                                                e
                                                            );
                                                        }}
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="confirmFormInscription"
                                                        id="confirmFormLabelInscription"
                                                    >
                                                        J'accepte les{" "}
                                                        <a
                                                            href="https://levelomad.com/pages/conditions-utilisation"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Conditions Générales
                                                            de vente
                                                        </a>
                                                        , j'ai pris connaissance
                                                        de la{" "}
                                                        <a
                                                            href="https://levelomad.com/pages/mentions-legales"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            politique de
                                                            confidentialité et
                                                            des traitements de
                                                            mes données
                                                            personnelles
                                                        </a>{" "}
                                                        et je confirme être
                                                        majeur(e).
                                                    </label>
                                                </div>
                                                <div className="confirmForm mb-4">
                                                    <input
                                                        id="confirmForm"
                                                        type="checkbox"
                                                        onClick={(e) => {
                                                            checkConfirmRGPD(e);
                                                        }}
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="confirmForm"
                                                        id="confirmFormLabel"
                                                        className=""
                                                    >
                                                        Je donne mon accord pour
                                                        être inscrit à la
                                                        newsletter du Velomad et
                                                        pour que mes données
                                                        personnelles soient
                                                        utilisées dans le cadre
                                                        de ma demande.
                                                    </label>
                                                </div>
                                                {verifMail &&
                                                inputLastName !== "" &&
                                                inputLastName.length >= 2 &&
                                                inputFirstName !== "" &&
                                                inputFirstName.length >= 2 &&
                                                confirmInscription === true &&
                                                confirmRGPD === true ? (
                                                    <button
                                                        className={
                                                            "velo_product mx-0"
                                                        }
                                                        onMouseOver={(e) =>
                                                            checkValueEmail(
                                                                document.querySelector(
                                                                    "input[type=email]"
                                                                )
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleSubmit()
                                                        }
                                                    >
                                                        Je demande mon
                                                        inscription aux ventes
                                                        privées
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={
                                                            "velo_product mx-0"
                                                        }
                                                        onMouseOver={(e) =>
                                                            checkValueEmail(
                                                                document.querySelector(
                                                                    "input[type=email]"
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Merci de compléter le
                                                        formulaire
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {step === 2 && (
                                    <>
                                        <div className="parcours">
                                            <h2>Envoi de votre demande</h2>{" "}
                                        </div>
                                        <p className="my-3 align-sav">
                                            Merci de patienter quelques
                                            instants...
                                        </p>
                                        <LoadingCycle />
                                    </>
                                )}
                                {step === 3 && (
                                    <>
                                        <div className="parcours">
                                            <h2>Demande Envoyée</h2>{" "}
                                        </div>
                                        <p className="my-3 align-sav">
                                            Merci de votre participation !
                                        </p>
                                        <p className="align-sav">
                                            Surveillez bien votre boîte mail (et
                                            vos spams) pour recevoir votre code
                                            de réduction.
                                        </p>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className=" dimension-main ">
                                <div className="parcours dimension-main">
                                    <h2>
                                        Les vente privées sont désormais
                                        terminées, merci de votre participation
                                        !
                                    </h2>
                                </div>
                            </div>
                        )}
                    </main>
                    <SingeFooterShopifyVelomad />
                    <FooterShopifyVelomad />
                </>
            )}
        </>
    );
}
