import { useEffect } from "react";

import { useFetchGetListSav } from "../Utils/FetchSav";
import "./gestion.css";
import { useSelector } from "react-redux";

export default function GestionSav() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);

    document.title = "VELOMAD | Liste demandes Sav";
    document.description = "Intranet du VELOMAD";
    const { dataListSav } = useFetchGetListSav();

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>
                        Liste des demandes Sav (
                        {dataListSav !== undefined &&
                            dataListSav.length > 0 &&
                            dataListSav.length}
                        )
                    </h1>
                    {dataListSav !== undefined &&
                        dataListSav.map((sav) => (
                            <div key={"sav" + sav.id} className="card_user">
                                <div>
                                    #{sav.id} - {sav.mail}
                                </div>
                                <hr />
                                <div>Création : {sav.created}</div>

                                <div>Modèle : {sav.modele}</div>
                                <hr />
                                <div>
                                    {sav.message.split("MSG :")[0]}
                                    <br />
                                    Message : <br />
                                    {sav.message.split("MSG :")[1]}
                                </div>
                                <hr />
                                <div>
                                    Panier :
                                    {sav.panier !== "" &&
                                        sav.panier
                                            .split("## ,")
                                            .map((element) => (
                                                <div>{element}</div>
                                            ))}
                                </div>
                            </div>
                        ))}
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
