import { useEffect } from "react";
import "./stats.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosBicycle } from "react-icons/io";
import { PiMotorcycleThin } from "react-icons/pi";
import { BsFillSendExclamationFill } from "react-icons/bs";
import { GiAutoRepair } from "react-icons/gi";
import { FaPuzzlePiece } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { IoStorefrontSharp } from "react-icons/io5";
import { GrDashboard } from "react-icons/gr";

export default function GestionStats(props) {
    document.title = "VELOMAD - Statistiques";
    document.description = "Statistiques";
    const accessToken = useSelector((store) => store.user.accessToken);
    const authUser = useSelector((store) => store.user.authUser);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1> Statistiques</h1>
                    <div className="text-center">
                        <div className="nav-erp-stats">
                            <Link
                                to="/gestion/stats/dispo"
                                className={
                                    props.link === "stock" ? "link-active" : ""
                                }
                            >
                                <IoStorefrontSharp />
                                <span>Produits "Disponibles" à la Vente</span>
                            </Link>
                            {dateStart !== null && dateEnd !== null ? (
                                <>
                                    <Link
                                        to="/gestion/stats/stock"
                                        className={
                                            props.link === "stock"
                                                ? "link-active"
                                                : ""
                                        }
                                    >
                                        <IoStorefrontSharp />
                                        <span>
                                            Etat des Stocks ! Prendre
                                            Intervalles de TOUTES les commandes
                                            !
                                        </span>
                                    </Link>
                                    <div className="my-5"></div>
                                    <Link
                                        to="/gestion/stats/tdb"
                                        className={
                                            props.link === "tdb"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <span className="bg-yellow-stats">
                                            <GrDashboard /> Tableau de Bord
                                        </span>
                                    </Link>
                                    <div className="my-5"></div>
                                    <Link
                                        to="/gestion/stats/commandes"
                                        className={
                                            props.link === "commandes"
                                                ? "link-active"
                                                : ""
                                        }
                                    >
                                        <FaShoppingCart />
                                        <span>Commandes</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/pieces"
                                        className={
                                            props.link === "pieces"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <FaPuzzlePiece />

                                        <span>Pièces</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/reparations"
                                        className={
                                            props.link === "reparations"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <GiAutoRepair />

                                        <span>Réparations</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/velos"
                                        className={
                                            props.link === "velos"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <IoIosBicycle />

                                        <span>Vélos</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/precommandes"
                                        className={
                                            props.link === "precommandes"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <PiMotorcycleThin />

                                        <span>Précommandes et autres tags</span>
                                    </Link>

                                    <Link
                                        to="/gestion/stats/nonlivres"
                                        className={
                                            props.link === "nonlivres"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <BsFillSendExclamationFill />

                                        <span>Expédition</span>
                                    </Link>
                                </>
                            ) : (
                                <p>
                                    Merci de sélectionner vote Période
                                    d'extraction de données
                                </p>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
