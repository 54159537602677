export const datacountry = [
    { value: "AL", label: "Albanie" },
    { value: "AD", label: "Andorre" },

    { value: "AG", label: "Antigua-et-Barbuda" },

    { value: "AT", label: "Autriche" },

    { value: "BY", label: "Biélorussie" },

    { value: "BE", label: "Belgique" },

    { value: "BO", label: "Bolivie" },

    { value: "BA", label: "Bosnie-Herzégovine" },

    { value: "BG", label: "Bulgarie" },

    { value: "CA", label: "Canada" },

    { value: "CV", label: "Cap-Vert" },

    { value: "CN", label: "Chine" },

    { value: "CX", label: "Île Christmas" },

    { value: "CC", label: "Îles Cocos" },

    { value: "HR", label: "Croatie" },

    { value: "CY", label: "Chypre" },

    { value: "CZ", label: "République tchèque" },

    { value: "DK", label: "Danemark" },

    { value: "EE", label: "Estonie" },

    { value: "FK", label: "Îles Malouines" },

    { value: "FO", label: "Îles Féroé" },

    { value: "FJ", label: "Fidji" },

    { value: "FI", label: "Finlande" },

    { value: "FR", label: "France" },

    { value: "GF", label: "Guyane française" },

    { value: "PF", label: "Polynésie française" },

    { value: "TF", label: "Terres australes françaises" },

    { value: "GE", label: "Géorgie" },

    { value: "DE", label: "Allemagne" },

    { value: "GH", label: "Ghana" },

    { value: "GI", label: "Gibraltar" },

    { value: "GR", label: "Grèce" },

    { value: "GP", label: "Guadeloupe" },

    { value: "GU", label: "Guam" },

    { value: "GT", label: "Guatemala" },

    { value: "GG", label: "Guernesey" },

    { value: "GN", label: "Guinée" },

    { value: "GW", label: "Guinée-Bissau" },

    { value: "GY", label: "Guyana" },

    { value: "HM", label: "Îles Heard-et-MacDonald" },

    { value: "HN", label: "Honduras" },

    { value: "HU", label: "Hongrie" },

    { value: "IS", label: "Islande" },

    { value: "IE", label: "Irlande" },

    { value: "IT", label: "Italie" },

    { value: "JP", label: "Japon" },

    { value: "JE", label: "Jersey" },

    { value: "JO", label: "Jordanie" },

    { value: "LV", label: "Lettonie" },

    { value: "LT", label: "Lituanie" },

    { value: "LU", label: "Luxembourg" },

    { value: "MK", label: "Macédoine" },

    { value: "MT", label: "Malte" },

    { value: "MQ", label: "Martinique" },

    { value: "MR", label: "Mauritanie" },

    { value: "MU", label: "Maurice" },

    { value: "YT", label: "Mayotte" },

    { value: "MD", label: "Moldavie" },

    { value: "MC", label: "Monaco" },

    { value: "ME", label: "Monténégro" },

    { value: "MS", label: "Montserrat" },

    { value: "MA", label: "Maroc" },

    { value: "NL", label: "Pays-Bas" },

    { value: "NC", label: "Nouvelle-Calédonie" },

    { value: "NZ", label: "Nouvelle-Zélande" },

    { value: "NO", label: "Norvège" },

    { value: "PL", label: "Pologne" },

    { value: "PT", label: "Portugal" },

    { value: "PR", label: "Porto Rico" },

    { value: "QA", label: "Qatar" },

    { value: "RO", label: "Roumanie" },

    { value: "RU", label: "Russie" },

    { value: "RE", label: "Réunion" },

    { value: "BL", label: "Saint-Barthélemy" },

    { value: "SH", label: "Sainte-Hélène" },

    { value: "KN", label: "Saint-Christophe-et-Niévès" },

    { value: "LC", label: "Sainte-Lucie" },

    { value: "MF", label: "Saint-Martin" },

    { value: "PM", label: "Saint-Pierre-et-Miquelon" },

    { value: "VC", label: "Saint-Vincent-et-les-Grenadines" },

    { value: "WS", label: "Samoa" },

    { value: "SM", label: "Saint-Marin" },

    { value: "SC", label: "Seychelles" },

    { value: "SX", label: "Saint-Martin" },

    { value: "SK", label: "Slovaquie" },

    { value: "SI", label: "Slovénie" },

    { value: "ES", label: "Espagne" },

    { value: "SE", label: "Suède" },

    { value: "CH", label: "Suisse" },
];
