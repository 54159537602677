/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from "react-redux";
import {
    getLocations,
    getInventoryItems,
    getProducts,
    eraseInventory,
    getCollectionVelos,
} from "../../Redux/actions/stats.actions";
import { useEffect, useState } from "react";
import "../../Pages/sav.css";
import LoadingCycle from "../Sav/LoaderCycle";
import { toast } from "react-toastify";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { BiArrowToTop, BiArrowFromTop } from "react-icons/bi";
import DateSelected from "./DateSelected";
import WidgetCalcStock from "./WidgetCalcStock";
import WidgetCalcAttribute from "./WidgetCalcAttribute";
import WidgetSumHTStock from "./WidgetSumHTStock";
import WidgetSumTTCStock from "./WidgetSumTTCStock";

export default function EtatStock() {
    document.title = "VELOMAD - Stats - Etats du Stock en Direct";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);

    const dispatch = useDispatch();
    const locations = useSelector((state) => state.Stats.locations);
    const inventory = useSelector((state) => state.Stats.inventory);
    const inventoryDetails = useSelector(
        (state) => state.Stats.inventory_details
    );
    const linkInvenProd = useSelector(
        (state) => state.Stats.link_inventory_product
    );
    const products = useSelector((state) => state.Stats.products);

    const [locationSelected, setLocationSelected] = useState(undefined);
    const [locationIdSelected, setLocationIdSelected] = useState(undefined);
    const [quantityMin, setQuantityMin] = useState(-50);
    const [sumQuantityAttrib, setSumQuantityAttrib] = useState(0.0);
    const [sumQuantityDispo, setSumQuantityDispo] = useState(0.0);
    const [sumQuantityStock, setSumQuantityStock] = useState(0.0);
    const [sumDispoHT, setSumDispoHT] = useState(0.0);
    const [sumDispoTTC, setSumDispoTTC] = useState(0.0);
    const [sumStockHT, setSumStockHT] = useState(0.0);
    const [sumStockTTC, setSumStockTTC] = useState(0.0);
    const [missingPriceStock, setMissingPriceStock] = useState(0);
    const [urlCsvStock, setUrlCsvStock] = useState(undefined);

    const handleClickScrollToTotaux = () => {
        setTimeout(() => {
            const element = document.getElementById("td-footer");

            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    const handleClickScrollToStart = () => {
        setTimeout(() => {
            const element = document.getElementsByTagName("h1")[0];
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    useEffect(() => {
        dispatch(getLocations(accessToken));
        dispatch(getProducts(accessToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [listingCat, setListingCat] = useState(undefined);
    const [choicedCat, setChoicedCat] = useState("Toutes");

    useEffect(() => {
        if (
            inventory !== null &&
            inventory !== undefined &&
            locationIdSelected !== undefined &&
            locationIdSelected !== null &&
            products !== null &&
            products !== undefined &&
            linkInvenProd !== null &&
            linkInvenProd !== undefined
        ) {
            const listing = [];
            Object.entries(inventory).map(([inventStore, inventStoreItems]) => {
                if (
                    parseInt(inventStore, 20) ===
                    parseInt(locationIdSelected, 20)
                ) {
                    inventStoreItems.map((items) => {
                        if (
                            listing.includes(
                                products[
                                    linkInvenProd[items.inventory_item_id][
                                        "product_id"
                                    ].toString()
                                ]["product_type"]
                            ) !== true
                        ) {
                            listing.push(
                                products[
                                    linkInvenProd[items.inventory_item_id][
                                        "product_id"
                                    ].toString()
                                ]["product_type"]
                            );
                        }
                    });
                }
            });
            setListingCat(listing.sort());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventory]);
    function add(accumulator, a) {
        return accumulator + a;
    }
    useEffect(() => {
        if (inventory !== null && inventory !== undefined) {
            setTimeout(() => {
                const allWithClassQuantityAttrib = Array.from(
                    document.getElementsByClassName("AttributeItem")
                );
                const allWithClassQuantityDispo = Array.from(
                    document.getElementsByClassName("DispoItem")
                );
                const allWithClassQuantityStock = Array.from(
                    document.getElementsByClassName("StockItem")
                );

                const allWithClassDispoHT = Array.from(
                    document.getElementsByClassName("totalDispoHT")
                );
                const allWithClassStockHT = Array.from(
                    document.getElementsByClassName("totalStockHT")
                );
                const allWithClassDispoTTC = Array.from(
                    document.getElementsByClassName("totalDispoItemTTC")
                );
                const allWithClassStockTTC = Array.from(
                    document.getElementsByClassName("totalStockItemTTC")
                );
                const quantityAttrib = [];
                const quantityDispo = [];
                const quantityStock = [];
                const sumDispoHT = [];
                const sumStockHT = [];
                const sumDispoTTC = [];
                const sumStockTTC = [];
                const error_price = [];
                if (allWithClassQuantityAttrib.length > 0) {
                    allWithClassQuantityAttrib.map((element) => {
                        if (!isNaN(element.innerText)) {
                            quantityAttrib.push(parseFloat(element.innerText));
                        } else {
                            error_price.push("error pice");
                        }
                    });
                }
                if (allWithClassQuantityDispo.length > 0) {
                    allWithClassQuantityDispo.map((element) => {
                        if (!isNaN(element.innerText)) {
                            quantityDispo.push(parseFloat(element.innerText));
                        } else {
                            error_price.push("error pice");
                        }
                    });
                }
                if (allWithClassQuantityStock.length > 0) {
                    allWithClassQuantityStock.map((element) => {
                        if (!isNaN(element.innerText)) {
                            quantityStock.push(parseFloat(element.innerText));
                        } else {
                            error_price.push("error pice");
                        }
                    });
                }
                // sum stock
                if (allWithClassDispoHT.length > 0) {
                    allWithClassDispoHT.map((element) => {
                        if (!isNaN(element.innerText)) {
                            sumDispoHT.push(parseFloat(element.innerText));
                        }
                    });
                }
                if (allWithClassStockHT.length > 0) {
                    allWithClassStockHT.map((element) => {
                        if (!isNaN(element.innerText)) {
                            sumStockHT.push(parseFloat(element.innerText));
                        }
                    });
                }
                if (allWithClassDispoTTC.length > 0) {
                    allWithClassDispoTTC.map((element) => {
                        if (!isNaN(element.innerText)) {
                            sumDispoTTC.push(parseFloat(element.innerText));
                        }
                    });
                }
                if (allWithClassStockTTC.length > 0) {
                    allWithClassStockTTC.map((element) => {
                        if (!isNaN(element.innerText)) {
                            sumStockTTC.push(parseFloat(element.innerText));
                        }
                    });
                }
                setSumQuantityAttrib(quantityAttrib.reduce(add, 0));
                setSumQuantityDispo(quantityDispo.reduce(add, 0));
                setSumQuantityStock(quantityStock.reduce(add, 0));
                setSumDispoHT(sumDispoHT.reduce(add, 0));
                setSumStockHT(sumStockHT.reduce(add, 0));
                setSumDispoTTC(sumDispoTTC.reduce(add, 0));
                setSumStockTTC(sumStockTTC.reduce(add, 0));
                setMissingPriceStock(error_price.length);
            }, 1200);
        }
    }, [quantityMin, setQuantityMin, inventory, choicedCat]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const table = document.getElementById("my-stock");

        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_stock");
        formData.append("table", JSON.stringify(obj));
        formData.append("Title", "Etat_Stock_" + locationSelected);
        formData.append("sum", sumStockTTC);
        formData.append("missingPrice", missingPriceStock);

        async function PostCreateSav(formData) {
            setUrlCsvStock(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvStock(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateSav(formData);
    };

    // extract order
    const orders = useSelector((state) => state.Stats.orders_date);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [newLinesItems, setNewLinesItems] = useState({});

    useEffect(() => {
        dispatch(getCollectionVelos());
        dispatch(getLocations(accessToken));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const new_line_items = {};
        if (orders !== undefined && orders !== null && orders.length > 0) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid" &&
                    order.fulfillment_status !== "fulfilled" &&
                    order.closed_at !== "null"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[item.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                        };
                    });
                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }

                    // detect if fulfilled success ! not cancelled
                    if (order.fulfillments.length > 0) {
                        order.fulfillments.map((fulfillment) => {
                            if (fulfillment.line_items.length > 0) {
                                fulfillment.line_items.map(
                                    (fulfillment_item) => {
                                        if (
                                            new_line_items.hasOwnProperty(
                                                fulfillment_item.id
                                            ) &&
                                            fulfillment.status !== "cancelled"
                                        ) {
                                            new_line_items[
                                                fulfillment_item.id
                                            ].quantity =
                                                new_line_items[
                                                    fulfillment_item.id
                                                ].quantity -
                                                fulfillment_item.quantity;
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            });
        }
        setNewLinesItems(new_line_items);
    }, [orders]);

    return (
        <main>
            <h1>Calcul Element en Stock </h1>
            <div className="text-center">
                <small>Merci de choisir dans quel lieu extraire le stock</small>
            </div>
            {locations !== undefined &&
                locations !== null &&
                locations.length > 0 && (
                    <div className="inventory">
                        {locations.map((store) => (
                            <div key={store.id} className="div_store">
                                {locationSelected === store.name ? (
                                    <div
                                        className="button-selected"
                                        onClick={(e) => {
                                            setLocationSelected(store.name);
                                            setLocationIdSelected(store.id);
                                            setSumStockHT(0);
                                            setSumDispoHT(0);
                                            setSumDispoTTC(0);
                                            setSumStockTTC(0);
                                            setMissingPriceStock(0);
                                            setSumQuantityStock(0);
                                            setSumQuantityDispo(0);
                                            setSumQuantityAttrib(0);
                                            dispatch(eraseInventory());
                                            dispatch(
                                                getInventoryItems(
                                                    store.id,
                                                    accessToken
                                                )
                                            );
                                        }}
                                    >
                                        {store.name}
                                    </div>
                                ) : (
                                    <div
                                        className="button"
                                        onClick={(e) => {
                                            setLocationSelected(store.name);
                                            setLocationIdSelected(store.id);
                                            setSumStockHT(0);
                                            setSumDispoHT(0);
                                            setSumDispoTTC(0);
                                            setSumStockTTC(0);
                                            setMissingPriceStock(0);
                                            setSumQuantityStock(0);
                                            setSumQuantityDispo(0);
                                            setSumQuantityAttrib(0);
                                            dispatch(eraseInventory());
                                            dispatch(
                                                getInventoryItems(
                                                    store.id,
                                                    accessToken
                                                )
                                            );
                                        }}
                                    >
                                        {store.name}
                                    </div>
                                )}
                                {inventory !== undefined &&
                                    inventory !== null && (
                                        <div>
                                            {Object.entries(inventory).map(
                                                ([
                                                    inventStore,
                                                    inventStoreItems,
                                                ]) => (
                                                    <>
                                                        {parseInt(
                                                            inventStore,
                                                            20
                                                        ) ===
                                                            parseInt(
                                                                store.id,
                                                                20
                                                            ) && (
                                                            <div className="text-center">
                                                                Contient{" "}
                                                                {
                                                                    inventStoreItems.length
                                                                }{" "}
                                                                éléments
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </div>
                                    )}
                            </div>
                        ))}
                    </div>
                )}
            <div>
                {locationSelected !== undefined && <h3>{locationSelected}</h3>}

                {locationSelected !== undefined &&
                inventory !== undefined &&
                inventory !== null &&
                inventoryDetails !== undefined &&
                inventoryDetails !== null &&
                linkInvenProd !== null &&
                linkInvenProd !== undefined &&
                products !== undefined &&
                products !== null ? (
                    <>
                        <div className="text-center f-column">
                            <label>Quantité Min en Stock dans le PDV</label>
                            <input
                                className="text-control"
                                type="number"
                                id="quantity"
                                name="quantity"
                                defaultValue={quantityMin}
                                onChange={(e) => setQuantityMin(e.target.value)}
                                required
                            />{" "}
                            <hr />
                            <button onClick={(e) => handleSubmit(e)}>
                                Demande d'export
                            </button>
                            {urlCsvStock !== undefined && (
                                <a
                                    href={`https://api.levelomad.com/media/stats_stock/${urlCsvStock.url_filename_stock}?auth_token=${accessToken}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button className="btn">
                                        Obtenir le CSV d'export{" "}
                                        <BsFiletypePdf /> <BsBoxArrowUpRight />
                                    </button>
                                </a>
                            )}
                        </div>
                        <div
                            className="button"
                            onClick={() => handleClickScrollToTotaux()}
                        >
                            <BiArrowFromTop /> Voir les totaux
                        </div>
                        <div>
                            Catégorie à afficher :{" "}
                            <select
                                onChange={(e) => setChoicedCat(e.target.value)}
                            >
                                {" "}
                                <option key={"Toutes"} value={"Toutes"}>
                                    ---Toutes---
                                </option>
                                {listingCat !== null &&
                                    listingCat !== undefined &&
                                    listingCat.map((item) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        {dateStart !== null && dateEnd !== null && (
                            <>
                                <DateSelected />
                                <div className="mx-auto">
                                    <table id="my-stock">
                                        <thead>
                                            <tr id="td-header">
                                                <td>Collection</td>
                                                <td>Id Produits</td>
                                                <td>Produits</td>
                                                <td>SKU</td>

                                                <td>Date MAJ Stock</td>
                                                <td>Attribué au PDV</td>
                                                <td>Disponible au PDV</td>
                                                <td>"En Stock" au PDV</td>
                                                <td>Prix Achat HT</td>
                                                <td>
                                                    Valeur Disponibles (PA HT)
                                                </td>
                                                <td>
                                                    Valeur "En Stock" (PA HT)
                                                </td>
                                                <td>Prix Vente TTC</td>
                                                <td>
                                                    Valeur Disponible PDV (PV
                                                    TTC)
                                                </td>
                                                <td>
                                                    Valeur En Stock PDV (PV TTC)
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(inventory).map(
                                                ([
                                                    inventStore,
                                                    inventStoreItems,
                                                ]) => (
                                                    <>
                                                        {parseInt(
                                                            inventStore,
                                                            20
                                                        ) ===
                                                            parseInt(
                                                                locationIdSelected,
                                                                20
                                                            ) && (
                                                            <>
                                                                {inventStoreItems.map(
                                                                    (items) => (
                                                                        <>
                                                                            {items[
                                                                                "available"
                                                                            ] >=
                                                                                quantityMin &&
                                                                                (choicedCat ===
                                                                                    "Toutes" ||
                                                                                    products[
                                                                                        linkInvenProd[
                                                                                            items
                                                                                                .inventory_item_id
                                                                                        ][
                                                                                            "product_id"
                                                                                        ].toString()
                                                                                    ][
                                                                                        "product_type"
                                                                                    ] ===
                                                                                        choicedCat) && (
                                                                                    <tr
                                                                                        key={
                                                                                            items.inventory_item_id
                                                                                        }
                                                                                    >
                                                                                        <td>
                                                                                            {
                                                                                                products[
                                                                                                    linkInvenProd[
                                                                                                        items
                                                                                                            .inventory_item_id
                                                                                                    ][
                                                                                                        "product_id"
                                                                                                    ].toString()
                                                                                                ][
                                                                                                    "product_type"
                                                                                                ]
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href={
                                                                                                    "https://admin.shopify.com/store/velomad/products/" +
                                                                                                    linkInvenProd[
                                                                                                        items
                                                                                                            .inventory_item_id
                                                                                                    ][
                                                                                                        "product_id"
                                                                                                    ]
                                                                                                }
                                                                                                rel="noreferrer"
                                                                                                target="_blank"
                                                                                            >
                                                                                                {
                                                                                                    linkInvenProd[
                                                                                                        items
                                                                                                            .inventory_item_id
                                                                                                    ][
                                                                                                        "product_id"
                                                                                                    ]
                                                                                                }
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                products[
                                                                                                    linkInvenProd[
                                                                                                        items
                                                                                                            .inventory_item_id
                                                                                                    ][
                                                                                                        "product_id"
                                                                                                    ].toString()
                                                                                                ][
                                                                                                    "title"
                                                                                                ]
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                linkInvenProd[
                                                                                                    items
                                                                                                        .inventory_item_id
                                                                                                ][
                                                                                                    "sku"
                                                                                                ]
                                                                                            }
                                                                                        </td>

                                                                                        <td>
                                                                                            {items[
                                                                                                "updated_at"
                                                                                            ] !==
                                                                                                undefined &&
                                                                                                items[
                                                                                                    "updated_at"
                                                                                                ]
                                                                                                    .split(
                                                                                                        "+"
                                                                                                    )[0]
                                                                                                    .split(
                                                                                                        "T"
                                                                                                    )[0]}
                                                                                        </td>
                                                                                        <td className="AttributeItem">
                                                                                            <WidgetCalcAttribute
                                                                                                newLinesItems={
                                                                                                    newLinesItems
                                                                                                }
                                                                                                items={
                                                                                                    items
                                                                                                }
                                                                                                linkInvenProd={
                                                                                                    linkInvenProd
                                                                                                }
                                                                                                locationIdSelected={
                                                                                                    locationIdSelected
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                        <td
                                                                                            className={
                                                                                                items[
                                                                                                    "available"
                                                                                                ] ===
                                                                                                0
                                                                                                    ? "DispoItem text-transparent"
                                                                                                    : "DispoItem"
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                items[
                                                                                                    "available"
                                                                                                ]
                                                                                            }
                                                                                        </td>
                                                                                        <td className="StockItem">
                                                                                            <WidgetCalcStock
                                                                                                newLinesItems={
                                                                                                    newLinesItems
                                                                                                }
                                                                                                items={
                                                                                                    items
                                                                                                }
                                                                                                linkInvenProd={
                                                                                                    linkInvenProd
                                                                                                }
                                                                                                available={
                                                                                                    items[
                                                                                                        "available"
                                                                                                    ]
                                                                                                }
                                                                                                locationIdSelected={
                                                                                                    locationIdSelected
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            {parseFloat(
                                                                                                inventoryDetails[
                                                                                                    inventStore.toString()
                                                                                                ][
                                                                                                    parseInt(
                                                                                                        items.inventory_item_id
                                                                                                    )
                                                                                                ]
                                                                                                    .cost
                                                                                            ) >=
                                                                                            0
                                                                                                ? inventoryDetails[
                                                                                                      inventStore.toString()
                                                                                                  ][
                                                                                                      parseInt(
                                                                                                          items.inventory_item_id
                                                                                                      )
                                                                                                  ]
                                                                                                      .cost
                                                                                                : "???"}
                                                                                        </td>
                                                                                        <td className="totalDispoHT">
                                                                                            {parseFloat(
                                                                                                inventoryDetails[
                                                                                                    inventStore.toString()
                                                                                                ][
                                                                                                    parseInt(
                                                                                                        items.inventory_item_id
                                                                                                    )
                                                                                                ]
                                                                                                    .cost
                                                                                            ) >=
                                                                                            0
                                                                                                ? Number(
                                                                                                      parseFloat(
                                                                                                          items[
                                                                                                              "available"
                                                                                                          ] *
                                                                                                              parseFloat(
                                                                                                                  inventoryDetails[
                                                                                                                      inventStore.toString()
                                                                                                                  ][
                                                                                                                      parseInt(
                                                                                                                          items.inventory_item_id
                                                                                                                      )
                                                                                                                  ]
                                                                                                                      .cost
                                                                                                              )
                                                                                                      ).toFixed(
                                                                                                          2
                                                                                                      )
                                                                                                  )
                                                                                                : "???"}
                                                                                        </td>
                                                                                        <td className="totalStockHT">
                                                                                            <WidgetSumHTStock
                                                                                                newLinesItems={
                                                                                                    newLinesItems
                                                                                                }
                                                                                                items={
                                                                                                    items
                                                                                                }
                                                                                                linkInvenProd={
                                                                                                    linkInvenProd
                                                                                                }
                                                                                                available={
                                                                                                    items[
                                                                                                        "available"
                                                                                                    ]
                                                                                                }
                                                                                                inventStore={
                                                                                                    inventStore
                                                                                                }
                                                                                                inventoryDetails={
                                                                                                    inventoryDetails
                                                                                                }
                                                                                                locationIdSelected={
                                                                                                    locationIdSelected
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                linkInvenProd[
                                                                                                    items
                                                                                                        .inventory_item_id
                                                                                                ][
                                                                                                    "price"
                                                                                                ]
                                                                                            }
                                                                                        </td>
                                                                                        <td className="totalDispoItemTTC">
                                                                                            {Number(
                                                                                                parseFloat(
                                                                                                    items[
                                                                                                        "available"
                                                                                                    ] *
                                                                                                        parseFloat(
                                                                                                            linkInvenProd[
                                                                                                                items
                                                                                                                    .inventory_item_id
                                                                                                            ][
                                                                                                                "price"
                                                                                                            ]
                                                                                                        )
                                                                                                ).toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="totalStockItemTTC">
                                                                                            <WidgetSumTTCStock
                                                                                                newLinesItems={
                                                                                                    newLinesItems
                                                                                                }
                                                                                                items={
                                                                                                    items
                                                                                                }
                                                                                                linkInvenProd={
                                                                                                    linkInvenProd
                                                                                                }
                                                                                                available={
                                                                                                    items[
                                                                                                        "available"
                                                                                                    ]
                                                                                                }
                                                                                                inventStore={
                                                                                                    inventStore
                                                                                                }
                                                                                                inventoryDetails={
                                                                                                    inventoryDetails
                                                                                                }
                                                                                                locationIdSelected={
                                                                                                    locationIdSelected
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                        </>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            )}
                                            <tr id="td-footer">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{sumQuantityAttrib}</td>
                                                <td>{sumQuantityDispo}</td>
                                                <td>{sumQuantityStock}</td>
                                                <td>
                                                    {missingPriceStock > 0 && (
                                                        <span>
                                                            {" "}
                                                            {
                                                                missingPriceStock
                                                            }{" "}
                                                            Prix Achat HT
                                                            manquants
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    {missingPriceStock > 0 && (
                                                        <span>
                                                            Approx. <br />
                                                        </span>
                                                    )}
                                                    {sumDispoHT.toFixed(2)} HT
                                                </td>
                                                <td>
                                                    {missingPriceStock > 0 && (
                                                        <span>
                                                            Approx. <br />
                                                        </span>
                                                    )}
                                                    {sumStockHT.toFixed(2)} HT
                                                </td>
                                                <td></td>
                                                <td>
                                                    {sumDispoTTC.toFixed(2)} TTC
                                                </td>
                                                <td>
                                                    {sumStockTTC.toFixed(2)} TTC
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        <div
                            className="button mb-4"
                            onClick={() => handleClickScrollToStart()}
                        >
                            <BiArrowToTop /> Retour en Haut
                        </div>
                    </>
                ) : (
                    <>{locationSelected !== undefined && <LoadingCycle />}</>
                )}
            </div>
        </main>
    );
}
