import axios from "axios";

export const addPiece = (piece) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/shopify/v1/product_by_id/${piece.id}/`
            )
            .then((res) => {
                return dispatch({
                    type: "ADD_PIECE_SAV",
                    piece: res.data,
                });
            })
            .catch((error) => {
                return dispatch({
                    type: "ADD_PIECE_SAV",
                    piece: piece,
                });
            });
    };
};
export const addPieceWithVariant = (piece, variant) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/shopify/v1/product_by_id/${piece.id}/`
            )
            .then((res) => {
                return dispatch({
                    type: "ADD_PIECE_SAV",
                    piece: { ...res.data, variantSelected: variant.id },
                });
            })
            .catch((error) => {
                return dispatch({
                    type: "ADD_PIECE_SAV",
                    piece: piece,
                });
            });
    };
};
export const addPieceSAVB2C = (piece) => {
    return (dispatch) => {
        return dispatch({
            type: "ADD_PIECE_SAV",
            piece: piece,
        });
    };
};
export const removePiece = (piece) => {
    return (dispatch) => {
        return dispatch({
            type: "REMOVE_PIECE_SAV",
            piece: piece,
        });
    };
};

export const erasePanier = () => {
    return (dispatch) => {
        return dispatch({
            type: "ERASE_PANIER",
        });
    };
};
