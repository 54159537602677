const initialState = {
    catSAV: null,
    foundPiecesSav: null,
    collection_velos: null,
    pieceSelectId: null,
    parcoursSavTitle: "Choisissez votre gamme",
    parcoursSavStep: 1,
    modele: null,
    product_select_velo: null,
    pieces_sav: null,
    sku: null,
    productSavB2C: null,
    prices_reprises: null,
};

export default function Velos(state = initialState, action) {
    switch (action.type) {
        case "GET_COLLECTION_VELOS":
            return { ...state, collection_velos: action.collection_velos };
        case "SELECT_VELO":
            return { ...state, velo: action.velo, foundPiecesSav: null };
        case "GET_PRODUCT_SELECT_VELO":
            return {
                ...state,
                product_select_velo: action.product_select_velo,
            };
        case "GET_PRICES_REPRISE_VELOS":
            return {
                ...state,
                prices_reprises: action.prices_reprises,
            };
        case "GET_PIECES_SAV":
            return {
                ...state,
                pieces_sav: action.pieces_sav,
            };
        case "SELECT_MODELE":
            return {
                ...state,
                modele: action.modele,
                foundPiecesSav: null,
            };
        case "SELECT_SKU":
            return {
                ...state,
                sku: action.sku,
                foundPiecesSav: null,
            };
        case "SET_FOUND_PIECES_SAV":
            return {
                ...state,
                foundPiecesSav: action.foundPiecesSav,
            };
        case "GET_PRODUCT_TO_CREATE_SAV_B2C":
            return {
                ...state,
                productSavB2C: action.productSavB2C,
            };
        case "SET_PIECE_SAV_SELECT_ID":
            return {
                ...state,
                pieceSelectId: action.pieceSelectId,
            };
        case "GET_PIECE_SAV_SELECT_BY_ID":
            return {
                ...state,
                pieceSelect: action.pieceSelect,
            };
        case "GET_CAT_SAV":
            return {
                ...state,
                catSAV: action.catSAV,
            };
        case "SET_PARCOURS_SAV_TITLE":
            return {
                ...state,
                parcoursSavTitle: action.parcoursSavTitle,
            };
        case "SET_PARCOURS_SAV_STEP":
            return {
                ...state,
                parcoursSavStep: action.parcoursSavStep,
            };
        default:
            return state;
    }
}
