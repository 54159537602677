import LoadingK from "../LoadingK";
import { SearchOrdersCustomerPresta } from "../../Utils/FetchPresta";
import { AiOutlineBarcode } from "react-icons/ai";
export default function OrderDetailPresta(props) {
    const { resultOrderCustomerPresta } = SearchOrdersCustomerPresta(
        props.idOrder
    );

    return (
        <div>
            {resultOrderCustomerPresta !== undefined ? (
                <>
                    {resultOrderCustomerPresta.length > 0 &&
                        resultOrderCustomerPresta.map((art) => (
                            <>
                                <div>
                                    <span>
                                        <AiOutlineBarcode />
                                        {art.product_reference}{" "}
                                    </span>
                                    <span>{art.product_name}</span>
                                    <br />
                                    <span> - {art.product_weight}kg </span>{" "}
                                    <br />{" "}
                                    <span> - EAN : {art.product_ean13}</span>
                                    <br />
                                    <span>
                                        Quantité : x{art.product_quantity}
                                    </span>{" "}
                                    <span>à {art.unit_price_tax_incl} EUR</span>{" "}
                                    <span>
                                        - Total {art.total_price_tax_incl} EUR
                                        TTC
                                    </span>
                                </div>
                                <hr />
                            </>
                        ))}
                </>
            ) : (
                <LoadingK />
            )}
        </div>
    );
}
