/* eslint-disable array-callback-return */
import axios from "axios";
import { toast } from "react-toastify";

export const getLocations = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get(`https://api.levelomad.com/api/stats/v1/get_locations/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res) => {
                return dispatch({
                    type: "GET_LOCATIONS",
                    locations: res.data.locations,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    if (error.response.status === 401) {
                        toast.warn(
                            "Echec Authentification / Session Périmée, merci de vous déconnecter et de vous relogguer",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API LocationsStore est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_LOCATIONS",
                        locations: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API LocationsStore",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const getInventoryItems = (idStore, accessToken) => {
    return async (dispatch) => {
        return await axios
            .get(
                "https://api.levelomad.com/api/stats/v1/get_inventy_by_location/" +
                    idStore +
                    "/",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 40000,
                }
            )
            .then((res) => {
                return dispatch({
                    type: "GET_INVENTORY",
                    inventory: res.data.inventory,
                    inventory_details: res.data.inventory_details,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API INVENTORY_ITEMS est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 503) {
                        toast.error(
                            "Shopify a retourné un code service indisponible, retenter votre requête.",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_INVENTORY",
                        inventory: null,
                        inventory_details: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API INVENTORY_ITEMS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};
export const eraseInventory = () => {
    return (dispatch) => {
        return dispatch({
            type: "ERASE_INVENTORY",
            inventory: undefined,
            inventory_details: undefined,
        });
    };
};

export const getProducts = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/stats/v1/get_all_products/", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 60000,
            })
            .then((res) => {
                return dispatch({
                    type: "GET_PRODUCTS",
                    products: res.data.products,
                    link_inventory_product: res.data.link_inventory_product,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API GET_PRODUCTS est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_PRODUCTS",
                        products: null,
                        link_inventory_product: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API GET_PRODUCTS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const getOrdersByDate = (dateStart, dateEnd, accessToken) => {
    return async (dispatch) => {
        const mocked = false;
        if (mocked !== true) {
            return await axios
                .get(
                    "https://api.levelomad.com/api/stats/v1/get_orders_by_date/" +
                        dateStart +
                        "/" +
                        dateEnd +
                        "T23:59:59/",
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        timeout: 60000,
                    }
                )
                .then((res) => {
                    return dispatch({
                        type: "GET_ORDERS_BY_DATE",
                        orders_date: res.data.orders,
                        dateStart: dateStart,
                        dateEnd: dateEnd,
                        intervalReturn: res.data.intervalle,
                        resultDateCount: res.data.count,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warn(error.response.data.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        } else if (error.response.status === 500) {
                            toast.error(
                                "Le serveur distant API GET_ORDERS_BY_DATE est en erreur",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                        }
                        return dispatch({
                            type: "GET_ORDERS_BY_DATE",
                            orders_date: null,
                            dateStart: dateStart,
                            dateEnd: dateEnd,
                        });
                    } else {
                        toast.error(
                            "Impossible de se connecter au serveur API GET_ORDERS_BY_DATE",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                });
        } else {
            return await axios.get("../order_date.json").then((res) => {
                return dispatch({
                    type: "GET_ORDERS_BY_DATE",
                    orders_date: res.data.orders,
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    intervalReturn: null,
                    resultDateCount: null,
                });
            });
        }
    };
};

export const getOrdersByDateTdb = (dateStart, dateEnd, accessToken) => {
    return async (dispatch) => {
        const mocked = false;
        if (mocked !== true) {
            return await axios
                .get(
                    "https://api.levelomad.com/api/stats/v1/get_tdb/" +
                        dateStart +
                        "/" +
                        dateEnd +
                        "T23:59:59/",
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        timeout: 60000,
                    }
                )
                .then((res) => {
                    return dispatch({
                        type: "GET_ORDERS_BY_DATE_TDB",
                        statsTdb: res.data,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warn(error.response.data.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        } else if (error.response.status === 500) {
                            toast.error(
                                "Le serveur distant API GET_ORDERS_BY_DATE_TDB est en erreur",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                        }
                        return dispatch({
                            type: "GET_ORDERS_BY_DATE_TDB",
                            statsTdb: null,
                        });
                    } else {
                        toast.error(
                            "Impossible de se connecter au serveur API GET_ORDERS_BY_DATE_TDB",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                });
        } else {
            return await axios.get("../order_date.json").then((res) => {
                return dispatch({
                    type: "GET_ORDERS_BY_DATE_TDB",
                    statsTdb: res.data,
                });
            });
        }
    };
};

export const eraseOrdersDate = () => {
    return (dispatch) => {
        return dispatch({
            type: "ERASE_ORDERS_BY_DATE",
            orders_date: undefined,
        });
    };
};

export const getProductsByIds = (products, accessToken) => {
    return async (dispatch) => {
        return await axios
            .post(
                "https://api.levelomad.com/api/stats/v1/get_producs_by_ids/",
                { products: products },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 60000,
                }
            )
            .then((res) => {
                return dispatch({
                    type: "GET_PRODUCTS_BY_IDS",
                    products_by_ids: res.data.products,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_PRODUCTS_BY_IDS",
                        products_by_ids: null,
                    });
                } else {
                }
            });
    };
};

export const getCustomers = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/stats/v1/get_init_customers/", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 60000,
            })
            .then((res) => {
                return dispatch({
                    type: "GET_CUSTOMERS",
                    customers: res.data.customers,
                    next_page_info_customer: res.data.page_info,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 401) {
                        toast.warn(
                            "votre session est périmée, merci de vous reconnecter",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API GET_CUSTOMERS est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_CUSTOMERS",
                        customers: null,
                        next_page_info_customer: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API GET_CUSTOMERS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const getNextCustomers = (next_page_info_customer, accessToken) => {
    return async (dispatch) => {
        return await axios
            .post(
                "https://api.levelomad.com/api/stats/v1/get_next_customers/",
                { page_info: next_page_info_customer },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 60000,
                }
            )
            .then((res) => {
                return dispatch({
                    type: "GET_NEXT_CUSTOMERS",
                    customers: res.data.customers,
                    next_page_info_customer: res.data.page_info,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 401) {
                        toast.warn(
                            "votre session est périmée, merci de vous reconnecter",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API GET_NEXT_CUSTOMERS est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_NEXT_CUSTOMERS",
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API GET_NEXT_CUSTOMERS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const getCollectionVelos = () => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/shopify/v1/products/velos/")
            .then((res) => {
                return dispatch({
                    type: "GET_COLLECTION_VELOS",
                    collection_velos: res.data.products,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_COLLECTION_VELOS",
                        collection_velos: null,
                    });
                } else {
                    toast.error("Impossible de se connecter au serveur", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    };
};

export const dicoProductsVelo = (dataCollectionVelos, orders) => {
    return (dispatch) => {
        const new_line_items = {};
        const new_products = {};
        let sumQuantity = 0;
        if (orders !== undefined && orders.length > 0) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[item.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                        };
                    });
                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }
                    // detect if fulfilled success ! not cancelled
                    if (order.fulfillments.length > 0) {
                        order.fulfillments.map((fulfillment) => {
                            if (fulfillment.line_items.length > 0) {
                                fulfillment.line_items.map(
                                    (fulfillment_item) => {
                                        if (
                                            new_line_items.hasOwnProperty(
                                                fulfillment_item.id
                                            ) &&
                                            fulfillment.status !== "cancelled"
                                        ) {
                                            new_line_items[
                                                fulfillment_item.id
                                            ].fulfillment =
                                                new_line_items[
                                                    fulfillment_item.id
                                                ].order.id +
                                                new_line_items[
                                                    fulfillment_item.id
                                                ].order.name;
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            });
        }
        // creation new_products
        const veloWithQuantity = [];
        Object.keys(new_line_items).map((key) => {
            if (
                new_line_items[key].quantity !== 0 &&
                new_line_items[key].quantity !== "0"
            ) {
                let location = "";
                if (
                    new_line_items[key].order.location_id === null &&
                    new_line_items[key].order.source_name === "web"
                ) {
                    location = 78563311897;
                } else {
                    location = new_line_items[key].order.location_id;
                }
                // si ip prdiot déjà  dans le dictionnaire
                let sku = new_line_items[key].sku;
                let name = new_line_items[key].name;
                let size = "";
                let color = "";
                let variantId = "";
                let product = new_line_items[key].product;

                if (
                    new_line_items[key].price > 800 &&
                    new_line_items[key].quantity !== "0" &&
                    !new_line_items[key].name.includes("ivraison") &&
                    !new_line_items[key].name.includes("frais") &&
                    !new_line_items[key].name.includes("Frais") &&
                    !new_line_items[key].name.includes("xpéd") &&
                    !new_line_items[key].name.includes("xped")
                ) {
                    veloWithQuantity.push(new_line_items[key]);
                    if (
                        new_line_items[key].sku !== null &&
                        new_line_items[key].sku.length >= 10
                    ) {
                        sku = new_line_items[key].sku.split(" /")[0];
                        name = new_line_items[key].name;

                        if (dataCollectionVelos !== undefined) {
                            dataCollectionVelos.map((velo) => {
                                if (velo.id === product) {
                                    if (
                                        velo.variants !== null &&
                                        velo.variants.length > 0
                                    ) {
                                        velo.variants.map((variant) => {
                                            if (
                                                variant.sku
                                                    .split(" /")[0]
                                                    .includes(sku)
                                            ) {
                                                if (
                                                    variant.option2.includes(
                                                        "Taille"
                                                    ) === true
                                                ) {
                                                    size = variant.option2;
                                                    color = variant.option3;
                                                } else {
                                                    size = variant.option1;
                                                    color = variant.option2;
                                                }

                                                variantId = variant.id;
                                            } else {
                                                if (sku === "YCVMWB4501") {
                                                    size = "Taille 45";
                                                    color = "Noir mat";
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    } else {
                        sku = new_line_items[key].name.split("SKU: ")[1];

                        if (sku === undefined) {
                            sku = new_line_items[key].name;
                            dataCollectionVelos !== undefined &&
                                dataCollectionVelos !== null &&
                                dataCollectionVelos.map((velo) => {
                                    if (
                                        velo.variants !== null &&
                                        velo.variants.length > 0
                                    ) {
                                        velo.variants.map((variant) => {
                                            // if Gamme V2 mag - Urbain 2 - Cadre semi ouvert / Taille 45 / Noir mat

                                            if (sku.includes("mag") === true) {
                                                const option1 =
                                                    sku.split(" - ")[1];
                                                if (
                                                    variant.option1.includes(
                                                        option1.split(" / ")[0]
                                                    ) === true &&
                                                    variant.option2.includes(
                                                        sku.split(" / ")[1]
                                                    ) === true &&
                                                    variant.option3.includes(
                                                        sku.split(" / ")[2]
                                                    ) === true
                                                ) {
                                                    sku =
                                                        variant.sku.split(
                                                            " /"
                                                        )[0];
                                                    name =
                                                        velo.title +
                                                        " - " +
                                                        variant.title;
                                                    product = velo;
                                                    size = variant.option2;
                                                    color = variant.option3;
                                                    variantId = variant.id;
                                                }
                                            }
                                            // if Urbain 2 - Cadre semi ouvert / Taille 45 / Noir mat SKU : YCVMWB4505 / EAN :3032651890126 / Vélo à assistance
                                            else if (
                                                sku.includes("SKU") === true
                                            ) {
                                                if (
                                                    variant.title.includes(
                                                        sku.split(" SKU")[0]
                                                    )
                                                ) {
                                                    sku =
                                                        variant.sku.split(
                                                            " /"
                                                        )[0];
                                                    name =
                                                        velo.title +
                                                        " - " +
                                                        variant.title;
                                                    product = velo;
                                                    size = variant.option2;
                                                    color = variant.option3;
                                                    variantId = variant.id;
                                                } else {
                                                    if (
                                                        variant.title.includes(
                                                            sku
                                                                .replace(
                                                                    "Gamme V2 ",
                                                                    ""
                                                                )
                                                                .split(
                                                                    " SKU"
                                                                )[0]
                                                        )
                                                    ) {
                                                        sku =
                                                            variant.sku.split(
                                                                " /"
                                                            )[0];
                                                        name =
                                                            velo.title +
                                                            " - " +
                                                            variant.title;
                                                        product = velo;
                                                        size = variant.option2;
                                                        color = variant.option3;
                                                        variantId = variant.id;
                                                    }
                                                }
                                            }
                                        });
                                    }
                                });
                        } else {
                            // sku = YCVMWB4502 / EAN: 3032651890140 / Vélo à assistance électrique

                            sku = new_line_items[key].name.split("SKU: ")[1];
                            sku = sku.split(" /")[0];
                            sku = sku.split(" - ")[0];
                            dataCollectionVelos !== undefined &&
                                dataCollectionVelos !== null &&
                                dataCollectionVelos.map((velo) => {
                                    if (
                                        velo.variants !== null &&
                                        velo.variants.length > 0
                                    ) {
                                        velo.variants.map((variant) => {
                                            if (
                                                variant.sku
                                                    .split(" /")[0]
                                                    .includes(sku)
                                            ) {
                                                name =
                                                    velo.title +
                                                    " - " +
                                                    variant.title;
                                                product = velo;
                                                size = variant.option2;
                                                color = variant.option3;
                                                variantId = variant.id;
                                            }
                                        });
                                    }
                                });
                        }
                    }

                    if (new_products.hasOwnProperty(sku) === true) {
                        new_products[sku].quantity =
                            new_products[sku].quantity +
                            new_line_items[key].quantity;
                        new_products[sku].order.push(new_line_items[key].order);
                        new_products[sku].orderName.push(
                            new_line_items[key].order.id +
                                new_line_items[key].order.name +
                                "#quantity:" +
                                new_line_items[key].quantity +
                                "#location:" +
                                location +
                                "#tags:" +
                                new_line_items[key].order.tags +
                                "#dateOrder:" +
                                new_line_items[key].order.created_at +
                                "#restDu:" +
                                new_line_items[key].order.total_outstanding +
                                "#financialStatus:" +
                                new_line_items[key].order.financial_status
                        );

                        new_products[sku].fulfillment.push(
                            new_line_items[key].fulfillment
                        );
                    } else {
                        new_products[sku] = {
                            quantity: new_line_items[key].quantity,
                            fulfillment: [new_line_items[key].fulfillment],
                            price: new_line_items[key].price,
                            name: name,
                            sku: sku,
                            product: product,
                            color: color,
                            size: size,
                            variantId: variantId,
                            order: [new_line_items[key].order],
                            orderName: [
                                new_line_items[key].order.id +
                                    new_line_items[key].order.name +
                                    "#quantity:" +
                                    new_line_items[key].quantity +
                                    "#location:" +
                                    location +
                                    "#tags:" +
                                    new_line_items[key].order.tags +
                                    "#dateOrder:" +
                                    new_line_items[key].order.created_at +
                                    "#restDu:" +
                                    new_line_items[key].order
                                        .total_outstanding +
                                    "#financialStatus:" +
                                    new_line_items[key].order.financial_status,
                            ],
                        };
                    }
                }
            }
        });
        let dicoSortedLocation = undefined;
        let dicoSorted = {};
        if (new_products !== undefined) {
            const sorted = Object.keys(new_products)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = new_products[key];
                    return accumulator;
                }, {});

            dicoSorted = sorted;
        }
        return dispatch({
            type: "DICO_PRODUCT_VELO",
            dicoProductsVelo: dicoSorted,
            sumQuantity: sumQuantity,
            dicoSortedLocation: dicoSortedLocation,
            new_line_items: new_line_items,
        });
    };
};

export const getProductsReparations = (accessToken) => {
    return async (dispatch) => {
        return await axios
            .get(
                "https://api.levelomad.com/api/stats/v1/get_product_reparation/",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 60000,
                }
            )
            .then((res) => {
                return dispatch({
                    type: "GET_PRODUCTS_REPARATIONS",
                    productsRepair: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API GET_PRODUCTS_REPARATIONS est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_PRODUCTS_REPARATIONS",
                        productsRepair: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter au serveur API GET_PRODUCTS_REPARATIONS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const dicoProductsRepair = (dataProductRepair, orders, locations) => {
    return (dispatch) => {
        const new_line_items = {};

        if (orders !== undefined && orders.length > 0) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[item.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                            location: order.location_id,
                        };
                    });
                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }
                }
            });
        }

        const dicoLocations = {};
        locations.map((location) => {
            dicoLocations[location.id] = [];
        });

        Object.keys(new_line_items).map((item) => {
            if (
                // other que POS
                dicoLocations[new_line_items[item].location] !== undefined
            ) {
                if (
                    dataProductRepair !== undefined &&
                    dataProductRepair.hasOwnProperty(
                        new_line_items[item].product
                    )
                )
                    dicoLocations[new_line_items[item].location] = [
                        ...dicoLocations[new_line_items[item].location],
                        new_line_items[item],
                    ];
            }
        });
        return dispatch({
            type: "DICO_PRODUCT_REPAIR",
            productsRepairByLocations: dicoLocations,
        });
    };
};
