import "./veloselect.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VeloProduct from "../../Components/Sav/VeloProducts";
import {
    getCollectionVelos,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import LoadingCycle from "./LoaderCycle";

export default function VeloToChoice() {
    const dispatch = useDispatch();

    const dataCollectionVelos = useSelector(
        (state) => state.veloDetails.collection_velos
    );

    useEffect(() => {
        dispatch(getCollectionVelos());
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );
    return (
        <>
            {dataCollectionVelos !== undefined &&
            dataCollectionVelos !== null ? (
                <div className="collect-velo-list">
                    {dataCollectionVelos !== undefined &&
                        dataCollectionVelos !== null &&
                        dataCollectionVelos.length > 0 &&
                        dataCollectionVelos
                            .filter(
                                (velo) =>
                                    velo.title !== undefined &&
                                    velo.title !== null &&
                                    velo.title.includes("av")
                            )
                            .map((velo) => (
                                <VeloProduct product={velo} key={velo.id} />
                            ))}
                </div>
            ) : (
                <>
                    <LoadingCycle />
                    <div className="text-center">
                        Chargement de la liste des Vélos en cours ...
                    </div>
                </>
            )}
            {dataVeloSelect !== null && dataVeloSelect !== undefined && (
                <div
                    className="btn-link-web margin-btn margin-btn-center"
                    onClick={() => {
                        dataVeloSelect !== null &&
                            dispatch(
                                setParcoursSavTitle("Choisissez votre version")
                            );
                        dispatch(setParcoursSavStep(2));
                    }}
                >
                    <button>Suivant</button>
                </div>
            )}
        </>
    );
}
