/* eslint-disable array-callback-return */
import { PieChart, Pie, Sector } from "recharts";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../Pages/sav.css";
import "../../Pages/stats.css";
import "../Stats/stats.css";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 40}
                y={ey - 14}
                textAnchor={textAnchor}
                fill="#808e79"
            >{` ${value}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`Commandes`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

const renderActiveShapeSum = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />

            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {` ${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

export default function GraphRepartOrderLocation(props) {
    const [dataNumberOrders, setDataNumberOrders] = useState(undefined);
    const [dataSumOrders, setDataSumOrders] = useState(undefined);
    const locations = useSelector((state) => state.Stats.locations);
    const [urlCsv, setUrlCsv] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    useEffect(() => {
        const validDataNumberOrders = [];
        const validDataSumOrders = [];
        const dictDataNumberOrders = {};
        const dictSumOrders = {};
        if (
            props.orders !== undefined &&
            props.orders.length >= 1 &&
            locations !== undefined &&
            locations.length >= 1
        ) {
            props.orders.map((order) => {
                let my_location = null;
                if (order.location_id === null) {
                    my_location = order.source_name;
                } else {
                    locations.map((location) => {
                        if (
                            parseFloat(location.id) ===
                            parseFloat(order.location_id)
                        ) {
                            my_location = location.name;
                        }
                    });
                }

                if (dictDataNumberOrders.hasOwnProperty(my_location) === true) {
                    dictDataNumberOrders[my_location] =
                        dictDataNumberOrders[my_location] + 1;
                    dictSumOrders[my_location] =
                        dictSumOrders[my_location] +
                        Number(order.current_total_price);
                } else {
                    dictDataNumberOrders[my_location] = 1;
                    dictSumOrders[my_location] = Number(
                        order.current_total_price
                    );
                }
            });
            Object.keys(dictDataNumberOrders).forEach((key) => {
                validDataNumberOrders.push({
                    name: key,
                    value: dictDataNumberOrders[key],
                });
            });
            Object.keys(dictSumOrders).forEach((key) => {
                validDataSumOrders.push({
                    name: key,
                    value: dictSumOrders[key],
                });
            });
            setDataNumberOrders(validDataNumberOrders);
            setDataSumOrders(validDataSumOrders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orders]);

    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );
    const handleSubmit = (e) => {
        e.preventDefault();
        let table = undefined;

        table = document.getElementById("location-cmde-ca");

        async function PostCreateSav(formData) {
            setUrlCsv(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsv(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        if (table !== undefined && table !== null) {
            const thead = Array.from(table.tHead.rows[0].children).map(
                (el) => el.textContent
            );

            const tbody = Array.from(table.tBodies[0].rows).map((row) =>
                Array.from(row.cells).map((cell) => cell.textContent)
            );

            const obj = {
                table: [thead].concat(tbody),
            };

            const formData = new FormData();

            formData.append("type", "export_orders");
            formData.append("table", JSON.stringify(obj));

            formData.append(
                "Title",
                "Repartition_commande_et_CATTC_DU_" +
                    dateStart +
                    "_AU_" +
                    dateEnd
            );

            PostCreateSav(formData);
        }
    };
    return (
        <div className="mb-5">
            {props !== undefined && (
                <div className="GraphCommandes">
                    <div className="f-column">
                        <div>N° Commandes / PDV</div>
                        <small>
                            Total cumulé sur la période : {props.orders.length}{" "}
                            commandes
                        </small>
                        <PieChart width={500} height={400} className="pie">
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={dataNumberOrders}
                                cx={250}
                                cy={200}
                                innerRadius={80}
                                outerRadius={90}
                                fill="#1d3c34"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            />
                        </PieChart>
                    </div>
                    <div className="f-column">
                        <div>CA TTC / PDV</div>

                        <PieChart width={500} height={400} className="pie">
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShapeSum}
                                data={dataSumOrders}
                                cx={250}
                                cy={200}
                                innerRadius={80}
                                outerRadius={90}
                                fill="#1d3c34"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            />
                        </PieChart>
                    </div>
                </div>
            )}
            {props.table === true && (
                <>
                    <table className="table" id="location-cmde-ca">
                        <thead>
                            <tr id="td-header">
                                <th className="bg-table">PDV</th>
                                <th className="bg-table">N° Commandes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataNumberOrders !== undefined &&
                                dataNumberOrders.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.value}</td>
                                        </tr>
                                    );
                                })}
                            <tr>
                                <td className="bg-table">total</td>
                                <td className="bg-table">
                                    {" "}
                                    {props.orders.length}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-flex flex-row ">
                        <div className="mx-auto">
                            {" "}
                            <button
                                onClick={(e) => handleSubmit(e)}
                                className=""
                            >
                                Demande d'export Location/Commande/CATTC
                            </button>{" "}
                        </div>
                        <div className="mx-auto mb-5">
                            {urlCsv !== undefined && (
                                <a
                                    href={`https://api.levelomad.com/media/stats_orders/${urlCsv.url_filename_stock}?auth_token=${accessToken}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button>
                                        Obtenir le CSV d'export{" "}
                                        <BsFiletypePdf /> <BsBoxArrowUpRight />
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
