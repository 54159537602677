import { useEffect, useState } from "react";
import "./gestion.css";
import AllUserList from "../Components/shopify/AllUserList";
import CustomersOrdersBike from "../Components/shopify/CustomersOrdersBike";
import { useSelector } from "react-redux";

export default function GestionUsersList() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = "VELOMAD | Ma Liste Clients Shopify";
    document.description = "Intranet du VELOMAD";
    const [sectionSelected, setSectionSelected] = useState(undefined);

    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Ma Liste Client Shopify</h1>
                    <div className="text-center">
                        <small>Merci de choisir la section à extraire</small>
                    </div>
                    <div className="d-flex-row">
                        {sectionSelected === "AllCustomers" ? (
                            <div
                                className="button-selected"
                                onClick={(e) =>
                                    setSectionSelected("AllCustomers")
                                }
                            >
                                Tous Mes Clients Shopify
                            </div>
                        ) : (
                            <div
                                className="button"
                                onClick={(e) =>
                                    setSectionSelected("AllCustomers")
                                }
                            >
                                Tous Mes Clients Shopify
                            </div>
                        )}
                        {sectionSelected === "CustomersOrdersBike" ? (
                            <div
                                className="button-selected"
                                onClick={(e) =>
                                    setSectionSelected("CustomersOrdersBike")
                                }
                            >
                                Extraire Clients ayant achetés un vélo
                            </div>
                        ) : (
                            <div
                                className="button"
                                onClick={(e) =>
                                    setSectionSelected("CustomersOrdersBike")
                                }
                            >
                                Extraire Clients ayant achetés un vélo
                            </div>
                        )}
                    </div>

                    {sectionSelected === "AllCustomers" && <AllUserList />}
                    {sectionSelected === "CustomersOrdersBike" && (
                        <CustomersOrdersBike />
                    )}
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
