import { useState } from "react";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import LoadingK from "../LoadingK";

export default function GenerateInvoicePresta(props) {
    const [generateInvoice, setGenerateInvoice] = useState(false);
    const [urlInvoice, setUrlInvoice] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    const mailCustomerSearch = useSelector(
        (state) => state.AdminStaff.mailCustomerSearch
    );

    async function SendGenerateInvoicePresta(
        idCustomerPresta,
        idOrderCustomerPresta
    ) {
        try {
            setGenerateInvoice(true);
            setUrlInvoice(undefined);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/create_pdf_prestashop/${mailCustomerSearch}/${idCustomerPresta}/${idOrderCustomerPresta}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setUrlInvoice(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setUrlInvoice(undefined);
                setGenerateInvoice(false);
                toast.error("Panne de Connexion avec API Prestashop Facture", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            setGenerateInvoice(false);
            toast.error(
                "Oups! Impossible de générer la facture Prestashop 😔",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    }

    return (
        <div>
            {generateInvoice === false ? (
                <button
                    className="button-action"
                    onClick={(e) =>
                        SendGenerateInvoicePresta(
                            props.cmde.id_customer,
                            props.cmde.id_order
                        )
                    }
                >
                    Générer la facture <BsBoxArrowUpRight />
                </button>
            ) : (
                <>
                    {urlInvoice === undefined ? (
                        <LoadingK />
                    ) : (
                        <a
                            href={`https://api.levelomad.com/media/pdf_invoice/${urlInvoice.url_pdf_invoice}?auth_token=${accessToken}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className="btn">
                                Ouvrir la Facture <BsFiletypePdf />{" "}
                                <BsBoxArrowUpRight />
                            </button>
                        </a>
                    )}
                </>
            )}
        </div>
    );
}
