import { useSelector } from "react-redux";

export default function DateSelected() {
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const intervalReturn = useSelector((state) => state.Stats.intervalReturn);
    const resultDateCount = useSelector((state) => state.Stats.resultDateCount);
    return (
        <div className="dateSelected">
            {dateStart === undefined || dateEnd === undefined ? (
                <span>
                    Merci de sélectionner votre période d'extraction dans le
                    menu statistiques sur votre gauche
                </span>
            ) : (
                <>
                    <p>
                        Demande du {dateStart} 00h00 <br /> Au {dateEnd} 23h59
                    </p>
                    <hr />
                    <p>
                        {resultDateCount} Commandes en résultats dont <br />
                        <br />
                        Première passée le <br />{" "}
                        {intervalReturn !== null &&
                            intervalReturn !== undefined &&
                            intervalReturn[1]
                                .replace("T", " ")
                                .replace("+01:00", "")
                                .replace("+02:00", "")}{" "}
                        <br />
                        Dernière passée le <br />
                        {intervalReturn !== null &&
                            intervalReturn !== undefined &&
                            intervalReturn[0]
                                .replace("T", " ")
                                .replace("+01:00", "")
                                .replace("+02:00", "")}
                    </p>
                </>
            )}
        </div>
    );
}
