import { toast } from "react-toastify";
import axios from "axios";

export const searchShopiCustomerByMail = (
    mailCustomerSearch,
    accessToken,
    authUser
) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "get",
            url: `https://api.levelomad.com/api/erp/v1/search_customer_by_mail/shopify/${mailCustomerSearch}/`,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "resultShopiCustomerByMail",
                    resultShopiCustomerByMail: res.data,
                });
            })

            .catch(() => {
                toast.error("Panne de Connexion avec API Shopify", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "resultShopiCustomerByMail",
                    resultShopiCustomerByMail: null,
                });
            });
    };
};

export const searchShopiOrderCustomerId = (
    idCustomerShopi,
    accessToken,
    authUser
) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "get",
            url: `https://api.levelomad.com/api/erp/v1/search_orders_curstomer/shopify/${idCustomerShopi}/`,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "resultShopiCustomerOrders",
                    resultShopiCustomerOrders: res.data,
                });
            })

            .catch(() => {
                toast.error("Panne de Connexion avec API Shopify Order", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "resultShopiCustomerOrders",
                    resultShopiCustomerOrders: null,
                });
            });
    };
};

export const searchProductsPresta = (accessToken, authUser) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }
        const config = {
            method: "get",
            url: `https://api.levelomad.com/api/erp/v1/search_product/presta/`,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "resultProductsPrestashop",
                    resultProductsPrestashop: res.data,
                });
            })

            .catch(() => {
                toast.error("Panne de Connexion avec BDD Prestashop Products", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "resultProductsPrestashop",
                    resultProductsPrestashop: null,
                });
            });
    };
};

export const searchOrdersByProductIdPresta = (
    accessToken,
    authUser,
    inputIdProduct
) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "get",
            url: `https://api.levelomad.com/api/erp/v1/search_order_by_product_id_prestashop/presta/${inputIdProduct}/`,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "resultOrdersByProductPrestashop",
                    resultOrdersByProductPrestashop: res.data,
                });
            })

            .catch(() => {
                toast.error("Panne de Connexion avec BDD Prestashop Products", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "resultOrdersByProductPrestashop",
                    resultOrdersByProductPrestashop: null,
                });
            });
    };
};

export const ObtainStatsParrainage = (accessToken, authUser) => {
    return async (dispatch) => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            timeout: 20000,
            credentials: "same-origin",
        };
        if (
            accessToken !== null &&
            authUser !== null &&
            authUser.is_superuser === true
        ) {
            headers = {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            };
        }

        const config = {
            method: "get",
            url: `https://api.levelomad.com/api/parrainage/v1/obtains_stats/`,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "statsParrainage",
                    statsParrainage: res.data,
                });
            })

            .catch(() => {
                toast.error("Panne de Connexion avec BDD statsParrainage", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: "statsParrainage",
                    statsParrainage: null,
                });
            });
    };
};
