import { combineReducers } from "redux";
import veloDetails from "./reducers/velo.reducer";
import panierSav from "./reducers/panierSav.reducer";
import user from "./reducers/user.reducer";
import AdminStaff from "./reducers/admin.reducer";
import Stats from "./reducers/stats.reducer";
import style from "./reducers/style.reducer";
import Map from "./reducers/map.reducer";
import Bdd from "./reducers/bdd.reducer";

export default combineReducers({
    veloDetails,
    panierSav,
    user,
    AdminStaff,
    Bdd,
    Map,
    Stats,
    style,
});
