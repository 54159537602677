import { useEffect, useState } from "react";
import "./panier.css";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import { addPiece, removePiece } from "../../Redux/actions/panierSav.actions";
import {
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import {
    setBatterieDetect,
    setBatterieState,
} from "../../Redux/actions/user.actions";

export default function Panier() {
    const dataPanier = useSelector((state) => state.panierSav);
    const [totalAmount, setTotalAmount] = useState(0.0);
    const garantieStatus = useSelector((state) => state.user.garantie);
    const batterieDetect = useSelector((state) => state.user.batterieDetect);
    const batterieState = useSelector((state) => state.user.batterieState);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setTotalAmount(0);
        if (dataPanier !== undefined && dataPanier !== null) {
            // eslint-disable-next-line array-callback-return
            dataPanier.map((piece) => {
                piece.id !== undefined &&
                    setTotalAmount(
                        (
                            parseFloat(totalAmount) +
                            parseFloat(piece.variants[0].price * 0.85)
                        ).toFixed(2)
                    );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPanier]);

    useEffect(() => {
        if (dataPanier !== undefined && dataPanier !== null) {
            dispatch(setBatterieState(false));
            dispatch(setBatterieDetect(false));
            dataPanier.map((piece) => {
                if (
                    piece.title !== undefined &&
                    piece.title.includes("atterie") &&
                    piece.title.includes("hargeur") === false
                ) {
                    dispatch(setBatterieDetect(true));
                } else if (
                    piece.title === undefined &&
                    piece.includes("Confirmation - Etat Batterie")
                ) {
                    dispatch(setBatterieState(true));
                }
                return null;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPanier]);

    return (
        <>
            {dataPanier !== undefined &&
            dataPanier !== null &&
            dataPanier.length > 0 ? (
                <>
                    <fieldset className="panier">
                        <legend>Votre demande :</legend>
                        {dataPanier !== undefined &&
                            dataPanier !== null &&
                            dataPanier.map((piece, index) => (
                                <div
                                    key={"panier" + index}
                                    className="itemslines-panier"
                                >
                                    <div className="d-row-align">
                                        <span
                                            className="icon-bin pointer"
                                            onClick={() =>
                                                dispatch(removePiece(piece))
                                            }
                                        >
                                            <RiDeleteBin5Line />
                                        </span>
                                        {piece.id !== undefined ? (
                                            <div>
                                                {piece.id} -{piece.title}
                                                {piece.variantSelected ===
                                                undefined ? (
                                                    <>
                                                        <small className="ps-2">
                                                            {
                                                                piece
                                                                    .variants[0]
                                                                    .sku
                                                            }
                                                        </small>
                                                    </>
                                                ) : (
                                                    <>
                                                        {piece.variants.map(
                                                            (
                                                                variant,
                                                                index
                                                            ) => (
                                                                <span
                                                                    key={
                                                                        variant.id +
                                                                        index
                                                                    }
                                                                >
                                                                    {variant.id ===
                                                                        piece.variantSelected && (
                                                                        <>
                                                                            <br />
                                                                            <small className="ps-2">
                                                                                {variant.title !==
                                                                                    "Default Title" && (
                                                                                    <>
                                                                                        {" "}
                                                                                        Option{" "}
                                                                                        {
                                                                                            variant.title
                                                                                        }{" "}
                                                                                        -
                                                                                    </>
                                                                                )}
                                                                                sku
                                                                                :{" "}
                                                                                {
                                                                                    variant.sku
                                                                                }{" "}
                                                                            </small>
                                                                            <br />
                                                                            {garantieStatus ===
                                                                            true ? (
                                                                                <>
                                                                                    <span className="text-barre">
                                                                                        {(
                                                                                            variant.price *
                                                                                            0.85
                                                                                        ).toFixed(
                                                                                            2
                                                                                        )}

                                                                                        €
                                                                                        TTC{" "}
                                                                                    </span>
                                                                                    {
                                                                                        " => "
                                                                                    }{" "}
                                                                                    <span className="text-black-price">
                                                                                        0
                                                                                        EUR
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {(
                                                                                        variant.price *
                                                                                        0.85
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}

                                                                                    €
                                                                                    TTC
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </span>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div>Type de dommage : {piece}</div>
                                        )}{" "}
                                    </div>
                                    {piece.id !== undefined &&
                                        piece.variants !== null &&
                                        piece.variants.length >= 1 &&
                                        piece.variantSelected === undefined && (
                                            <div>
                                                {garantieStatus === true ? (
                                                    <>
                                                        <span className="text-barre">
                                                            {(
                                                                piece
                                                                    .variants[0]
                                                                    .price *
                                                                0.85
                                                            ).toFixed(2)}
                                                            € TTC{" "}
                                                        </span>
                                                        {" => "}{" "}
                                                        <span className="text-black-price">
                                                            0 EUR
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {(
                                                            piece.variants[0]
                                                                .price * 0.85
                                                        ).toFixed(2)}
                                                        € TTC
                                                    </>
                                                )}
                                            </div>
                                        )}
                                </div>
                            ))}
                        <div className="total-amount d-none">
                            <span>-------------------</span>
                            <span>
                                {" "}
                                TOTAL HT :{" "}
                                {garantieStatus === true ? (
                                    <>
                                        <span className="text-barre">
                                            {(totalAmount / 1.2).toFixed(2)}€ HT{" "}
                                        </span>
                                        {" => "}{" "}
                                        <span className="text-green">
                                            0 EUR
                                        </span>
                                    </>
                                ) : (
                                    <>{(totalAmount / 1.2).toFixed(2)}€ HT</>
                                )}
                            </span>

                            <span>
                                {" "}
                                Taxe TVA FR :{" "}
                                {garantieStatus === true ? (
                                    <>
                                        <span className="text-barre">
                                            {(totalAmount / 1.2).toFixed(2)}€ HT{" "}
                                        </span>
                                        {" => "}{" "}
                                        <span className="text-green">
                                            0 EUR{" "}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        {(
                                            totalAmount -
                                            totalAmount / 1.2
                                        ).toFixed(2)}{" "}
                                        EUR{" "}
                                    </>
                                )}
                            </span>
                            <span>-------------------</span>
                            <span>
                                TOTAL TTC :
                                {garantieStatus === true ? (
                                    <>
                                        <span className="text-barre">
                                            {totalAmount} EUR TTC{" "}
                                        </span>
                                        {" => "}{" "}
                                        <span className="text-green">
                                            0 EUR TTC
                                        </span>
                                    </>
                                ) : (
                                    <> {totalAmount} EUR TTC</>
                                )}
                            </span>
                        </div>{" "}
                    </fieldset>
                    {/* detect batterie piece */}
                    {batterieDetect === true && batterieState === false && (
                        <fieldset className="panier">
                            <legend>
                                {" "}
                                Une pièce concernant une Batterie a été détectée
                                :
                            </legend>
                            <p>
                                Merci de nous donner quelques précisions svp :{" "}
                            </p>
                            <div className="itemslines-batterie-confirm">
                                <div>
                                    Vous attestez que la batterie concernée par
                                    la demande SAV n'a subi aucun choc, qu'elle
                                    ne présente aucune déformation de son casing
                                    ni de trace de fuite, et qu'elle n'a pas eu
                                    de surchauffe dans les 48 dernières heures.
                                </div>
                                <div
                                    className="btn-panier"
                                    onClick={(e) => {
                                        dispatch(
                                            addPiece(
                                                "Confirmation - Etat Batterie : Sans dommage"
                                            )
                                        );
                                    }}
                                >
                                    Je confirme
                                </div>
                            </div>
                            <div className="itemslines-batterie-confirm">
                                <div>
                                    Vous nous informez que la batterie a subi un
                                    choc et/ou qu'elle présente une déformation
                                    de son casing ou des traces de fuites. Dans
                                    ce cas précis, merci de nous envoyer des
                                    photos de ces traces jointes à votre
                                    demande.
                                </div>
                                <div
                                    className="btn-panier"
                                    onClick={(e) => {
                                        dispatch(
                                            addPiece(
                                                "Confirmation - Etat Batterie : avec dommage(s)"
                                            )
                                        );
                                    }}
                                >
                                    Je confirme
                                </div>
                            </div>
                            <div className="itemslines-batterie-confirm">
                                <div>
                                    Vous nous informez que la batterie a subi
                                    une surchauffe dans les 48 dernières heures.
                                    Dans ce cas précis, merci de nous préciser
                                    les circonstances de cette surchauffe.
                                </div>
                                <div
                                    className="btn-panier"
                                    onClick={(e) => {
                                        dispatch(
                                            addPiece(
                                                "Confirmation - Etat Batterie : avec surchauffe"
                                            )
                                        );
                                    }}
                                >
                                    Je confirme
                                </div>
                            </div>
                        </fieldset>
                    )}
                    {(batterieDetect === true && batterieState === true) ||
                    batterieDetect === false ? (
                        <div className="div-btn-panier">
                            <div
                                className="btn-link-web margin-30"
                                onClick={() => {
                                    dispatch(
                                        setParcoursSavTitle(
                                            "Saississez votre SAV"
                                        )
                                    );
                                    dispatch(setParcoursSavStep(4));
                                }}
                            >
                                <button>Ajouter d'autres pièces</button>
                            </div>
                            <div
                                className="btn-link-web margin-30"
                                onClick={() => {
                                    dispatch(
                                        setParcoursSavTitle("Vos Coordonnées")
                                    );
                                    dispatch(setParcoursSavStep(6));
                                }}
                            >
                                <button>Finaliser ma demande</button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            Merci de confirmer l'état de la batterie avant de
                            poursuivre
                        </div>
                    )}
                </>
            ) : (
                <fieldset className="panier">
                    <legend>Votre demande :</legend>
                    <div>
                        Votre panier ne contient aucune pièce. Merci de
                        renseigner les informations demandées dans les onglets
                        précédents pour poursuivre.
                    </div>
                </fieldset>
            )}
        </>
    );
}
