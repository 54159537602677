/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../Redux/actions/stats.actions";

import { useEffect, useState } from "react";
import "../../Pages/sav.css";
import { toast } from "react-toastify";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { BiArrowToTop, BiArrowFromTop } from "react-icons/bi";
import DateSelected from "./DateSelected";

export default function EtatPieces() {
    document.title = "VELOMAD - Stats - Etats des Pièces Vendues";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);

    const dispatch = useDispatch();
    const locations = useSelector((state) => state.Stats.locations);
    const orders_date = useSelector((state) => state.Stats.orders_date);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);

    useEffect(() => {
        dispatch(getLocations(accessToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClickScrollToTotaux = () => {
        setTimeout(() => {
            const element = document.getElementById("btn-footer");

            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    const handleClickScrollToStart = () => {
        setTimeout(() => {
            const element = document.getElementsByTagName("h1")[0];
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let table = undefined;
        if (e.target.innerText.includes("Produits")) {
            table = document.getElementById("my-products");
        } else if (e.target.innerText.includes("Commandes")) {
            table = document.getElementById("my-orders");
        }
        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        if (e.target.innerText.includes("Produits")) {
            formData.append(
                "Title",
                "Ventes_produits_DU_" + dateStart + "_AU_" + dateEnd
            );
        } else if (e.target.innerText.includes("Commandes")) {
            formData.append(
                "Title",
                "Etat_Commandes_DU_" + dateStart + "_AU_" + dateEnd
            );
        }

        async function PostCreateSav(formData) {
            setUrlCsvOrdersDate(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvOrdersDate(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateSav(formData);
    };
    return (
        <main>
            <h1 id="selectProducts">Liste des Pieces vendues</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    {" "}
                    <DateSelected />
                    {orders_date !== null && orders_date !== undefined && (
                        <>
                            <div className="text-center f-column">
                                <button onClick={(e) => handleSubmit(e)}>
                                    Demande d'export Produits
                                </button>
                                {urlCsvOrdersDate !== undefined && (
                                    <a
                                        href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button className="btn">
                                            Obtenir le CSV d'export{" "}
                                            <BsFiletypePdf />{" "}
                                            <BsBoxArrowUpRight />
                                        </button>
                                    </a>
                                )}
                            </div>
                            <div
                                className="button"
                                onClick={() => handleClickScrollToTotaux()}
                            >
                                <BiArrowFromTop />
                            </div>
                            {orders_date !== null &&
                                orders_date !== undefined &&
                                orders_date.length >= 0 && (
                                    <table id="my-products">
                                        <thead>
                                            <tr id="td-header">
                                                <td>N°Commande</td>
                                                <td>PdV</td>
                                                <td>Produits</td>
                                                <td>SKU</td>
                                                <td>Quantité</td>
                                                <td>
                                                    Prix de vente TTC Unitaire
                                                    hors remise
                                                </td>
                                                <td>Remise</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders_date !== null &&
                                                orders_date !== undefined &&
                                                orders_date.length >= 0 && (
                                                    <>
                                                        {orders_date.map(
                                                            (order) => (
                                                                <>
                                                                    {order.cancel_reason ===
                                                                        null &&
                                                                        order.line_items !==
                                                                            null &&
                                                                        order.line_items !==
                                                                            undefined &&
                                                                        order.financial_status !==
                                                                            "refunded" &&
                                                                        order.financial_status !==
                                                                            "avoid" &&
                                                                        order
                                                                            .line_items
                                                                            .length >=
                                                                            0 &&
                                                                        order.line_items.map(
                                                                            (
                                                                                lineItems
                                                                            ) => (
                                                                                <>
                                                                                    <tr
                                                                                        key={
                                                                                            "items" +
                                                                                            lineItems.id +
                                                                                            "order" +
                                                                                            order.id
                                                                                        }
                                                                                    >
                                                                                        <td>
                                                                                            <a
                                                                                                href={
                                                                                                    "https://admin.shopify.com/store/velomad/orders/" +
                                                                                                    order.id
                                                                                                }
                                                                                                rel="noreferrer"
                                                                                                target="_blank"
                                                                                            >
                                                                                                {
                                                                                                    order.name
                                                                                                }
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            {locations !==
                                                                                                undefined &&
                                                                                                locations.map(
                                                                                                    (
                                                                                                        store
                                                                                                    ) => (
                                                                                                        <>
                                                                                                            {parseFloat(
                                                                                                                order.location_id
                                                                                                            ) ===
                                                                                                                parseFloat(
                                                                                                                    store.id
                                                                                                                ) && (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        store.name
                                                                                                                    }
                                                                                                                </>
                                                                                                            )}
                                                                                                            {order.location_id ===
                                                                                                                null &&
                                                                                                                order.source_name ===
                                                                                                                    "web" &&
                                                                                                                parseFloat(
                                                                                                                    store.id
                                                                                                                ) ===
                                                                                                                    parseFloat(
                                                                                                                        78563311897
                                                                                                                    ) && (
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            store.name
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )}
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                        </td>

                                                                                        <td>
                                                                                            {lineItems.product_id !==
                                                                                                null &&
                                                                                            lineItems.variant_id !==
                                                                                                null ? (
                                                                                                <a
                                                                                                    href={
                                                                                                        "https://admin.shopify.com/store/velomad/products/" +
                                                                                                        lineItems.product_id +
                                                                                                        "/variants/" +
                                                                                                        lineItems.variant_id
                                                                                                    }
                                                                                                    rel="noreferrer"
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    {
                                                                                                        lineItems.name
                                                                                                    }
                                                                                                </a>
                                                                                            ) : (
                                                                                                lineItems.name
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                lineItems.sku
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                lineItems.quantity
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                lineItems.price
                                                                                            }
                                                                                        </td>

                                                                                        <td>
                                                                                            {
                                                                                                lineItems.total_discount
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        )}
                                                                    {order
                                                                        .refunds
                                                                        .length >
                                                                        0 &&
                                                                        order.refunds.map(
                                                                            (
                                                                                itemRefund
                                                                            ) => (
                                                                                <>
                                                                                    {itemRefund.refund_line_items !==
                                                                                        undefined &&
                                                                                        itemRefund
                                                                                            .refund_line_items
                                                                                            .length >
                                                                                            0 && (
                                                                                            <>
                                                                                                {itemRefund.refund_line_items.map(
                                                                                                    (
                                                                                                        lineItemRefund
                                                                                                    ) => (
                                                                                                        <>
                                                                                                            <tr
                                                                                                                key={
                                                                                                                    "items" +
                                                                                                                    lineItemRefund.id +
                                                                                                                    "order" +
                                                                                                                    order.id
                                                                                                                }
                                                                                                            >
                                                                                                                <td>
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                                                                                            order.id
                                                                                                                        }
                                                                                                                        rel="noreferrer"
                                                                                                                        target="_blank"
                                                                                                                    >
                                                                                                                        <span className="bg-error">
                                                                                                                            REFUND
                                                                                                                            {
                                                                                                                                order.name
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {locations !==
                                                                                                                        undefined &&
                                                                                                                        locations.map(
                                                                                                                            (
                                                                                                                                store
                                                                                                                            ) => (
                                                                                                                                <>
                                                                                                                                    {parseFloat(
                                                                                                                                        order.location_id
                                                                                                                                    ) ===
                                                                                                                                        parseFloat(
                                                                                                                                            store.id
                                                                                                                                        ) && (
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                store.name
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                    {order.location_id ===
                                                                                                                                        null &&
                                                                                                                                        order.source_name ===
                                                                                                                                            "web" &&
                                                                                                                                        parseFloat(
                                                                                                                                            store.id
                                                                                                                                        ) ===
                                                                                                                                            parseFloat(
                                                                                                                                                78563311897
                                                                                                                                            ) && (
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    store.name
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                </td>

                                                                                                                <td>
                                                                                                                    {lineItemRefund
                                                                                                                        .line_item
                                                                                                                        .product_id !==
                                                                                                                        null &&
                                                                                                                    lineItemRefund
                                                                                                                        .line_item
                                                                                                                        .variant_id !==
                                                                                                                        null ? (
                                                                                                                        <a
                                                                                                                            href={
                                                                                                                                "https://admin.shopify.com/store/velomad/products/" +
                                                                                                                                lineItemRefund
                                                                                                                                    .line_item
                                                                                                                                    .product_id +
                                                                                                                                "/variants/" +
                                                                                                                                lineItemRefund
                                                                                                                                    .line_item
                                                                                                                                    .variant_id
                                                                                                                            }
                                                                                                                            rel="noreferrer"
                                                                                                                            target="_blank"
                                                                                                                        >
                                                                                                                            <span className="bg-error">
                                                                                                                                {
                                                                                                                                    lineItemRefund
                                                                                                                                        .line_item
                                                                                                                                        .name
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    ) : (
                                                                                                                        <span className="bg-error">
                                                                                                                            {
                                                                                                                                lineItemRefund
                                                                                                                                    .line_item
                                                                                                                                    .name
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="bg-error">
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            lineItemRefund
                                                                                                                                .line_item
                                                                                                                                .sku
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="bg-error">
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            -lineItemRefund.quantity
                                                                                                                        }{" "}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="bg-error">
                                                                                                                        {parseFloat(
                                                                                                                            -lineItemRefund
                                                                                                                                .line_item
                                                                                                                                .price
                                                                                                                        )}
                                                                                                                    </span>
                                                                                                                </td>

                                                                                                                <td>
                                                                                                                    0
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                </>
                                                                            )
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </tbody>
                                    </table>
                                )}
                            <div
                                id="btn-footer"
                                className="button mb-4"
                                onClick={() => handleClickScrollToStart()}
                            >
                                <BiArrowToTop />
                            </div>
                        </>
                    )}{" "}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
