import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default

export function SearchOrdersCustomerPresta(idOrder) {
    const [resultOrderCustomerPresta, setData] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.levelomad.com/api/erp/v1/search_order_detail_customer/prestashop/${idOrder}/`,
                    {
                        method: "get",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                } else if (response.status === 500) {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (response.status === 401) {
                    toast.error(
                        "Votre session est périmée, merci de vous relogguer...",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 500) {
                    setData(undefined);
                    toast.error(
                        "Panne de Connexion en direct avec API Prestashop Detail de Commande",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch {
                toast.error(
                    "Oups! Impossible d'envoyer la demande Prestashop Detail de Commande du clients 😔",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (idOrder !== undefined) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { resultOrderCustomerPresta };
}

export function SearchAddressCustomerPresta(idAddress) {
    const [resultAddressPresta, setData] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.levelomad.com/api/erp/v1/search_address_customer/prestashop/${idAddress}/`,
                    {
                        method: "get",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                } else if (response.status === 500) {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (response.status === 401) {
                    toast.error(
                        "Votre session est périmée, merci de vous relogguer...",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 500) {
                    setData(undefined);
                    toast.error(
                        "Panne de Connexion en direct avec API Prestashop Address",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch {
                toast.error(
                    "Oups! Impossible d'envoyer la demande Prestashop Address du clients 😔",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (idAddress !== undefined) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { resultAddressPresta };
}
