import "./header.css";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <header>
            <div className="main-header">
                <a href="https://levelomad.com" rel="noreferrer noopener">
                    <img
                        src={
                            "https://levelomad.com/cdn/shop/files/Group_79.png?v=1685631931&width=150"
                        }
                        alt="Logo - VeloMad"
                        className="logo_velo"
                    />
                </a>
                <nav>
                    <a href="https://levelomad.com" rel="noreferrer noopener">
                        Retour sur le site
                    </a>
                    <Link to="/sav">Demande de SAV</Link>
                    <a
                        href="https://levelomad.com/pages/contact"
                        rel="noreferrer noopener"
                    >
                        Contact
                    </a>
                </nav>
            </div>
        </header>
    );
}
