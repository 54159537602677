import "./login.css";

export default function PageError() {
    document.title = "VELOMAD - Erreur 404";
    document.description = "Impossible de trouver la page";
    return (
        <main>
            <h1>
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/Group_79.png?v=1685631931&width=150"
                    }
                    alt="Logo - VeloMad"
                    className="logo_velo"
                />
            </h1>
            <h2>Error 404 - Page Introuvable</h2>
            <div className="text-center">
                <video
                    className="full_video"
                    playsInline="playsinline"
                    loop="loop"
                    autoPlay="autoplay"
                    muted="muted"
                    preload="metadata"
                    poster="https://levelomad.com/cdn/shop/files/preview_images/0b1be126705e4d61b465dadf3bbaae2d.thumbnail.0000000000_1200x.jpg?v=1692267075"
                >
                    <source
                        src="https://cdn.shopify.com/videos/c/vp/0b1be126705e4d61b465dadf3bbaae2d/0b1be126705e4d61b465dadf3bbaae2d.HD-720p-4.5Mbps-17401736.mp4"
                        type="video/mp4"
                    />
                    <img
                        src="https://levelomad.com/cdn/shop/files/preview_images/0b1be126705e4d61b465dadf3bbaae2d.thumbnail.0000000000_1200x.jpg?v=1692267075"
                        alt=""
                    />
                </video>
            </div>
        </main>
    );
}
