/* eslint-disable no-unused-expressions */
import "./gestion.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SearchPresta from "../Components/Bdd/SearchPresta";
import { useDispatch, useSelector } from "react-redux";
import {
    setMailCustomer,
    setNameCustomer,
} from "../Redux/actions/admin.actions";
import SearchShopi from "../Components/Bdd/SearchShopi";
import SearchNetSuit from "../Components/Bdd/SearchNetSuit";
import {
    searchOrdersByProductIdPresta,
    searchProductsPresta,
} from "../Redux/actions/bdd.actions";
import GenerateInvoicePresta from "../Components/Bdd/GenerateInvoice";

export default function GestionBdd() {
    document.title = "VELOMAD | Les 3 Bases de données ";
    document.description = "Intranet du VELOMAD";
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    const resultProductsPrestashop = useSelector(
        (state) => state.Bdd.resultProductsPrestashop
    );
    const resultOrdersByProductPrestashop = useSelector(
        (state) => state.Bdd.resultOrdersByProductPrestashop
    );
    const dispatch = useDispatch();
    const [inputEmail, setInputEmail] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputIdProduct, setInputIdProduct] = useState(0);
    const dateMaxGarantie = new Date();
    dateMaxGarantie.setDate(dateMaxGarantie.getDate() - 365 * 2);

    const [dateStateMaxGarantie] = useState(dateMaxGarantie);
    function checkValueEmail() {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputEmail)) {
            toast.error("Oups 😦 Le format du Email est invalide ! ", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    const handleSubmitSearchCustomerByMail = (e) => {
        e.preventDefault();
        if (inputEmail.length > 6) {
            dispatch(setMailCustomer(inputEmail));
            handleClickScrollMail();
        }
    };

    const handleSubmitSearchCustomerByName = (e) => {
        e.preventDefault();

        dispatch(setNameCustomer(inputName));
        handleClickScrollMail();
    };

    const handleSubmitSearchProductPrestashop = (e) => {
        dispatch(searchProductsPresta(accessToken, authUser));
    };

    const handleClickScrollMail = () => {
        setTimeout(() => {
            const element = document.getElementById(
                "result_search_customer_mail"
            );
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Les 3 Bases de donnees - Velomad</h1>
                    <div className="text-center">
                        <small>
                            Centralisation des données Prestashop + NetSuite +
                            Shopify
                        </small>
                    </div>
                    <div>
                        <h2>Recherche Client</h2>
                        <div>
                            Utilisez de préférence le mail car la recherche du
                            nom de famille est sensible aux accents et
                            majuscules (doit être exact/identique pour shopify)
                        </div>
                        <fieldset>
                            <legend>
                                Recherche d'un client dans les 3 bases de
                                données
                            </legend>
                            <div className="search-bdd-input">
                                <form
                                    onSubmit={(e) =>
                                        handleSubmitSearchCustomerByMail(e)
                                    }
                                >
                                    <div>
                                        <label className="">
                                            Mail du Client :
                                        </label>
                                        <input
                                            placeholder="adresse@domaine.fr"
                                            type="email"
                                            id="email"
                                            name="email"
                                            onChange={(e) =>
                                                setInputEmail(e.target.value)
                                            }
                                            onBlur={checkValueEmail}
                                            data-testid="inputMailStaff"
                                        />
                                        <div className="mx-auto">
                                            <button
                                                className="form_login-button"
                                                data-testid="btnSubmitCustomer"
                                                type="submit"
                                            >
                                                Rechercher
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <form
                                    onSubmit={(e) =>
                                        handleSubmitSearchCustomerByName(e)
                                    }
                                >
                                    <div>
                                        <label className="">
                                            Nom de famille du Client : <br />
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            onChange={(e) =>
                                                setInputName(e.target.value)
                                            }
                                        />

                                        <div className="mx-auto">
                                            <button
                                                className="form_login-button"
                                                data-testid="btnSubmitCustomer"
                                                type="submit"
                                            >
                                                Rechercher
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </fieldset>

                        <div id="result_search_customer_mail" className="mb-4">
                            <SearchPresta />
                            <SearchShopi />
                            <SearchNetSuit />
                        </div>
                    </div>

                    <div>
                        <h2>
                            Recherche Produit {"<=>"} Facture <br />
                            sur Prestashop{" "}
                        </h2>
                        <fieldset>
                            <legend>
                                Recherche d'un produit dans Prestahop
                            </legend>
                            {resultProductsPrestashop === null ? (
                                <div className="mx-auto">
                                    <button
                                        className="form_login-button"
                                        data-testid="btnSubmitCustomer"
                                        type="submit"
                                        onClick={() =>
                                            handleSubmitSearchProductPrestashop()
                                        }
                                    >
                                        Demander connexion Prestashop
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        {resultProductsPrestashop !== null &&
                                            resultProductsPrestashop.order_details !==
                                                undefined &&
                                            resultProductsPrestashop
                                                .order_details.length}{" "}
                                        Articles Vendus via Prestashop
                                    </div>
                                    <h3>
                                        Produits Prestashop avec Référence
                                        contenant un Y
                                    </h3>
                                    <select
                                        onChange={(e) => {
                                            setInputIdProduct(e.target.value);
                                            dispatch(
                                                searchOrdersByProductIdPresta(
                                                    accessToken,
                                                    authUser,
                                                    e.target.value
                                                )
                                            );
                                        }}
                                    >
                                        <option key={"0"} value={0}>
                                            ID 0 - Faites votre choix
                                        </option>
                                        {Object.keys(resultProductsPrestashop)
                                            .length > 0 &&
                                            resultProductsPrestashop.product_attribute.map(
                                                (product, index) => (
                                                    <>
                                                        {product.reference.slice(
                                                            0,
                                                            1
                                                        ) === "Y" && (
                                                            <option
                                                                key={
                                                                    "product" +
                                                                    index
                                                                }
                                                                value={
                                                                    product.id_product_attribute
                                                                }
                                                            >
                                                                ID{" "}
                                                                {
                                                                    product.id_product_attribute
                                                                }{" "}
                                                                -
                                                                {
                                                                    product.reference
                                                                }
                                                            </option>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </select>

                                    {inputIdProduct !== 0 && (
                                        <div>
                                            Extraction de l'ID Produit{" "}
                                            {inputIdProduct}
                                        </div>
                                    )}
                                    {resultOrdersByProductPrestashop !== null &&
                                        inputIdProduct !== 0 && (
                                            <table
                                                id="all-not-sended"
                                                className="table-center"
                                            >
                                                <thead>
                                                    <tr className="td-header">
                                                        <td>
                                                            N° vente Produit
                                                        </td>
                                                        <td>N° Commande</td>
                                                        <td>Produit</td>
                                                        <td>Reference</td>
                                                        <td>Date Facture</td>
                                                        <td>Garantie</td>
                                                        <td>ID Client</td>
                                                        <td>Facture</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {resultOrdersByProductPrestashop !==
                                                        null &&
                                                        resultOrdersByProductPrestashop !==
                                                            undefined &&
                                                        Object.keys(
                                                            resultOrdersByProductPrestashop
                                                        ).length > 0 &&
                                                        resultOrdersByProductPrestashop.order_details.map(
                                                            (
                                                                order_details,
                                                                index
                                                            ) => (
                                                                <tr
                                                                    key={
                                                                        "product" +
                                                                        index
                                                                    }
                                                                >
                                                                    <td>
                                                                        {
                                                                            order_details.id_order_detail
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            order_details.id_order
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            order_details.product_name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            order_details.product_reference
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {resultOrdersByProductPrestashop.dict_orders[
                                                                            order_details
                                                                                .id_order
                                                                        ].invoice_date.replace(
                                                                            "T",
                                                                            " "
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        className={
                                                                            new Date(
                                                                                resultOrdersByProductPrestashop.dict_orders[
                                                                                    order_details.id_order
                                                                                ].invoice_date
                                                                            ) >
                                                                            dateStateMaxGarantie
                                                                                ? "bg-light-green"
                                                                                : "bg-light-red"
                                                                        }
                                                                    >
                                                                        {new Date(
                                                                            resultOrdersByProductPrestashop.dict_orders[
                                                                                order_details.id_order
                                                                            ].invoice_date
                                                                        ) >
                                                                        dateStateMaxGarantie
                                                                            ? "Garantie OK"
                                                                            : "Hors Garantie"}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            resultOrdersByProductPrestashop
                                                                                .dict_orders[
                                                                                order_details
                                                                                    .id_order
                                                                            ]
                                                                                .id_customer
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <GenerateInvoicePresta
                                                                            cmde={
                                                                                resultOrdersByProductPrestashop
                                                                                    .dict_orders[
                                                                                    order_details
                                                                                        .id_order
                                                                                ]
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        )}
                                </div>
                            )}
                        </fieldset>
                    </div>
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
