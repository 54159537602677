import { useEffect } from "react";
import "./login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    connectAccountStaff,
    getAuthUser,
} from "../Redux/actions/user.actions";

function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = "VELOMAD ERP - Interface de Connexion";
    document.description = "Page de Connexion à l'espace administrateur";
    const location = useLocation();
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            document.getElementById("email").value.length > 0 &&
            document.getElementById("password").value.length > 0
        ) {
            dispatch(
                connectAccountStaff(
                    document.getElementById("email").value,
                    document.getElementById("password").value
                )
            );
        }
    };

    function redirectHistory() {
        if (
            location.state === "/" ||
            location.state === "" ||
            location.state === null
        ) {
            navigate("/app");
        } else {
            navigate(location.state);
        }
    }
    useEffect(() => {
        if (authUser === null && accessToken !== null) {
            dispatch(getAuthUser(accessToken));
        } else if (authUser !== null) {
            redirectHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, authUser, dispatch]);

    return (
        <>
            <div className="gradient-background__wrapper">
                <div className="gradient-background">
                    <div className="gradient-background__shape gradient-background__shape--1"></div>
                    <div className="gradient-background__shape gradient-background__shape--2"></div>
                </div>
                <div className="gradient-background__noise"></div>
            </div>{" "}
            <main className="login">
                <div className="login-content">
                    <h1 className="h1-login">VELOMAD </h1>
                    <h2 className="h2-login">Connecter votre compte </h2>
                    <h3 className="h3-login">pour continuer vers Erp</h3>
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        className="form_login"
                    >
                        <div>
                            <div>
                                <label className="">Votre Email : </label>
                                <input
                                    placeholder="adresse@domaine.fr"
                                    type="email"
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password">
                                    Votre Mot de Passe :{" "}
                                </label>
                                <input
                                    id="password"
                                    type="password"
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    data-testid="inputPasswordStaff"
                                />
                            </div>
                            <div className="mx-auto">
                                <button
                                    className="form_login-button"
                                    data-testid="btnSubmitStaff"
                                    type="submit"
                                >
                                    Se connecter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
}

export default Login;
