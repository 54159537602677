import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import "./gestion.css";
import { useDispatch, useSelector } from "react-redux";
import {
    createUserToEdit,
    getListStaff,
    putUserToEdit,
} from "../Redux/actions/user.actions";

export default function GestionUsers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleClickScrollAdd = () => {
        setTimeout(() => {
            const element = document.getElementById("add_user");
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    const handleClickScrollEdit = () => {
        setTimeout(() => {
            const element = document.getElementById("edit_user");
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    document.title = "VELOMAD | Utilisateurs";
    document.description = "Intranet du VELOMAD";
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.user.authUser);
    const dataListStaff = useSelector((store) => store.user.listUser);

    const accessToken = useSelector((store) => store.user.accessToken);

    useEffect(() => {
        setTimeout(() => {
            dispatch(getListStaff(accessToken));
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [user, setUser] = useState(undefined);
    const [idUser, setIdUser] = useState(undefined);
    const [username, setUsername] = useState(undefined);
    const [isActive, setIsActive] = useState(undefined);
    const [isSuperUser, setIsSuperUser] = useState(undefined);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [showOff, setShowOff] = useState(false);
    const handleClose = () => setShowOff(false);
    const handleShow = (user) => {
        setShowOff(true);
        setIdUser(user.id);
        setUsername(user.username);
        setPhone(user.phone_number);
        setIsActive(user.is_active);
        setIsSuperUser(user.is_superuser);
        setFirstName(user.first_name);
        setLastName(user.last_name.toUpperCase());
        setCheckedMailError(false);
        setUser(user);
    };
    const [showOffAdd, setShowOffAdd] = useState(false);
    const handleCloseAdd = () => setShowOffAdd(false);
    const handleShowAdd = (e) => {
        setShowOffAdd(true);
        setIdUser(undefined);
        setUsername("");
        setIsActive("true");
        setIsSuperUser("false");
        setFirstName("");
        setLastName("");
        setCheckedMailError(true);
    };
    const [checkedMailError, setCheckedMailError] = useState(false);
    function checkValueEmail() {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
            setCheckedMailError(true);
            toast.error("Adresse Email invalide !", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        } else {
            setCheckedMailError(false);
        }
    }
    const handleSubmitAddUser = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("username", username);
        formData.append("is_active", isActive);
        formData.append("is_superuser", isSuperUser);
        formData.append("last_name", lastName);
        formData.append("first_name", firstName);
        formData.append("phone_number", phone);
        formData.append("password", password);

        dispatch(createUserToEdit(formData, accessToken, authUser));
        setTimeout(() => {
            dispatch(getListStaff(accessToken));
        }, 1000);
    };
    const handleSubmitEditUser = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("id", idUser);
        formData.append("username", username);
        formData.append("is_active", isActive);
        formData.append("is_superuser", isSuperUser);
        formData.append("last_name", lastName);
        formData.append("first_name", firstName);
        formData.append("phone_number", phone);
        dispatch(putUserToEdit(formData, accessToken, authUser));
    };

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Gestion du Staff</h1>

                    <div className="bg-antique-light m-3 p-4 border border-2 border-primary rounded">
                        <h2 className="text-center">Liste du Staff</h2>
                        <div className="mx-auto">
                            <div
                                className="button"
                                onClick={(e) => {
                                    handleShowAdd(e);
                                    handleClose();
                                    handleClickScrollAdd();
                                }}
                            >
                                Ajouter{" "}
                                <i className="fa-solid fa-user-plus"></i>
                            </div>
                        </div>

                        {dataListStaff !== null &&
                            dataListStaff.map((element) => (
                                <div key={element.id} className="card_user">
                                    {element.last_name} {element.first_name}{" "}
                                    {element.is_active === true ? (
                                        <span className="text-success">
                                            Compte activé
                                        </span>
                                    ) : (
                                        <span className="text-danger">
                                            Compte Suspendu
                                        </span>
                                    )}{" "}
                                    -{" "}
                                    {element.is_superuser === true && (
                                        <span>
                                            Admin{" "}
                                            <i className="fa-solid fa-crown"></i>
                                        </span>
                                    )}{" "}
                                    <button
                                        className="form_login-button"
                                        onClick={(e) => {
                                            handleShow(element);
                                            handleCloseAdd();
                                            handleClickScrollEdit();
                                        }}
                                    >
                                        Modifier
                                    </button>
                                    <br />
                                    Dernier login :{" "}
                                    <i className="fa-regular fa-clock"></i>{" "}
                                    {element.last_login} - Compte crée le{" "}
                                    {element.date_joined}
                                    <br />
                                    <i className="fa-solid fa-at"></i>{" "}
                                    {element.username}{" "}
                                    <i className="fa-solid fa-mobile-screen"></i>{" "}
                                    {element.phone_number}
                                    <hr />
                                </div>
                            ))}
                    </div>
                    {showOff && (
                        <div className="orders" id="edit_user">
                            <h2>
                                Modifier l'Utilisateur N°
                                {idUser}
                            </h2>
                            <div className="gestion_user">
                                {user !== undefined && (
                                    <form
                                        onSubmit={(e) =>
                                            handleSubmitEditUser(e)
                                        }
                                    >
                                        <h3 className="mt-2 text-wrap">
                                            Nom :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="text"
                                            maxLength={150}
                                            id="lastName"
                                            name="lastName"
                                            defaultValue={lastName}
                                            onChange={(e) =>
                                                setLastName(
                                                    e.target.value.toUpperCase()
                                                )
                                            }
                                        />
                                        <h3 className="mt-2 text-wrap">
                                            Prénom :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="text"
                                            maxLength={150}
                                            id="firstName"
                                            name="firstName"
                                            defaultValue={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                        <h3 className=" mt-2 text-wrap">
                                            Mail :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="mail"
                                            maxLength={254}
                                            id="username"
                                            name="username"
                                            defaultValue={username}
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                            onBlur={checkValueEmail}
                                        />
                                        <h3 className="mt-2 text-wrap">
                                            Phone :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="phone"
                                            maxLength={10}
                                            id="phone"
                                            name="phone"
                                            defaultValue={phone}
                                            onChange={(e) =>
                                                setPhone(e.target.value)
                                            }
                                        />

                                        <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                            Compte Activé :
                                        </h3>
                                        <select
                                            onChange={(e) =>
                                                setIsActive(e.target.value)
                                            }
                                        >
                                            <option value={user.is_active}>
                                                {user.is_active === true
                                                    ? "Actuellement : Oui"
                                                    : "Actuellement : Non"}
                                            </option>
                                            {user.is_active === true ? (
                                                <option value="false">
                                                    Non
                                                </option>
                                            ) : (
                                                <option value="true">
                                                    Oui
                                                </option>
                                            )}
                                        </select>

                                        <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                            Compte Administrateur :
                                        </h3>
                                        <select
                                            onChange={(e) =>
                                                setIsSuperUser(e.target.value)
                                            }
                                        >
                                            <option value={isSuperUser}>
                                                {user.is_superuser === true
                                                    ? "Actuellement : Oui"
                                                    : "Actuellement : Non"}
                                            </option>
                                            {user.is_superuser === true ? (
                                                <option value="false">
                                                    Non
                                                </option>
                                            ) : (
                                                <option value="true">
                                                    Oui
                                                </option>
                                            )}
                                        </select>

                                        {checkedMailError === false && (
                                            <div>
                                                <button
                                                    className="form_login-button"
                                                    type="submit"
                                                >
                                                    Sauvegarder
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                )}
                            </div>
                        </div>
                    )}
                    {showOffAdd && (
                        <div className="gestion_user" id="add_user">
                            <h2> Ajouter un nouvel Utilisateur</h2>{" "}
                            <form onSubmit={(e) => handleSubmitAddUser(e)}>
                                <h3 className=" mt-2 text-wrap">Nom :</h3>

                                <input
                                    className="rounded border border-1 border-chic mx-1"
                                    type="text"
                                    maxLength={150}
                                    id="lastName"
                                    name="lastName"
                                    defaultValue={lastName}
                                    onChange={(e) =>
                                        setLastName(
                                            e.target.value.toUpperCase()
                                        )
                                    }
                                />
                                <h3 className=" mt-2 text-wrap">Prénom :</h3>

                                <input
                                    className="rounded border border-1 border-chic mx-1"
                                    type="text"
                                    maxLength={150}
                                    id="firstName"
                                    name="firstName"
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                                <h3 className="mt-2 text-wrap">Phone :</h3>

                                <input
                                    className="rounded border border-1 border-chic mx-1"
                                    type="phone"
                                    maxLength={10}
                                    id="phone"
                                    name="phone"
                                    defaultValue={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <h3 className="mt-2 text-wrap">Mail :</h3>

                                <input
                                    className="rounded border border-1 border-chic mx-1"
                                    type="mail"
                                    maxLength={254}
                                    id="username"
                                    name="username"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    onBlur={checkValueEmail}
                                />
                                <h3 className="mt-2 text-wrap">
                                    Mot de Passe :
                                </h3>

                                <input
                                    className="rounded border border-1 border-chic mx-1"
                                    type="password"
                                    maxLength={254}
                                    id="password"
                                    name="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />

                                <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                    Compte Activé :
                                </h3>
                                <select
                                    onChange={(e) =>
                                        setIsActive(e.target.value)
                                    }
                                >
                                    <option value="false">Non</option>
                                    <option value="true">Oui</option>
                                </select>

                                <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                    Compte Administrateur :
                                </h3>
                                <select
                                    onChange={(e) =>
                                        setIsSuperUser(e.target.value)
                                    }
                                >
                                    <option value="false">Non</option>
                                    <option value="true">Oui</option>
                                </select>
                                <div>
                                    {checkedMailError === false && (
                                        <button
                                            className="form_login-button"
                                            type="submit"
                                        >
                                            Sauvegarder
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    )}
                    <small>
                        Merci de rafraichir la page après chaque modification...
                    </small>
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
