import { useEffect, useState } from "react";
import { BiLogoShopify } from "react-icons/bi";
import { FaArrowDownAZ } from "react-icons/fa6";
import { MdLocalShipping } from "react-icons/md";
import { useSelector } from "react-redux";

export default function TableVelo() {
    const [watchOrderName, setWatchOrderName] = useState(true);
    const newDicoProductsVelo = useSelector(
        (state) => state.Stats.dicoProductsVelo
    ); // [sku: {quantity: 1, name: "velo", sku: "velo", product: 123, orderName: ["5509126947097#SHOPI1390#quantity:1#location:88647401753"]}
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (newDicoProductsVelo !== undefined && newDicoProductsVelo !== null) {
            setTimeout(() => {
                setTotal(
                    document.getElementsByClassName("quantity-total").length >
                        0 &&
                        Array.from(
                            document.getElementsByClassName("quantity-total")
                        ).reduce(
                            (acc, element) =>
                                acc + parseFloat(element.innerText),
                            0
                        )
                );
            }, 1000);
        }
    }, [newDicoProductsVelo]);
    return (
        <>
            <div className="text-red text-center">
                <label htmlFor="watchOrderName">
                    Afficher les commandes et répartition PDV ?{" "}
                </label>
                <input
                    id="watchOrderName"
                    type="checkbox"
                    className="mx-2 "
                    defaultChecked={watchOrderName}
                    onClick={() => {
                        setWatchOrderName(!watchOrderName);
                    }}
                />
            </div>
            <table id="my-velos" className="table-center">
                <thead>
                    <tr id="td-header">
                        <td>
                            <span className="title-td">Appelation Vélos </span>
                        </td>
                        <td>
                            <span className="title-td">
                                Sku <FaArrowDownAZ />
                            </span>
                        </td>
                        <td>Taille</td>
                        <td>Couleur</td>
                        <td>
                            <span className="title-td">Quantité </span>
                        </td>
                        {watchOrderName === true && (
                            <>
                                <td>Commandes Associées</td>
                                <td>Date Cmde</td>

                                <td>PDV Site</td>
                                <td>PDV Showroom Rouen</td>
                                <td>PDV Showroom Paris</td>
                                <td>PDV Showroom B2B</td>
                                <td>Précommande</td>
                                <td>Fabrication</td>
                                <td>Reconditionné</td>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody id="body-my-velos">
                    {newDicoProductsVelo !== undefined &&
                    newDicoProductsVelo !== null &&
                    Object.keys(newDicoProductsVelo).length > 0 ? (
                        Object.keys(newDicoProductsVelo).map((key) => (
                            <>
                                {parseFloat(
                                    newDicoProductsVelo[key].quantity
                                ) !== parseFloat(0) && (
                                    <>
                                        {newDicoProductsVelo[key].order !==
                                            undefined &&
                                            newDicoProductsVelo[key].order
                                                .length > 0 &&
                                            newDicoProductsVelo[key].order.map(
                                                (order, index) => (
                                                    <>
                                                        <tr>
                                                            <td
                                                                className={
                                                                    index > 0
                                                                        ? "td-transparent"
                                                                        : ""
                                                                }
                                                            >
                                                                {typeof newDicoProductsVelo[
                                                                    key
                                                                ].product !==
                                                                "number" ? (
                                                                    <a
                                                                        className={
                                                                            index >
                                                                            0
                                                                                ? "td-transparent"
                                                                                : ""
                                                                        }
                                                                        href={
                                                                            "https://admin.shopify.com/store/velomad/products/" +
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .product
                                                                                .id +
                                                                            "/variants/" +
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .variantId
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        className={
                                                                            index >
                                                                            0
                                                                                ? "td-transparent"
                                                                                : ""
                                                                        }
                                                                        href={
                                                                            "https://admin.shopify.com/store/velomad/products/" +
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .product +
                                                                            "/variants/" +
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .variantId
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </a>
                                                                )}
                                                            </td>
                                                            <td
                                                                className={
                                                                    index > 0
                                                                        ? "td-transparent"
                                                                        : ""
                                                                }
                                                            >
                                                                {newDicoProductsVelo[
                                                                    key
                                                                ].sku === null
                                                                    ? "Saisie Manuelle"
                                                                    : newDicoProductsVelo[
                                                                          key
                                                                      ].sku}
                                                            </td>
                                                            <td
                                                                className={
                                                                    index > 0
                                                                        ? "td-transparent"
                                                                        : ""
                                                                }
                                                            >
                                                                {
                                                                    newDicoProductsVelo[
                                                                        key
                                                                    ].size
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    index > 0
                                                                        ? "td-transparent"
                                                                        : ""
                                                                }
                                                            >
                                                                {
                                                                    newDicoProductsVelo[
                                                                        key
                                                                    ].color
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    index > 0
                                                                        ? "td-transparent quantity-total"
                                                                        : " quantity-total"
                                                                }
                                                            >
                                                                {index === 0
                                                                    ? newDicoProductsVelo[
                                                                          key
                                                                      ].quantity
                                                                    : 0}
                                                            </td>
                                                            {watchOrderName ===
                                                                true && (
                                                                <>
                                                                    <td
                                                                        className={
                                                                            order.name
                                                                        }
                                                                    >
                                                                        {typeof order !==
                                                                        "string" ? (
                                                                            <span className="ligne-td">
                                                                                <a
                                                                                    href={
                                                                                        "https://admin.shopify.com/store/velomad/orders/" +
                                                                                        order.id
                                                                                    }
                                                                                    rel="noreferrer"
                                                                                    target="_blank"
                                                                                    className="commandes-velos"
                                                                                >
                                                                                    <BiLogoShopify className="text-shopify" />
                                                                                    {
                                                                                        order.name
                                                                                    }
                                                                                    {newDicoProductsVelo[
                                                                                        key
                                                                                    ].fulfillment.map(
                                                                                        (
                                                                                            expedition
                                                                                        ) => (
                                                                                            <>
                                                                                                {expedition !==
                                                                                                    undefined &&
                                                                                                    expedition.includes(
                                                                                                        order.name
                                                                                                    ) && (
                                                                                                        <MdLocalShipping className="text-green" />
                                                                                                    )}
                                                                                            </>
                                                                                        )
                                                                                    )}
                                                                                </a>
                                                                            </span>
                                                                        ) : (
                                                                            "error prévenir Dev"
                                                                        )}
                                                                    </td>
                                                                    <td className="text-no-wrap">
                                                                        {
                                                                            order.created_at.split(
                                                                                "T"
                                                                            )[0]
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        {parseFloat(
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ].orderName[
                                                                                index
                                                                            ]
                                                                                .split(
                                                                                    "#"
                                                                                )[3]
                                                                                .split(
                                                                                    "location:"
                                                                                )[1]
                                                                        ) ===
                                                                        parseFloat(
                                                                            78563311897
                                                                        ) ? (
                                                                            <span className="quantity-site">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                                <br />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                                <br />
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {parseFloat(
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ].orderName[
                                                                                index
                                                                            ]
                                                                                .split(
                                                                                    "#"
                                                                                )[3]
                                                                                .split(
                                                                                    "location:"
                                                                                )[1]
                                                                        ) ===
                                                                        parseFloat(
                                                                            88647401753
                                                                        ) ? (
                                                                            <span className="quantity-rouen">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                                <br />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                                <br />
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {parseFloat(
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ].orderName[
                                                                                index
                                                                            ]
                                                                                .split(
                                                                                    "#"
                                                                                )[3]
                                                                                .split(
                                                                                    "location:"
                                                                                )[1]
                                                                        ) ===
                                                                        parseFloat(
                                                                            88642617625
                                                                        ) ? (
                                                                            <span className="quantity-paris">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                                <br />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                                <br />
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {parseFloat(
                                                                            newDicoProductsVelo[
                                                                                key
                                                                            ].orderName[
                                                                                index
                                                                            ]
                                                                                .split(
                                                                                    "#"
                                                                                )[3]
                                                                                .split(
                                                                                    "location:"
                                                                                )[1]
                                                                        ) ===
                                                                        parseFloat(
                                                                            90150437145
                                                                        ) ? (
                                                                            <span className="quantity-b2b">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                                <br />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                                <br />
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {newDicoProductsVelo[
                                                                            key
                                                                        ].orderName[
                                                                            index
                                                                        ]
                                                                            .split(
                                                                                "#"
                                                                            )[4]
                                                                            .split(
                                                                                "tags:"
                                                                            )[1]
                                                                            .includes(
                                                                                "precommande"
                                                                            ) ? (
                                                                            <span className="quantity-précommande">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {newDicoProductsVelo[
                                                                            key
                                                                        ].orderName[
                                                                            index
                                                                        ]
                                                                            .split(
                                                                                "#"
                                                                            )[4]
                                                                            .split(
                                                                                "tags:"
                                                                            )[1]
                                                                            .includes(
                                                                                "fabrication"
                                                                            ) ? (
                                                                            <span className="quantity-fabrication">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {newDicoProductsVelo[
                                                                            key
                                                                        ].orderName[
                                                                            index
                                                                        ]
                                                                            .split(
                                                                                "#"
                                                                            )[4]
                                                                            .split(
                                                                                "tags:"
                                                                            )[1]
                                                                            .includes(
                                                                                "reconditionné"
                                                                            ) ? (
                                                                            <span className="quantity-reconditionné">
                                                                                {
                                                                                    newDicoProductsVelo[
                                                                                        key
                                                                                    ].orderName[
                                                                                        index
                                                                                    ]
                                                                                        .split(
                                                                                            "#"
                                                                                        )[2]
                                                                                        .split(
                                                                                            "quantity:"
                                                                                        )[1]
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span className="ligne-td-0 ">
                                                                                0
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    </>
                                                )
                                            )}
                                    </>
                                )}
                            </>
                        ))
                    ) : (
                        <tr></tr>
                    )}
                    <tr id="td-footer">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{total}</td>
                        {watchOrderName === true && (
                            <>
                                <td></td>
                                <td></td>

                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-site"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-site"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-rouen"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-rouen"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-paris"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-paris"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-b2b"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-b2b"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-précommande"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-précommande"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-fabrication"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-fabrication"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                                <td>
                                    {watchOrderName === true &&
                                        document.getElementsByClassName(
                                            "quantity-reconditionné"
                                        ).length > 0 &&
                                        Array.from(
                                            document.getElementsByClassName(
                                                "quantity-reconditionné"
                                            )
                                        ).reduce(
                                            (acc, element) =>
                                                acc +
                                                parseFloat(element.innerText),
                                            0
                                        )}
                                </td>
                            </>
                        )}
                    </tr>
                </tbody>

                <tfoot>
                    <tr className="td-footer">
                        <td>
                            <span className="title-td">Appelation Vélos </span>
                        </td>
                        <td>
                            <span className="title-td">
                                Sku <FaArrowDownAZ />
                            </span>
                        </td>
                        <td>Taille</td>
                        <td>Couleur</td>
                        <td>
                            <span className="title-td">Quantité </span>
                        </td>
                        {watchOrderName === true && (
                            <>
                                <td>Commandes Associées</td>
                                <td>Date Cmde</td>
                                <td>PDV Site</td>
                                <td>PDV Showroom Rouen</td>
                                <td>PDV Showroom Paris</td>
                                <td>PDV Showroom B2B</td>
                                <td>Précommande</td>
                                <td>Fabrication</td>
                                <td>Reconditionné</td>
                            </>
                        )}
                    </tr>
                </tfoot>
            </table>
        </>
    );
}
