import { useDispatch, useSelector } from "react-redux";
import "./sav.css";
import "./maps.css";
import VeloSelected from "../Components/Sav/VeloSelected";
import Panier from "../Components/Sav/Panier";
import PiecesSav from "../Components/Sav/PiecesSav";
import AboutVelo from "../Components/User/AboutVelo";
import Coordonnees from "../Components/User/Coordonnees";
import VeloToChoice from "../Components/Sav/VeloToChoice";
import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";
import {
    erasePieceSAV,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../Redux/actions/velo.actions";

export default function Sav(props) {
    document.title = "Notre formulaire de demande SAV";
    document.description =
        "Pour toutes demandes SAV de pièces ou de réparation de votre Velomad, remplissez le formulaire pour que nos équipes soit le plus informés possible.";
    const openNavBurger = useSelector((state) => state.style.openNavBurger);
    const parcoursSavTitle = useSelector(
        (state) => state.veloDetails.parcoursSavTitle
    );
    const parcoursSavStep = useSelector(
        (state) => state.veloDetails.parcoursSavStep
    );
    const dispatch = useDispatch();
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );
    const dataPanier = useSelector((state) => state.panierSav);
    const batterieDetect = useSelector((state) => state.user.batterieDetect);
    const batterieState = useSelector((state) => state.user.batterieState);
    return (
        <>
            {openNavBurger === false && (
                <>
                    <main className="main-sav">
                        <div className="banner-sav"></div>
                        <div className="banner-title">
                            <h1 id="title_sav">
                                Demande <br />
                                SAV {props.type}
                            </h1>
                        </div>
                        <div className="parcours dimension-main ">
                            <div>
                                <h2>
                                    {parcoursSavTitle !== null &&
                                        parcoursSavTitle}
                                </h2>
                            </div>
                            <div className="parcours_line">
                                <div
                                    className={
                                        parcoursSavStep === 1
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dispatch(
                                            setParcoursSavTitle(
                                                "Choisissez votre gamme"
                                            )
                                        );
                                        dispatch(setParcoursSavStep(1));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    1. Gamme Sav
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 2
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Choisissez votre version"
                                                )
                                            );
                                        dispatch(setParcoursSavStep(2));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    2. Vélo
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 3
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Etat du Velomad"
                                                )
                                            );
                                        dispatch(setParcoursSavStep(3));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    3. Etat
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 4
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Saississez votre SAV"
                                                )
                                            );
                                        dispatch(setParcoursSavStep(4));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    4.{" "}
                                    {props.type === "b2b"
                                        ? "Pièces"
                                        : "Dommages"}
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 5
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle("Panier")
                                            );
                                        dispatch(erasePieceSAV());
                                        dispatch(setParcoursSavStep(5));
                                    }}
                                >
                                    5. Panier{" "}
                                    {dataPanier !== null &&
                                        dataPanier.length > 0 &&
                                        "(" + dataPanier.length + ")"}
                                </div>
                                {((batterieDetect === true &&
                                    batterieState === true) ||
                                    batterieDetect === false) && (
                                    <div
                                        className={
                                            parcoursSavStep === 6
                                                ? "parcoursSelected"
                                                : "parcoursElement"
                                        }
                                        onClick={() => {
                                            dataVeloSelect !== null &&
                                                dispatch(
                                                    setParcoursSavTitle(
                                                        "Vos Coordonnées"
                                                    )
                                                );
                                            dispatch(erasePieceSAV());
                                            dispatch(setParcoursSavStep(6));
                                        }}
                                    >
                                        6. Coordonnées
                                    </div>
                                )}
                            </div>
                        </div>{" "}
                        <div className="dimension-main ">
                            {parcoursSavStep === 1 && <VeloToChoice />}

                            {parcoursSavStep === 2 && <VeloSelected />}

                            {parcoursSavStep === 3 && <AboutVelo />}

                            {parcoursSavStep === 4 && (
                                <PiecesSav type={props.type} />
                            )}
                            {parcoursSavStep === 5 && <Panier />}
                            {parcoursSavStep === 6 && (
                                <Coordonnees type={props.type} />
                            )}
                        </div>
                    </main>
                    <SingeFooterShopifyVelomad />
                    <FooterShopifyVelomad />
                </>
            )}
        </>
    );
}
