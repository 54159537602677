/* eslint-disable react-hooks/exhaustive-deps */
import "./piecesSav.css";
import "../Modal/modal.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    erasePieceSAV,
    getCatSav,
    getPiecesSav,
    getPiecesSavById,
    getProductSavB2C,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import PieceInput from "./PieceInput";
import {
    addPiece,
    addPieceSAVB2C,
} from "../../Redux/actions/panierSav.actions";
import LoadingCycle from "./LoaderCycle";

export default function PiecesSav(props) {
    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const dataPiecesSav = useSelector((state) => state.veloDetails.pieces_sav);
    const selectSku = useSelector((state) => state.veloDetails.modele);
    const pieceSelectId = useSelector(
        (state) => state.veloDetails.pieceSelectId
    );
    const catSAV = useSelector((state) => state.veloDetails.catSAV);
    const dataPanier = useSelector((state) => state.panierSav);
    const sku = useSelector((state) => state.veloDetails.sku);

    const dispatch = useDispatch();
    const foundPiecesSav = useSelector(
        (state) => state.veloDetails.foundPiecesSav
    );
    const foundProductSavB2C = useSelector(
        (state) => state.veloDetails.productSavB2C
    );
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );
    const etatState = useSelector((state) => state.user.etat);
    const garantieState = useSelector((state) => state.user.garantie);
    const [searchCat, setSearchCat] = useState(undefined);

    useEffect(() => {
        dispatch(getCatSav());
        if (props.type !== "b2b") {
            dispatch(getProductSavB2C(9183727124761));
        }
    }, [selectSku]);

    useEffect(() => {
        if (pieceSelectId !== undefined) {
            dispatch(getPiecesSavById(pieceSelectId));
        }
    }, [pieceSelectId]);

    useEffect(() => {
        if (searchCat !== undefined) {
            dispatch(erasePieceSAV());
            setTimeout(() => {
                dispatch(getPiecesSav(searchCat));
            }, 500);
        }
    }, [searchCat]);

    const [searchV1U, setSearchV1U] = useState(false);
    const [searchV1S, setSearchV1S] = useState(false);
    const [searchV1US, setSearchV1US] = useState(false);
    const [searchVPlus, setSearchVPlus] = useState(false);
    const [searchV2U, setSearchV2U] = useState(false);
    const [searchV2S, setSearchV2S] = useState(false);
    const [searchV2US, setSearchV2US] = useState(false);
    const [searchConnect, setSearchConnect] = useState(false);
    const [searchConnectPlus, setSearchConnectPlus] = useState(false);
    const [searchElite, setSearchElite] = useState(false);
    const [searchLight, setSearchLight] = useState(false);
    const [searchCargo, setSearchCargo] = useState(false);

    useEffect(() => {
        setSearchV1U(false);
        setSearchV1S(false);
        setSearchV1US(false);
        setSearchVPlus(false);
        setSearchV2U(false);
        setSearchV2S(false);
        setSearchV2US(false);
        setSearchConnect(false);
        setSearchConnectPlus(false);
        setSearchElite(false);
        setSearchLight(false);
        setSearchCargo(false);
        if (selectSku !== null && selectSku !== undefined) {
            if (
                selectSku.includes("Urbain") &&
                !selectSku.split("#")[0].includes("2") &&
                !selectSku.includes("Elite")
            ) {
                setSearchV1U(true);
                setSearchV1US(true);
            }

            if (
                selectSku.includes("Sport") &&
                !selectSku.split("#")[0].includes("2") &&
                !selectSku.split("#")[0].includes("+") &&
                !selectSku.includes("Elite")
            ) {
                setSearchV1S(true);
                setSearchV1US(true);
            }

            if (selectSku.split("#")[0].includes("+")) {
                setSearchVPlus(true);
            }
            if (
                selectSku.split("#")[0].includes("2") &&
                selectSku.includes("Urbain") &&
                !selectSku.includes("Elite")
            ) {
                setSearchV2U(true);
                setSearchV2US(true);
            }
            if (
                selectSku.split("#")[0].includes("2") &&
                selectSku.includes("Sport") &&
                !selectSku.includes("Elite")
            ) {
                setSearchV2S(true);
                setSearchV2US(true);
            }
            if (selectSku.includes("Connect")) {
                setSearchConnect(true);
            }
            if (selectSku.includes("Connect") && selectSku.includes("+")) {
                setSearchConnect(false);
                setSearchConnectPlus(true);
            }
            if (selectSku.includes("Elite")) {
                setSearchElite(true);
            }
            if (selectSku.includes("Light")) {
                setSearchLight(true);
            }
            if (selectSku.includes("Cargo")) {
                setSearchV2U(true);
                setSearchV2US(true);
            }
        }
    }, [selectSku]);

    const handleClick = (key) => {
        dispatch(addPiece(catSAV[key].split("SAV - ")[1]));
        dispatch(addPieceSAVB2C(foundProductSavB2C));
        dispatch(setParcoursSavTitle("Panier"));
        dispatch(setParcoursSavStep(5));
    };
    return (
        <>
            {dataVeloSelect !== undefined &&
            selectSku !== null &&
            selectSku !== undefined &&
            etatState !== null &&
            garantieState !== null ? (
                <div id="piecesSAVListing">
                    <ul className="d-none">
                        {searchV1U === true && (
                            <li className="statut statut-actived statut-show">
                                V1U
                            </li>
                        )}
                        {searchV1S === true && (
                            <li className="statut statut-actived statut-show">
                                V1S
                            </li>
                        )}
                        {searchV1US === true && (
                            <li className="statut statut-actived statut-show">
                                V1US
                            </li>
                        )}
                        {searchVPlus === true && (
                            <li className="statut statut-actived statut-show">
                                V+
                            </li>
                        )}
                        {searchV2U === true && (
                            <li className="statut statut-actived statut-show">
                                V2U
                            </li>
                        )}
                        {searchV2S === true && (
                            <li className="statut statut-actived statut-show">
                                V2S
                            </li>
                        )}
                        {searchV2US === true && (
                            <li className="statut statut-actived statut-show">
                                V2US
                            </li>
                        )}
                        {searchConnect === true && (
                            <li className="statut statut-actived statut-show">
                                Connect
                            </li>
                        )}
                        {searchConnectPlus === true && (
                            <li className="statut statut-actived statut-show">
                                Connect+
                            </li>
                        )}
                        {searchElite === true && (
                            <li className="statut statut-actived statut-show">
                                Elite
                            </li>
                        )}
                        {searchLight === true && (
                            <li className="statut statut-actived statut-show">
                                Light
                            </li>
                        )}
                        {searchCargo === true && (
                            <li className="statut statut-actived statut-show">
                                Cargo
                            </li>
                        )}
                    </ul>
                    <div className="etat_small">Modèle sélectionné :</div>
                    <div> {sku}</div>
                    <div>
                        {selectSku !== null &&
                            selectSku !== undefined &&
                            selectSku
                                .replace("sav", "")
                                .split("#")
                                .map((element, index) => (
                                    <div key={index}>
                                        {element.replace(" - ", " ")}
                                    </div>
                                ))}
                    </div>
                    {selectSku.includes("Elite") === true ? (
                        <>
                            <div>
                                Désolé, il n'y a pas encore de pièces SAV de
                                disponibles en ligne. <br />
                                Merci de poursuivre le formulaire avec votre
                                numéro de portable en commentaire pour une prise
                                de contact par téléphone SVP.
                            </div>
                            <div
                                className="btn-link-web margin-30"
                                onClick={() => {
                                    dataVeloSelect !== null &&
                                        dispatch(setParcoursSavTitle("Panier"));
                                    dataVeloSelect !== null &&
                                        dispatch(
                                            addPiece(
                                                "Piece SAV non disponibles en ligne"
                                            )
                                        );
                                    dispatch(setParcoursSavStep(5));
                                }}
                            >
                                <button>Aller au Panier</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="etat_small">
                                Type de dommages :{" "}
                            </div>

                            <div className="listing-cat">
                                {catSAV !== null &&
                                    Object.keys(catSAV).map((key) => (
                                        <div
                                            className={
                                                key !== searchCat
                                                    ? "velo_product pointer"
                                                    : "velo_product selected pointer"
                                            }
                                            key={"cat" + key}
                                            onClick={() => {
                                                setSearchCat(undefined);
                                                setTimeout(() => {
                                                    props.type === "b2b"
                                                        ? setSearchCat(key)
                                                        : handleClick(key);
                                                }, 500);
                                            }}
                                        >
                                            {catSAV[key].split("SAV - ")[1]}
                                        </div>
                                    ))}
                            </div>
                            {selectSku !== null &&
                                selectSku !== undefined &&
                                etatState !== null &&
                                garantieState !== null &&
                                dataPanier !== null &&
                                dataPanier.length > 0 && (
                                    <div
                                        className="btn-link-web margin-30"
                                        onClick={() => {
                                            dataVeloSelect !== null &&
                                                dispatch(
                                                    setParcoursSavTitle(
                                                        "Panier"
                                                    )
                                                );
                                            dispatch(setParcoursSavStep(5));
                                        }}
                                    >
                                        <button>Aller au Panier</button>
                                    </div>
                                )}
                        </>
                    )}
                    <div className="mb-4 listing_sav">
                        {searchCat !== undefined && dataPiecesSav === null && (
                            <>
                                <LoadingCycle />
                                <div className="text-center">
                                    Chargement de la liste des Pièces
                                    disponibles en cours ...
                                </div>
                            </>
                        )}
                        {dataPiecesSav !== undefined &&
                            dataPiecesSav !== null &&
                            dataPiecesSav.length > 1 &&
                            dataPiecesSav.map((piece) => (
                                <>
                                    {piece.title.startsWith("V") === true &&
                                        piece.status === "active" && (
                                            <>
                                                {searchV1U === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("V1") &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("U") &&
                                                    !piece.title
                                                        .split(" ")[0]
                                                        .includes("2") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchV1S === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("V1") &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("S") &&
                                                    !piece.title
                                                        .split(" ")[0]
                                                        .includes("2") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchVPlus === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("V+") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchV2U === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("V2") &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("U") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchV2S === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("V2") &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("S") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchConnect === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("C") &&
                                                    !piece.title
                                                        .split(" ")[0]
                                                        .includes("+") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchConnectPlus === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("C") &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("+") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchElite === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("E") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                                {searchLight === true &&
                                                    piece.title
                                                        .split(" ")[0]
                                                        .includes("L") && (
                                                        <PieceInput
                                                            piece={piece}
                                                        />
                                                    )}
                                            </>
                                        )}
                                </>
                            ))}
                    </div>

                    {foundPiecesSav === false && (
                        <>
                            <div>
                                Désolé, il n'y a pas encore de pièces SAV de
                                disponibles en ligne. <br />
                                Merci de poursuivre le formulaire avec votre
                                numéro de téléphone en commentaire pour une
                                prise de contact par téléphone SVP.
                            </div>
                            <div
                                className="btn-link-web margin-30"
                                onClick={() => {
                                    dataVeloSelect !== null &&
                                        dispatch(setParcoursSavTitle("Panier"));
                                    dataVeloSelect !== null &&
                                        dispatch(
                                            addPiece(
                                                "Piece SAV non disponibles en ligne"
                                            )
                                        );
                                    dispatch(setParcoursSavStep(5));
                                }}
                            >
                                <button>Aller au Panier</button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className=" margin-30">
                    Merci de sélectionner un statut de garantie et d'utilisation
                    dans l'onglet 3 précédent
                </div>
            )}
        </>
    );
}
