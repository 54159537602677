import axios from "axios";
import { toast } from "react-toastify";

export const getDataMap = () => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/map/v1/obtain/")
            .then((res) => {
                return dispatch({
                    type: "OBTAIN_DATA_MAP",
                    elements: res.data.elements,
                    categories: res.data.categories,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "OBTAIN_DATA_MAP",
                        elements: null,
                        categories: null,
                    });
                }
            });
    };
};

export const showElementModal = (element) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/map/v1/element-maps/open/${element.id}`
            )
            .then((res) => {
                return dispatch({
                    type: "SHOW_ELEMENT",
                    showElement: element,
                });
            })
            .catch((res) => {
                return dispatch({
                    type: "SHOW_ELEMENT",
                    showElement: element,
                });
            });
    };
};

export const showNull = () => {
    return async (dispatch) => {
        return dispatch({
            type: "SHOW_ELEMENT_NULL",
            showElement: null,
        });
    };
};

export const getCollectionsMap = () => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/map/v1/velos/")
            .then((res) => {
                return dispatch({
                    type: "OBTAIN_DATA_VELO_MAP",
                    veloSKU: res.data.products,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "OBTAIN_DATA_VELO_MAP",
                        veloSKU: null,
                    });
                }
            });
    };
};
export const createElementMap = (newElement, accessToken) => {
    return async (dispatch) => {
        const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
        };
        const config = {
            method: "post",
            url: "https://api.levelomad.com/api/map/v1/element-maps/",
            data: newElement,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "PUT_ELEMENT_MAP",
                    elements: res.data.elements,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 401) {
                        toast.warn(
                            "Accès non authorisé, votre session est périmée, veuillez vous reconnecter",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 404) {
                        toast.warn("Element Map introuvable - pb d'ID", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "OBTAIN_DATA_MAP_error",
                    });
                }
            });
    };
};

export const putElementMap = (newElement, accessToken) => {
    return async (dispatch) => {
        const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
        };
        const config = {
            method: "put",
            url: "https://api.levelomad.com/api/map/v1/element-maps/",
            data: newElement,
            headers: headers,
        };

        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "PUT_ELEMENT_MAP",
                    elements: res.data.elements,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 401) {
                        toast.warn(
                            "Accès non authorisé, votre session est périmée, veuillez vous reconnecter",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    } else if (error.response.status === 404) {
                        toast.warn("Element Map introuvable - pb d'ID", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "OBTAIN_DATA_MAP_error",
                    });
                }
            });
    };
};

export const setFilterCat = (element) => {
    return async (dispatch) => {
        return dispatch({
            type: "SET_FILTER_CAT",
            filterCat: element,
        });
    };
};

export const getStatsMap = (accessToken) => {
    return async (dispatch) => {
        const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
        };
        const config = {
            method: "get",
            url: "https://api.levelomad.com/api/map/v1/element-maps/stats/",

            headers: headers,
        };
        return await axios(config)
            .then((res) => {
                return dispatch({
                    type: "GET_STATS_MAP",
                    statsMaps: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error(
                            "Le serveur distant API Stats Maps est en erreur",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                    return dispatch({
                        type: "GET_STATS_MAP",
                        statsMaps: null,
                    });
                }
            });
    };
};
