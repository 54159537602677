import { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";
import { VscClose } from "react-icons/vsc";
import { IoHomeOutline } from "react-icons/io5";
import { IoBicycle } from "react-icons/io5";
import { GiAutoRepair } from "react-icons/gi";
import { TbMapSearch } from "react-icons/tb";
import { FaDoorOpen, FaUserLock, FaPuzzlePiece } from "react-icons/fa6";
import { LiaMapSolid } from "react-icons/lia";
import { BsDatabaseFillLock, BsFillSendExclamationFill } from "react-icons/bs";
import { BiLogoShopify } from "react-icons/bi";
import { ImStatsDots } from "react-icons/im";

import { useDispatch, useSelector } from "react-redux";
import { IoStorefrontSharp } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { IoIosBicycle } from "react-icons/io";
import { PiMotorcycleThin } from "react-icons/pi";
import {
    eraseOrdersDate,
    getOrdersByDate,
} from "../../Redux/actions/stats.actions";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { GrDashboard } from "react-icons/gr";

export default function HeaderERP(props) {
    const [showBurger, setShowBurger] = useState(false);
    registerLocale("fr", fr);

    // const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.user.accessToken);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    function handleSetDate(dateSelected) {
        setDateRange(dateSelected);
        if (dateSelected[0] !== null && dateSelected[1] !== null) {
            if (dateSelected !== undefined && dateSelected.length >= 2) {
                let checkInDate = new Date();
                checkInDate.setFullYear(dateSelected[0].getFullYear());
                checkInDate.setMonth(dateSelected[0].getMonth());
                checkInDate.setDate(dateSelected[0].getDate());
                checkInDate = String(checkInDate.toISOString().split("T")[0]);

                let checkOutDate = new Date();
                checkOutDate.setFullYear(dateSelected[1].getFullYear());
                checkOutDate.setMonth(dateSelected[1].getMonth());
                checkOutDate.setDate(dateSelected[1].getDate());
                checkOutDate = String(checkOutDate.toISOString().split("T")[0]);
                dispatch(eraseOrdersDate());

                dispatch(
                    getOrdersByDate(checkInDate, checkOutDate, accessToken)
                );
            }
        }
    }
    return (
        <>
            {" "}
            <div className="date-picker-erp">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={true}
                    onChange={(dateSelected) => {
                        handleSetDate(dateSelected);
                    }}
                    monthsShown={2}
                    maxDate={new Date()}
                    locale="fr"
                    placeholderText="Période d'extraction"
                />
            </div>
            <header>
                <nav className="nav-mobile-erp">
                    <div
                        className="nav-icon-burger"
                        onClick={() => setShowBurger(!showBurger)}
                    >
                        {showBurger === false ? <RiMenu2Fill /> : <VscClose />}
                    </div>{" "}
                    <a
                        href="https://erp.levelomad.com"
                        rel="noreferrer noopener"
                    >
                        <img
                            src={
                                "https://levelomad.com/cdn/shop/files/Group_79.png?v=1685631931&width=150"
                            }
                            alt="Logo - VeloMad"
                            className="logo_velo"
                        />
                    </a>
                </nav>

                <div
                    className={
                        showBurger === true
                            ? "burger-menu bg-grey-nav nav-erp"
                            : "burger-menu burger-translate bg-grey-nav nav-erp"
                    }
                    onMouseLeave={() => setShowBurger(false)}
                >
                    <small>ERP</small>
                    <Link onClick={() => setShowBurger(false)} to="/gestion">
                        <IoHomeOutline />
                        Accueil
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/users"
                    >
                        <FaUserLock /> Staff
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/maps"
                    >
                        <LiaMapSolid /> Gestion Map
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/maps/stats"
                    >
                        <ImStatsDots /> Stats Map
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/bdd"
                    >
                        <BsDatabaseFillLock /> Bdd Clients Presta/Oracle/
                        <BiLogoShopify />
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/shopify/customers"
                    >
                        <BiLogoShopify /> Clients Shopify
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/shopify/parrainage"
                    >
                        <BiLogoShopify /> Parrainage
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/sav"
                    >
                        <GiAutoRepair /> Demandes Sav
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/reprise"
                    >
                        <GiAutoRepair /> Prix Reprise
                    </Link>
                    <hr />
                    <small>Stats</small>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/gestion/stats"
                    >
                        <ImStatsDots /> Stats
                    </Link>
                    {accessToken !== undefined && accessToken !== null && (
                        <>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                showIcon
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={true}
                                onChange={(dateSelected) => {
                                    handleSetDate(dateSelected);
                                }}
                                monthsShown={2}
                                maxDate={new Date()}
                                locale="fr"
                                placeholderText="Période d'extraction"
                            />
                            {dateStart !== null && dateEnd !== null && (
                                <>
                                    <Link
                                        to="/gestion/stats/dispo"
                                        className={
                                            props.link === "stock"
                                                ? "link-active"
                                                : ""
                                        }
                                    >
                                        <IoStorefrontSharp />
                                        <span>Produits Disponibles</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/stock"
                                        className={
                                            props.link === "stock"
                                                ? "link-active bg-yellow-stats"
                                                : "bg-yellow-stats"
                                        }
                                    >
                                        <IoStorefrontSharp />
                                        <span>Stocks</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/tdb"
                                        className={
                                            props.link === "tdb"
                                                ? "link-active bg-yellow-stats"
                                                : "bg-yellow-stats"
                                        }
                                    >
                                        <span className="bg-yellow-stats ">
                                            <GrDashboard />
                                            Tableau de Bord
                                        </span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/commandes"
                                        className={
                                            props.link === "commandes"
                                                ? "link-active"
                                                : ""
                                        }
                                    >
                                        <FaShoppingCart />
                                        <span>Commandes</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/pieces"
                                        className={
                                            props.link === "pieces"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <FaPuzzlePiece />

                                        <span>Pièces</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/reparations"
                                        className={
                                            props.link === "reparations"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <GiAutoRepair />

                                        <span>Réparations</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/velos"
                                        className={
                                            props.link === "velos"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <IoIosBicycle />

                                        <span>Vélos</span>
                                    </Link>
                                    <Link
                                        to="/gestion/stats/precommandes"
                                        className={
                                            props.link === "precommandes"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <PiMotorcycleThin />

                                        <span>Précommandes</span>
                                    </Link>

                                    <Link
                                        to="/gestion/stats/nonlivres"
                                        className={
                                            props.link === "nonlivres"
                                                ? "link-active "
                                                : ""
                                        }
                                    >
                                        <BsFillSendExclamationFill />

                                        <span>Expédition</span>
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                    <hr />
                    <small>Public</small>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="https://levelomad.com"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <IoBicycle />
                        Velomad
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="https://sav.levelomad.com"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {" "}
                        <GiAutoRepair /> SAV
                    </Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="https://maps.levelomad.com"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <TbMapSearch /> Maps
                    </Link>
                    <Link
                        to="https://admin.shopify.com/store/velomad/"
                        rel="noreferrer noopener"
                        target="_blank"
                    ></Link>
                    <Link
                        onClick={() => setShowBurger(false)}
                        to="/deconnexion"
                    >
                        <FaDoorOpen />
                        Déconnexion
                    </Link>
                </div>
            </header>
        </>
    );
}
