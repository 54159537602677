import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../Redux/actions/style.action";
import { getCollectionsMap } from "../../Redux/actions/map.actions";
import "./modal.css";
import { getCollectionVelos } from "../../Redux/actions/velo.actions";
import React from "react";
import { FaWindowClose } from "react-icons/fa";

export default function Modal() {
    const dispatch = useDispatch();
    const showModal = useSelector((state) => state.style.showModal);
    const handleQuit = () => {
        dispatch(closeModal());
        //dispatch(showNull());
    };
    const categories = useSelector((state) => state.Map.categories);
    const showElement = useSelector((state) => state.Map.showElement);
    const dataCollectionVelos = useSelector(
        (state) => state.veloDetails.collection_velos
    );

    useEffect(() => {
        if (categories === null || dataCollectionVelos === null) {
            dispatch(getCollectionVelos());
            dispatch(getCollectionsMap());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const detectDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        if (window.innerWidth < 551 && showModal === true) {
            setTimeout(() => {
                const element = document.getElementById("title-modal");
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);
        }
    };
    // load on charge page
    useEffect(() => {
        window.addEventListener("load", detectDimension);
        return () => {
            window.removeEventListener("load", detectDimension);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // refresh calcul on screen resize
    useEffect(() => {
        window.addEventListener("resize", detectDimension);
        return () => {
            window.removeEventListener("resize", detectDimension);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSize]);
    return (
        <div
            className={
                showModal !== true
                    ? "modal_body transparent"
                    : showElement.visible === true ||
                      showElement.visible === "true"
                    ? "modal_body"
                    : "modal_body filter-gray"
            }
            id="title-modal"
        >
            <div className="modal_close" onClick={() => handleQuit()}>
                Fermer la fiche
                <span>
                    <FaWindowClose />{" "}
                </span>
            </div>
            {showElement.title !== "" && (
                <div>
                    <div className="modal-title">
                        <div>
                            {showElement.url_img_header !== null &&
                                showElement.url_img_header !== undefined &&
                                showElement.url_img_header !== "" &&
                                showElement.url_img_header.includes("http") && (
                                    <div className="img-header ">
                                        <img
                                            src={showElement.url_img_header}
                                            alt={showElement.title}
                                        />
                                    </div>
                                )}{" "}
                        </div>
                        <div>
                            <h2>{showElement.title}</h2>
                            <hr className="hr-modal " />
                            {showElement.category !== undefined &&
                                showElement.category !== null &&
                                showElement.category
                                    .split(",")
                                    .map((elemCat) => (
                                        <p className="categoryMap">
                                            {categories.map((cat) => (
                                                <>
                                                    {parseFloat(cat.id) ===
                                                        parseFloat(elemCat) && (
                                                        <>{cat.name}</>
                                                    )}
                                                </>
                                            ))}
                                        </p>
                                    ))}
                        </div>
                    </div>
                    <div className="modal-scroll-content">
                        {showElement.description !== null &&
                            showElement.description !== "" &&
                            showElement.description !== '" "' && (
                                <div className="modal-description">
                                    {showElement.description}
                                </div>
                            )}

                        <div className="modal-content">
                            <div className="modal_content-card ">
                                {showElement.phone !== null &&
                                    showElement.phone !== "" && (
                                        <>
                                            <h3>Téléphone: </h3>
                                            <a
                                                href={
                                                    "tel:" +
                                                    showElement.phone.replaceAll(
                                                        " ",
                                                        ""
                                                    )
                                                }
                                            >
                                                {showElement.phone}
                                            </a>
                                        </>
                                    )}

                                {showElement.mail !== null &&
                                    showElement.mail !== "" && (
                                        <>
                                            {" "}
                                            <h3>E-mail: </h3>
                                            <a
                                                href={
                                                    "mailto:" + showElement.mail
                                                }
                                            >
                                                {showElement.mail}
                                            </a>
                                        </>
                                    )}
                                {showElement.city !== null &&
                                    showElement.city !== "" && (
                                        <>
                                            <h3>Adresse : </h3>
                                            <span>{showElement.address}</span>
                                            <span>
                                                {showElement.zipcode}{" "}
                                                {showElement.city}
                                            </span>
                                            <span>{showElement.country}</span>
                                        </>
                                    )}
                            </div>
                            {showElement.url_img_cycle !== null &&
                                showElement.url_img_cycle !== "" && (
                                    <div className="modal_content-velo ">
                                        <div>
                                            {showElement.url_img_cycle
                                                .split(",")
                                                .map((element, index) => (
                                                    <img
                                                        src={element}
                                                        alt={
                                                            showElement.model_cycle.split(
                                                                "et"
                                                            )[index]
                                                        }
                                                    />
                                                ))}
                                        </div>
                                        <small>{showElement.model_cycle}</small>
                                    </div>
                                )}
                        </div>
                        {showElement.url_link_website !== null &&
                            showElement.url_link_website !== "" &&
                            showElement.text_link_web !== null &&
                            showElement.text_link_web !== "" && (
                                <div className="btn-link-modal margin-btn-modal">
                                    <a
                                        href={showElement.url_link_website}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button>
                                            {showElement.text_link_web}
                                        </button>
                                    </a>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </div>
    );
}
