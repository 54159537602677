const initialState = {
    mailCustomerSearch: null,
    idCustomerSearch: null,
    idOrderCustomerSearch: null,
    phoneCustomerSearch: null,
    nameCustomerSearch: null,
};

export default function AdminStaff(state = initialState, action) {
    switch (action.type) {
        case "SET_MAIL_CUSTOMER":
            return {
                ...state,
                mailCustomerSearch: action.mailCustomerSearch,
            };
        case "SET_PHONE_CUSTOMER":
            return {
                ...state,
                phoneCustomerSearch: action.phoneCustomerSearch,
            };
        case "SET_NAME_CUSTOMER":
            return {
                ...state,
                nameCustomerSearch: action.nameCustomerSearch,
            };
        case "SET_ID_CUSTOMER":
            return {
                ...state,
                idCustomerSearch: action.idCustomerSearch,
            };
        case "SET_ID_ORDER_CUSTOMER":
            return {
                ...state,
                idOrderCustomerSearch: action.idOrderCustomerSearch,
            };
        default:
            return state;
    }
}
