/* eslint-disable array-callback-return */
import { PieChart, Pie, Sector } from "recharts";
import { useEffect, useState, useCallback } from "react";

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={250} y={40} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{` x ${value} `}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(Soit ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const renderActiveShapeSum = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={250} y={40} dy={8} textAnchor="middle" fill={"#808e79"}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={"#f3aa86"}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={"#808e79"}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={"#808e79"}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={"#808e79"} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#808e79"
            >{`  ${value.toFixed(2)} Euros`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#808e79"
            >
                {`(Soit ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function GraphRepartRepair(props) {
    const [dataQuantity, setDataQuanity] = useState(undefined);
    const [dataTotal, setDataTotal] = useState(undefined);

    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    useEffect(() => {
        let dicoProdQuantity = [];
        let dicoProdPrice = [];
        if (props.dicoRepairProduct !== undefined) {
            Object.keys(props.dicoRepairProduct).map((item) => {
                dicoProdQuantity.push({
                    name: props.dicoRepairProduct[item].name,
                    value: props.dicoRepairProduct[item].quantity,
                });
                dicoProdPrice.push({
                    name: props.dicoRepairProduct[item].name,
                    value: parseFloat(
                        props.dicoRepairProduct[item].price *
                            props.dicoRepairProduct[item].quantity.toFixed(2)
                    ),
                });
            });
        }
        setDataQuanity(dicoProdQuantity);
        setDataTotal(dicoProdPrice);
    }, [props.dicoRepairProduct]);
    return (
        <>
            {props !== undefined && (
                <div className="graph-repart">
                    <div className="f-column ">
                        <div className="mb-4">
                            Réparation {props.title} / Quantité
                        </div>

                        {dataQuantity !== undefined && (
                            <PieChart width={500} height={400} className="pie">
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShape}
                                    data={dataQuantity}
                                    innerRadius={80}
                                    outerRadius={90}
                                    fill="#1d3c34"
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                />
                            </PieChart>
                        )}
                    </div>{" "}
                    <div className="f-column">
                        <div className="mb-4">
                            Réparation {props.title} / CA généré
                        </div>

                        {dataTotal !== undefined && (
                            <PieChart width={500} height={400} className="pie">
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShapeSum}
                                    data={dataTotal}
                                    cx={250}
                                    cy={200}
                                    innerRadius={80}
                                    outerRadius={90}
                                    fill="#1d3c34"
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                />
                            </PieChart>
                        )}
                    </div>
                </div>
            )}{" "}
        </>
    );
}
