import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./gestionMap.css";
import "./stats.css";
import {
    getDataMap,
    showElementModal,
    putElementMap,
    createElementMap,
} from "../Redux/actions/map.actions";
import { toast } from "react-toastify";
import { BiEdit } from "react-icons/bi";
import { RiSave3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaWindowClose } from "react-icons/fa";

export default function GestionMaps() {
    document.title = "VELOMAD | Gestion Maps";
    document.description = "Intranet du VELOMAD";
    const dispatch = useDispatch();
    const elements = useSelector((state) => state.Map.elements);
    const categories = useSelector((state) => state.Map.categories);
    const showElement = useSelector((state) => state.Map.showElement);
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    const [filterCat, setFilterCat] = useState("");
    useEffect(() => {
        dispatch(getDataMap());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClickScrollModal = () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    };
    const [openAdd, setOpenAdd] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [elementFiltred, setElementFiltred] = useState(0);
    const [newElement, setNewElement] = useState({
        address: "",
        category: "",
        city: "",
        country: "France",
        description: "",
        gps: "",
        id: 0,
        mail: "",
        model_cycle: "",
        phone: "",
        text_link_web: "",
        title: "",
        url_icon_map: "",
        url_img_cycle: "",
        url_img_header: "",
        url_link_website: "",
        visible: true,
        zipcode: "",
    });
    useEffect(() => {
        dispatch(showElementModal(newElement));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNewElement(showElement);
    }, [showElement]);

    useEffect(() => {
        setElementFiltred(
            Array.from(document.getElementsByClassName("elementMap")).length
        );
    }, [searchWord]);
    const [uploadedFilesProfil, setUploadedFilesProfil] = useState([]);
    const handleUploadFilesProfil = (files) => {
        const uploaded = [...uploadedFilesProfil];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
            return true;
        });
        setUploadedFilesProfil(uploaded);
    };

    const handleFileEventProfil = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFilesProfil(chosenFiles);
    };
    async function put_img_profil(formData) {
        try {
            const response = await fetch(
                `https://api.levelomad.com/api/map/v1/element-maps/img-profil/`,
                {
                    method: "put",
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        Accept: "application/json",
                    }),
                    body: formData,
                }
            );
            if (response.status === 202) {
                toast.success("Intégration Photo Réussie ✅", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (err) {
            toast.error(
                "Oups! Impossible de faire la modification, contacter la hotline 😔",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    }
    const handleSubmitProfile = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("id", newElement.id);
        if (
            uploadedFilesProfil !== undefined &&
            uploadedFilesProfil.length >= 1
        ) {
            formData.append(
                "files",
                uploadedFilesProfil[0],
                uploadedFilesProfil[0].name
            );
        }

        put_img_profil(formData);
    };
    const [uploadedFilesVelo, setUploadedFilesVelo] = useState([]);
    const handleUploadFilesVelo = (files) => {
        const uploaded = [...uploadedFilesVelo];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
            return true;
        });
        setUploadedFilesVelo(uploaded);
    };

    const handleFileEventVelo = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFilesVelo(chosenFiles);
    };
    async function put_img_Velo(formData) {
        try {
            const response = await fetch(
                `https://api.levelomad.com/api/map/v1/element-maps/img-velo/`,
                {
                    method: "put",
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        Accept: "application/json",
                    }),
                    body: formData,
                }
            );
            if (response.status === 202) {
                toast.success("Intégration Photo Réussie ✅", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (err) {
            toast.error(
                "Oups! Impossible de faire la modification, contacter la hotline 😔",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    }
    const handleSubmitVelo = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("id", newElement.id);
        if (uploadedFilesVelo !== undefined && uploadedFilesVelo.length >= 1) {
            formData.append(
                "files",
                uploadedFilesVelo[0],
                uploadedFilesVelo[0].name
            );
        }

        put_img_Velo(formData);
    };
    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Gestion Maps</h1>
                    <Link to="/gestion/maps/stats">
                        Accéder aux stats Maps{" "}
                    </Link>
                    {openAdd === false ? (
                        <>
                            <div
                                className="add"
                                onClick={() => {
                                    dispatch(
                                        showElementModal({
                                            address: "",
                                            category: "",
                                            city: "",
                                            country: "France",
                                            description: "",
                                            gps: "",
                                            id: 0,
                                            mail: "",
                                            model_cycle: "",
                                            phone: "",
                                            text_link_web: "",
                                            title: "",
                                            url_icon_map: "",
                                            url_img_cycle: "",
                                            url_img_header: "",
                                            url_link_website: "",
                                            visible: true,
                                            zipcode: "",
                                        })
                                    );
                                    setOpenAdd(true);
                                }}
                            >
                                Ajouter
                            </div>
                            <div
                                className="fixed-add add"
                                onClick={() => {
                                    dispatch(
                                        showElementModal({
                                            address: "",
                                            category: "",
                                            city: "",
                                            country: "France",
                                            description: "",
                                            gps: "",
                                            id: 0,
                                            mail: "",
                                            model_cycle: "",
                                            phone: "",
                                            text_link_web: "",
                                            title: "",
                                            url_icon_map: "",
                                            url_img_cycle: "",
                                            url_img_header: "",
                                            url_link_website: "",
                                            visible: true,
                                            zipcode: "",
                                        })
                                    );
                                    setTimeout(() => {
                                        dispatch(showElementModal(newElement));
                                        setOpenAdd(false);
                                    }, 1000);
                                }}
                            >
                                Ajouter
                            </div>
                        </>
                    ) : (
                        <div
                            className="fixed-save"
                            onClick={() => {
                                newElement.id !== 0
                                    ? dispatch(
                                          putElementMap(newElement, accessToken)
                                      )
                                    : dispatch(
                                          createElementMap(
                                              newElement,
                                              accessToken
                                          )
                                      );
                                setNewElement({
                                    address: "",
                                    category: "",
                                    city: "",
                                    country: "France",
                                    description: "",
                                    gps: "",
                                    id: 0,
                                    mail: "",
                                    model_cycle: "",
                                    phone: "",
                                    text_link_web: "",
                                    title: "",
                                    url_icon_map: "",
                                    url_img_cycle: "",
                                    url_img_header: "",
                                    url_link_website: "",
                                    visible: true,
                                    zipcode: "",
                                });
                                setTimeout(() => {
                                    dispatch(showElementModal(newElement));
                                    setOpenAdd(false);
                                }, 1000);
                                setTimeout(() => {
                                    dispatch(getDataMap());
                                }, 1500);
                            }}
                        >
                            <RiSave3Fill /> Sauvegarder
                        </div>
                    )}
                    {openAdd && (
                        <div className="addForm ">
                            <div
                                className="modal_close text-white"
                                onClick={() => {
                                    setNewElement({
                                        address: "",
                                        category: "",
                                        city: "",
                                        country: "France",
                                        description: "",
                                        gps: "",
                                        id: 0,
                                        mail: "",
                                        model_cycle: "",
                                        phone: "",
                                        text_link_web: "",
                                        title: "",
                                        url_icon_map: "",
                                        url_img_cycle: "",
                                        url_img_header: "",
                                        url_link_website: "",
                                        visible: true,
                                        zipcode: "",
                                    });
                                    setTimeout(() => {
                                        dispatch(showElementModal(newElement));
                                        setOpenAdd(false);
                                    }, 1000);
                                    setTimeout(() => {
                                        dispatch(getDataMap());
                                    }, 1500);
                                }}
                            >
                                Fermer la fiche
                                <span>
                                    <FaWindowClose />{" "}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="categorie">
                                        Catégories :
                                    </label>

                                    <div
                                        className="categorie"
                                        name="categorie"
                                        id="categorie"
                                    >
                                        {categories !== null &&
                                            categories !== undefined &&
                                            categories.length > 0 &&
                                            categories.map((categorie) => (
                                                <div key={"cat" + categorie.id}>
                                                    {newElement.category.includes(
                                                        categorie.id
                                                    ) === true ? (
                                                        <input
                                                            name={
                                                                "cat" +
                                                                categorie.id
                                                            }
                                                            id={
                                                                "cat" +
                                                                categorie.id
                                                            }
                                                            type="checkbox"
                                                            checked
                                                            onChange={(e) => {
                                                                newElement.category.includes(
                                                                    categorie.id
                                                                ) === true
                                                                    ? setNewElement(
                                                                          {
                                                                              ...newElement,
                                                                              category:
                                                                                  newElement.category.replace(
                                                                                      ", " +
                                                                                          categorie.id,
                                                                                      ""
                                                                                  ),
                                                                          }
                                                                      )
                                                                    : setNewElement(
                                                                          {
                                                                              ...newElement,
                                                                              category:
                                                                                  newElement.category +
                                                                                  ", " +
                                                                                  categorie.id,
                                                                          }
                                                                      );
                                                            }}
                                                        />
                                                    ) : (
                                                        <input
                                                            name={
                                                                "cat" +
                                                                categorie.id
                                                            }
                                                            id={
                                                                "cat" +
                                                                categorie.id
                                                            }
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                newElement.category.includes(
                                                                    categorie.id
                                                                ) === true
                                                                    ? setNewElement(
                                                                          {
                                                                              ...newElement,
                                                                              category:
                                                                                  newElement.category.replace(
                                                                                      ", " +
                                                                                          categorie.id,
                                                                                      ""
                                                                                  ),
                                                                          }
                                                                      )
                                                                    : setNewElement(
                                                                          {
                                                                              ...newElement,
                                                                              category:
                                                                                  newElement.category +
                                                                                  ", " +
                                                                                  categorie.id,
                                                                          }
                                                                      );
                                                            }}
                                                        />
                                                    )}

                                                    <label
                                                        htmlFor={
                                                            "cat" + categorie.id
                                                        }
                                                    >
                                                        {categorie.name}
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="visible">Visible ?</label>
                                    <select
                                        name="visible"
                                        id="visible"
                                        className="visible"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                visible: e.target.value,
                                            });
                                        }}
                                    >
                                        {" "}
                                        <option value={showElement.visible}>
                                            {showElement.visible === true
                                                ? "Actuellement : Oui"
                                                : "Actuellement : Non"}
                                        </option>
                                        <option
                                            value={
                                                showElement.visible === true
                                                    ? false
                                                    : true
                                            }
                                        >
                                            {showElement.visible === true
                                                ? "Non"
                                                : "Oui"}
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="titre">Titre</label>
                                    <input
                                        type="text"
                                        name="titre"
                                        id="titre"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                title: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.title !== undefined &&
                                            newElement.title
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="description">
                                        Description
                                    </label>
                                    <textarea
                                        rows={8}
                                        type="text"
                                        name="description"
                                        id="description"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                description: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.description
                                        }
                                    ></textarea>
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="url_img_header">
                                        Image Entête
                                    </label>
                                    <div>
                                        {" "}
                                        <img
                                            src={
                                                newElement !== null &&
                                                newElement.url_img_header !==
                                                    undefined &&
                                                newElement.url_img_header
                                            }
                                            alt={
                                                newElement !== null &&
                                                newElement.title !==
                                                    undefined &&
                                                newElement.title
                                            }
                                            width={"40px"}
                                            height={"40px"}
                                        />
                                        <input
                                            type="text"
                                            name="url_img_header"
                                            id="url_img_header"
                                            onChange={(e) => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_header:
                                                        e.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                newElement !== null &&
                                                newElement.url_img_header !==
                                                    undefined &&
                                                newElement.url_img_header
                                            }
                                        />
                                    </div>
                                </div>
                                <form
                                    onSubmit={handleSubmitProfile}
                                    encType="multipart/form-data"
                                >
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(e) =>
                                            // eslint-disable-next-line no-sequences
                                            handleFileEventProfil(e)
                                        }
                                    />

                                    <button
                                        className="btn-submit "
                                        type="submit"
                                    >
                                        <RiSave3Fill /> Envoyer
                                    </button>
                                </form>
                                <div className="form-group-map">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                phone: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.phone !== undefined &&
                                            newElement.phone
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="mail">Mail</label>
                                    <input
                                        type="email"
                                        name="mail"
                                        id="mail"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                mail: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.mail !== undefined &&
                                            newElement.mail
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="modele_velo">
                                        Modèle Vélo (texte)
                                    </label>
                                    <input
                                        type="text"
                                        name="modele_velo"
                                        id="modele_velo"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                model_cycle: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.model_cycle !==
                                                undefined &&
                                            newElement.model_cycle
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="url_photo_velo">
                                        Modèle Vélo (Photo)
                                    </label>
                                    <div>
                                        {newElement !== null &&
                                            newElement.url_img_cycle !==
                                                undefined &&
                                            newElement.url_img_cycle && (
                                                <>
                                                    {newElement.url_img_cycle
                                                        .split(",")
                                                        .map((element) => (
                                                            <img
                                                                src={element}
                                                                alt={element}
                                                                width={"50px"}
                                                                height={"50px"}
                                                            />
                                                        ))}
                                                </>
                                            )}
                                        <input
                                            type="text"
                                            name="url_photo_velo"
                                            id="url_photo_velo"
                                            onChange={(e) => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        e.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                newElement !== null &&
                                                newElement.url_img_cycle !==
                                                    undefined &&
                                                newElement.url_img_cycle
                                            }
                                        />
                                    </div>
                                </div>
                                <small>
                                    Le clic sur une photo modifie la miniature
                                    modifie dans la base de données (ne pas
                                    toucher à l'input texte)
                                </small>
                                <div className="choice-velo">
                                    {" "}
                                    <div>
                                        <span>V1</span>
                                    </div>
                                    <div>
                                        <span>sport-elite-noir</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-elite-noir-profil.png"
                                            }
                                            alt={"sport-elite-noir"}
                                            title="sport-elite-noir"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-elite-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-noir</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-noir-profil.png"
                                            }
                                            alt={"sport-noir"}
                                            title="sport-noir"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-blanc</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-blanc-profil.png"
                                            }
                                            alt={"sport-blanc"}
                                            title="sport-blanc"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-blanc-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-bleu</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-bleu-profil.png"
                                            }
                                            alt={"sport-bleu"}
                                            title="sport-bleu"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-bleu-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-plus-noir</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-plus-noir-profil.png"
                                            }
                                            alt={"sport-plus-noir"}
                                            title="sport-plus-noir"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-plus-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-plus-vert</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-plus-vert-profil.png"
                                            }
                                            alt={"sport-plus-vert"}
                                            title="sport-plus-vert"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-plus-vert-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>urbain-blanc-profil</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-blanc-profil.png"
                                            }
                                            alt={"urbain-blanc-profil"}
                                            title="urbain-blanc-profil"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-blanc-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>urbain-noir-profil</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-noir-profil.png"
                                            }
                                            alt={"urbain-noir-profil"}
                                            title="urbain-noir-profil"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>urban-elite-noir-profil</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-urban-elite-noir-profil.png"
                                            }
                                            alt={"urban-elite-noir-profil"}
                                            title="urban-elite-noir-profil"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-urban-elite-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>V2</span>
                                    </div>
                                    <div>
                                        <span>sport-2-gris</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-2-gris-profil.png"
                                            }
                                            alt={"sport-2-gris"}
                                            title="sport-2-gris"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-2-gris-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>sport-2-noir</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-sport-2-noir-profil.png"
                                            }
                                            alt={"sport-2-noir"}
                                            title="sport-2-noir"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-sport-2-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>urbain-2-blanc</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-2-blanc-profil.png"
                                            }
                                            alt={"urbain-2-blanc"}
                                            title="urbain-2-blanc"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-2-blanc-profil.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>urbain-2-noir</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-2-noir-profil.png"
                                            }
                                            alt={"urbain-2-noir"}
                                            title="urbain-2-noir"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-urbain-2-noir-profil.png",
                                                });
                                            }}
                                        />
                                    </div>{" "}
                                    <div>
                                        <span>CARGO</span>
                                    </div>
                                    <div>
                                        <span>cargo-base</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_1.png"
                                            }
                                            alt={"cargo-base"}
                                            title="cargo-base"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_1.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>cargo-kit-enfant-sans-guppy</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_2-kit-enfant-sans-guppy.png"
                                            }
                                            alt={"cargo-kit-enfant-sans-guppy"}
                                            title="cargo-kit-enfant-sans-guppy"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_2-kit-enfant-sans-guppy.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>cargo-kit-enfant-sans-guppy</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_3-kit-enfant-complet.png"
                                            }
                                            alt={"cargo-kit-enfant-complet"}
                                            title="cargo-kit-enfant-complet"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_3-kit-enfant-complet.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>
                                            cargo--enfant-complet-et-porte-bebe
                                        </span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_4-enfant-complet-et-porte-bebe.png"
                                            }
                                            alt={
                                                "cargo--enfant-complet-et-porte-bebe"
                                            }
                                            title="cargo--enfant-complet-et-porte-bebe"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_4-enfant-complet-et-porte-bebe.png",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span>cargo--caisse</span>
                                        <img
                                            src={
                                                "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_5_CAISSE.png"
                                            }
                                            alt={"cargo--caisse"}
                                            title="cargo--ciasse"
                                            onClick={() => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_img_cycle:
                                                        "https://api.levelomad.com/media/img_maps/VELOMAD-CARGO_5_CAISSE.png",
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <small>
                                    On peut ajouter un 2e velo, mettre une
                                    virgule sans espace et coller l'addresse de
                                    la 2e photo (clicdroit copier adresse) et la
                                    coller sans espace
                                </small>
                                <form
                                    onSubmit={handleSubmitVelo}
                                    encType="multipart/form-data"
                                    className="d-none"
                                >
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(e) =>
                                            // eslint-disable-next-line no-sequences
                                            handleFileEventVelo(e)
                                        }
                                    />

                                    <button
                                        className="btn-submit "
                                        type="submit"
                                    >
                                        <RiSave3Fill /> Envoyer
                                    </button>
                                </form>
                                <div className="form-group-map">
                                    <label htmlFor="texte_lien_web">
                                        Texte Lien Web
                                    </label>
                                    <input
                                        type="text"
                                        name="texte_lien_web"
                                        id="texte_lien_web"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                text_link_web: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.text_link_web !==
                                                undefined &&
                                            newElement.text_link_web
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="url_lien_web">
                                        URL Lien Web
                                    </label>
                                    <input
                                        type="text"
                                        name="url_lien_web"
                                        id="url_lien_web"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                url_link_website:
                                                    e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.url_link_website !==
                                                undefined &&
                                            newElement.url_link_website
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="url_icon_map">
                                        <div>
                                            Icône Map Choix parmi :{" "}
                                            <div className="choice-icon-map">
                                                {" "}
                                                <img
                                                    src={
                                                        "https://maps.levelomad.com/icon/icon-ambassadeurs-mascotte.png"
                                                    }
                                                    alt={"Ambassadeurs"}
                                                    className="pointer"
                                                    title="Ambassadeurs"
                                                    onClick={() => {
                                                        setNewElement({
                                                            ...newElement,
                                                            url_icon_map:
                                                                "https://maps.levelomad.com/icon/icon-ambassadeurs-mascotte.png",
                                                        });
                                                    }}
                                                    width={"20px"}
                                                    height={"20px"}
                                                />
                                                <img
                                                    src={
                                                        "https://maps.levelomad.com/icon/icon-reparateurs.png"
                                                    }
                                                    alt={"Réparateurs"}
                                                    className="pointer"
                                                    title="Réparateurs"
                                                    onClick={() => {
                                                        setNewElement({
                                                            ...newElement,
                                                            url_icon_map:
                                                                "https://maps.levelomad.com/icon/icon-reparateurs.png",
                                                        });
                                                    }}
                                                    width={"20px"}
                                                    height={"20px"}
                                                />
                                                <img
                                                    src={
                                                        "https://maps.levelomad.com/icon/icon-revendeurs.png"
                                                    }
                                                    alt={"Revendeurs"}
                                                    className="pointer"
                                                    title="Revendeurs"
                                                    onClick={() => {
                                                        setNewElement({
                                                            ...newElement,
                                                            url_icon_map:
                                                                "https://maps.levelomad.com/icon/icon-revendeurs.png",
                                                        });
                                                    }}
                                                    width={"20px"}
                                                    height={"20px"}
                                                />
                                                <img
                                                    src={
                                                        "https://maps.levelomad.com/icon/icon-showrooms.png"
                                                    }
                                                    alt={"Magasins"}
                                                    className="pointer"
                                                    title="Magasins"
                                                    onClick={() => {
                                                        setNewElement({
                                                            ...newElement,
                                                            url_icon_map:
                                                                "https://maps.levelomad.com/icon/icon-showrooms.png",
                                                        });
                                                    }}
                                                    width={"20px"}
                                                    height={"20px"}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                    <div className="d-flex d-flex-row">
                                        <img
                                            src={
                                                newElement !== null &&
                                                newElement.url_icon_map !==
                                                    undefined &&
                                                newElement.url_icon_map
                                            }
                                            alt={
                                                newElement !== null &&
                                                newElement.title !==
                                                    undefined &&
                                                newElement.title
                                            }
                                            width={"30px"}
                                            height={"30px"}
                                        />
                                        <input
                                            type="text"
                                            name="url_icon_map"
                                            id="url_icon_map"
                                            onChange={(e) => {
                                                setNewElement({
                                                    ...newElement,
                                                    url_icon_map:
                                                        e.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                newElement !== null &&
                                                newElement.url_icon_map !==
                                                    undefined &&
                                                newElement.url_icon_map
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="url_icon_map">
                                        Adresse
                                    </label>

                                    <input
                                        type="text"
                                        name="address"
                                        id="address"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                address: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.address !== undefined &&
                                            newElement.address
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="zipcode">Code Postal</label>

                                    <input
                                        type="text"
                                        name="zipcode"
                                        id="zipcode"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                zipcode: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.zipcode !== undefined &&
                                            newElement.zipcode
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="city">Ville</label>

                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                city: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.city !== undefined &&
                                            newElement.city
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="country">Pays</label>

                                    <input
                                        type="text"
                                        name="country"
                                        id="country"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                country: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.country !== undefined &&
                                            newElement.country
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="gps">
                                        GPS (format : lat, long)
                                    </label>

                                    <input
                                        type="text"
                                        name="gps"
                                        id="gps"
                                        onChange={(e) => {
                                            setNewElement({
                                                ...newElement,
                                                gps: e.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            newElement !== null &&
                                            newElement.gps !== undefined &&
                                            newElement.gps
                                        }
                                    />
                                </div>
                                <div className="form-group-map">
                                    <label htmlFor="google">
                                        Lien google maps
                                    </label>

                                    <input
                                        type="text"
                                        name="google"
                                        id="google"
                                        onChange={(e) => {
                                            document.getElementById(
                                                "gps"
                                            ).value =
                                                e.target.value
                                                    .split("/@")[1]
                                                    .split(",")[0] +
                                                ", " +
                                                e.target.value
                                                    .split("/@")[1]
                                                    .split(",")[1];
                                            setNewElement({
                                                ...newElement,
                                                gps:
                                                    e.target.value
                                                        .split("/@")[1]
                                                        .split(",")[0] +
                                                    ", " +
                                                    e.target.value
                                                        .split("/@")[1]
                                                        .split(",")[1],
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <small>
                                        Saisir le lien googlemap pour conversion
                                        en Coordonnées GPS
                                    </small>
                                </div>
                            </div>
                            <div
                                className="fixed-save"
                                onClick={() => {
                                    newElement.id !== 0
                                        ? dispatch(
                                              putElementMap(
                                                  newElement,
                                                  accessToken
                                              )
                                          )
                                        : dispatch(
                                              createElementMap(
                                                  newElement,
                                                  accessToken
                                              )
                                          );
                                    setNewElement({
                                        address: "",
                                        category: "",
                                        city: "",
                                        country: "France",
                                        description: "",
                                        gps: "",
                                        id: 0,
                                        mail: "",
                                        model_cycle: "",
                                        phone: "",
                                        text_link_web: "",
                                        title: "",
                                        url_icon_map: "",
                                        url_img_cycle: "",
                                        url_img_header: "",
                                        url_link_website: "",
                                        visible: true,
                                        zipcode: "",
                                    });
                                    setTimeout(() => {
                                        dispatch(showElementModal(newElement));
                                        setOpenAdd(false);
                                    }, 1000);
                                    setTimeout(() => {
                                        dispatch(getDataMap());
                                    }, 1500);
                                }}
                            >
                                <RiSave3Fill /> Sauvegarder
                            </div>
                        </div>
                    )}
                    <div className="filter-categorie">
                        <input
                            onChange={(e) => setFilterCat(e.target.value)}
                            type="radio"
                            id="radiocatall"
                            name="filtercat"
                            value={""}
                            defaultChecked={true}
                        />

                        <label htmlFor="radiocatall">Tout</label>
                        {categories !== null &&
                            categories !== undefined &&
                            categories.length > 0 &&
                            categories.map((categorie) => (
                                <div
                                    className="fr-checkbox-group"
                                    key={"cat" + categorie.id}
                                >
                                    <input
                                        onChange={(e) =>
                                            setFilterCat(e.target.value)
                                        }
                                        type="radio"
                                        id={"radiocat" + categorie.id}
                                        name="filtercat"
                                        value={categorie.id}
                                        className="mouseover"
                                    />

                                    <label htmlFor={"cat" + categorie.id}>
                                        {categorie.name}
                                    </label>
                                </div>
                            ))}
                    </div>
                    <div>
                        <label>
                            Mot :{" "}
                            <input
                                type="search"
                                onChange={(e) => setSearchWord(e.target.value)}
                                className="searchWord"
                                name="searchWord"
                                id="searchWord"
                            />
                        </label>{" "}
                        <small>
                            Recherche dans Titre, description, modèle, sku, code
                            postal, villes, pays
                        </small>
                    </div>
                    <hr className="w-50 my-2" />
                    <div id="table_map">
                        {elements !== null &&
                            elements !== undefined &&
                            elements.length > 0 && (
                                <div>
                                    total affichés : {elementFiltred} sur{" "}
                                    {elements.length} éléments
                                </div>
                            )}

                        <table>
                            <thead className="td-header">
                                <tr>
                                    <td>Action</td>
                                    <td>Catégories</td>
                                    <td>Visible</td>
                                    <td>Titre</td>
                                    <td>Description</td>
                                    <td>Phone</td>
                                    <td>Mail</td>
                                    <td>Modèle Vélo</td>
                                    <td>SKU</td>
                                    <td>Texte Lien Web</td>
                                    <td>URL Img Principale</td>
                                    <td>URL Icone Map</td>
                                    <td>Adresse</td>
                                    <td>Code Postal</td>
                                    <td>Ville</td>
                                    <td>Pays</td>
                                    <td>Coordonnées GPS</td>
                                </tr>
                            </thead>
                            <tbody>
                                {elements !== null &&
                                    elements !== undefined &&
                                    elements.length > 0 &&
                                    elements.map((element) => (
                                        <>
                                            {(element.category.includes(
                                                filterCat
                                            ) === true ||
                                                filterCat === "") && (
                                                <>
                                                    {(element.title
                                                        .toLowerCase()
                                                        .includes(
                                                            searchWord.toLowerCase()
                                                        ) === true ||
                                                        element.description
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.mail
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.model_cycle
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.model_cycle
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.url_img_cycle
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.zipcode
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.city
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true ||
                                                        element.country
                                                            .toLowerCase()
                                                            .includes(
                                                                searchWord.toLowerCase()
                                                            ) === true) && (
                                                        <tr
                                                            key={element.id}
                                                            className="elementMap"
                                                        >
                                                            <td>
                                                                <div
                                                                    className="edit"
                                                                    onClick={() => {
                                                                        setOpenAdd(
                                                                            true
                                                                        );

                                                                        dispatch(
                                                                            showElementModal(
                                                                                element
                                                                            )
                                                                        );
                                                                        handleClickScrollModal();
                                                                    }}
                                                                >
                                                                    <BiEdit />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {categories !==
                                                                    null &&
                                                                    categories !==
                                                                        undefined &&
                                                                    categories.length >
                                                                        0 &&
                                                                    categories.map(
                                                                        (
                                                                            cat
                                                                        ) => (
                                                                            <>
                                                                                {parseFloat(
                                                                                    element.category
                                                                                ) ===
                                                                                    parseFloat(
                                                                                        cat.id
                                                                                    ) &&
                                                                                    cat.name}
                                                                            </>
                                                                        )
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {element.visible ===
                                                                true
                                                                    ? "oui"
                                                                    : "non"}
                                                            </td>
                                                            <td>
                                                                {element.title}
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.description
                                                                }
                                                            </td>
                                                            <td>
                                                                {element.phone}
                                                            </td>
                                                            <td>
                                                                {element.mail !==
                                                                    "" &&
                                                                    element.mail !==
                                                                        null && (
                                                                        <>
                                                                            {
                                                                                element.mail.split(
                                                                                    "@"
                                                                                )[0]
                                                                            }{" "}
                                                                            <br />{" "}
                                                                            @
                                                                            {
                                                                                element.mail.split(
                                                                                    "@"
                                                                                )[1]
                                                                            }
                                                                        </>
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.model_cycle
                                                                }
                                                            </td>
                                                            <td>
                                                                {element.url_img_cycle
                                                                    .split(",")
                                                                    .map(
                                                                        (
                                                                            element
                                                                        ) => (
                                                                            <>
                                                                                {
                                                                                    <>
                                                                                        <small>
                                                                                            {
                                                                                                element.split(
                                                                                                    "/"
                                                                                                )[
                                                                                                    element.split(
                                                                                                        "/"
                                                                                                    )
                                                                                                        .length -
                                                                                                        1
                                                                                                ]
                                                                                            }
                                                                                        </small>{" "}
                                                                                        <br />
                                                                                        <img
                                                                                            src={
                                                                                                element
                                                                                            }
                                                                                            alt=""
                                                                                            width={
                                                                                                "50px"
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        )
                                                                    )}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={
                                                                        element.url_link_website
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {
                                                                        element.text_link_web
                                                                    }
                                                                </a>
                                                            </td>

                                                            <td>
                                                                {element.url_img_header !==
                                                                    "none" && (
                                                                    <img
                                                                        className="img-map-gestion"
                                                                        src={
                                                                            element.url_img_header
                                                                        }
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                {element.url_icon_map !==
                                                                    "none" && (
                                                                    <img
                                                                        src={
                                                                            element.url_icon_map
                                                                        }
                                                                        alt=""
                                                                        width={
                                                                            "30px"
                                                                        }
                                                                        height={
                                                                            "30px"
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.address
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.zipcode
                                                                }
                                                            </td>
                                                            <td>
                                                                {element.city}
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.country
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    element.gps.split(
                                                                        ","
                                                                    )[0]
                                                                }
                                                                <br />
                                                                {
                                                                    element.gps.split(
                                                                        ","
                                                                    )[1]
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ))}
                            </tbody>
                        </table>
                    </div>{" "}
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}{" "}
        </main>
    );
}
