/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../Redux/actions/stats.actions";
import GraphRepartOrderLocation from "./graphRepartOrderLocation";

import { useEffect, useState } from "react";
import "../../Pages/sav.css";
import "../../Pages/stats.css";
import { toast } from "react-toastify";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { BiArrowToTop, BiArrowFromTop } from "react-icons/bi";
import { BiLogoShopify } from "react-icons/bi";
import { MdLocalShipping } from "react-icons/md";
import DateSelected from "./DateSelected";

export default function EtatCommandes() {
    document.title = "VELOMAD - Stats - Etats des Commandes";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);

    const dispatch = useDispatch();
    const locations = useSelector((state) => state.Stats.locations);
    const orders_date = useSelector((state) => state.Stats.orders_date);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [sumAmountTTC, setSumAmountTTC] = useState(0.0);
    const [sumAmountTVA, setSumAmountTVA] = useState(0.0);
    const [sumAmountDiscount, setSumAmountDiscount] = useState(0.0);
    const [sumAmountDu, setSumAmountDu] = useState(0.0);
    const [sumRefund, setSumRefund] = useState(0.0);
    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);
    const [ordersListPdf, setOrdersListPdf] = useState([]);
    const [ordersListPdfChunck, setOrdersListPdfChunck] = useState(undefined);

    useEffect(() => {
        dispatch(getLocations(accessToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClickScrollToTotaux = () => {
        setTimeout(() => {
            const element = document.getElementById("td-footer");

            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    const handleClickScrollToStart = () => {
        setTimeout(() => {
            const element = document.getElementsByTagName("h1")[0];
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };
    function add(accumulator, a) {
        return accumulator + a;
    }
    useEffect(() => {
        setTimeout(() => {
            const allWithClassAmountTTC = Array.from(
                document.getElementsByClassName("AmountTTC")
            );
            const allWithClassAmountTVA = Array.from(
                document.getElementsByClassName("AmountTVA")
            );
            const allWithClassAmountDiscount = Array.from(
                document.getElementsByClassName("AmountDiscount")
            );
            const allWithClassAmountDu = Array.from(
                document.getElementsByClassName("AmountDu")
            );
            const allWithClassRefund = Array.from(
                document.getElementsByClassName("Refund")
            );
            const sumAmountTTC = [];
            const sumAmountTVA = [];
            const sumAmountDiscount = [];
            const sumAmountDu = [];
            const sumRefund = [];
            if (allWithClassAmountTTC.length > 0) {
                allWithClassAmountTTC.map((element) => {
                    if (!isNaN(element.innerText)) {
                        sumAmountTTC.push(parseFloat(element.innerText));
                    }
                });
            }
            if (allWithClassAmountTVA.length > 0) {
                allWithClassAmountTVA.map((element) => {
                    if (!isNaN(element.innerText)) {
                        sumAmountTVA.push(parseFloat(element.innerText));
                    }
                });
            }
            if (allWithClassAmountDiscount.length > 0) {
                allWithClassAmountDiscount.map((element) => {
                    if (!isNaN(element.innerText)) {
                        sumAmountDiscount.push(parseFloat(element.innerText));
                    }
                });
            }
            if (allWithClassAmountDu.length > 0) {
                allWithClassAmountDu.map((element) => {
                    if (!isNaN(element.innerText)) {
                        sumAmountDu.push(parseFloat(element.innerText));
                    }
                });
            }
            if (allWithClassRefund.length > 0) {
                allWithClassRefund.map((element) => {
                    if (!isNaN(element.innerText)) {
                        sumRefund.push(parseFloat(element.innerText));
                    }
                });
            }
            setSumAmountTTC(sumAmountTTC.reduce(add, 0));
            setSumAmountTVA(sumAmountTVA.reduce(add, 0));
            setSumAmountDiscount(sumAmountDiscount.reduce(add, 0));
            setSumAmountDu(sumAmountDu.reduce(add, 0));
            setSumRefund(sumRefund.reduce(add, 0));
        }, 1000);
    }, [orders_date]);
    useEffect(
        () => {
            if (
                orders_date !== undefined &&
                orders_date !== null &&
                orders_date.length >= 0
            ) {
                const newArray = [];
                orders_date.map((order) => {
                    newArray.push(order.id);
                });
                setOrdersListPdf(newArray);
                let chunks = [];
                for (let i = 0; i < ordersListPdf.length; i += 50) {
                    chunks.push(ordersListPdf.slice(i, i + 50));
                }
                setOrdersListPdfChunck(chunks);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orders_date]
    );

    useEffect(
        () => {
            if (
                ordersListPdf !== undefined &&
                ordersListPdf !== null &&
                ordersListPdf.length >= 0
            ) {
                let chunks = [];
                for (let i = 0; i < ordersListPdf.length; i += 50) {
                    chunks.push(ordersListPdf.slice(i, i + 50));
                }
                setOrdersListPdfChunck(chunks);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordersListPdf]
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        let table = undefined;
        if (e.target.innerText.includes("Produits")) {
            table = document.getElementById("my-products");
        } else if (e.target.innerText.includes("Commandes")) {
            table = document.getElementById("my-orders");
        }

        async function PostCreateSav(formData) {
            setUrlCsvOrdersDate(undefined);
            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvOrdersDate(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        if (table !== undefined && table !== null) {
            const thead = Array.from(table.tHead.rows[0].children).map(
                (el) => el.textContent
            );

            const tbody = Array.from(table.tBodies[0].rows).map((row) =>
                Array.from(row.cells).map((cell) => cell.textContent)
            );

            const obj = {
                table: [thead].concat(tbody),
            };

            const formData = new FormData();

            formData.append("type", "export_orders");
            formData.append("table", JSON.stringify(obj));

            if (e.target.innerText.includes("Produits")) {
                formData.append(
                    "Title",
                    "Ventes_produits_DU_" + dateStart + "_AU_" + dateEnd
                );
            } else if (e.target.innerText.includes("Commandes")) {
                formData.append(
                    "Title",
                    "Etat_Commandes_DU_" + dateStart + "_AU_" + dateEnd
                );
            }
            PostCreateSav(formData);
        }
    };
    return (
        <main>
            <h1 id="selectCmde">Liste des Commandes</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    <DateSelected />
                    {orders_date !== null &&
                        orders_date !== undefined &&
                        orders_date.length >= 0 &&
                        locations !== null &&
                        locations !== undefined && (
                            <GraphRepartOrderLocation
                                orders={orders_date}
                                sumAmountTTC={sumAmountTTC}
                                table={true}
                            />
                        )}
                    {orders_date !== null &&
                        orders_date !== undefined &&
                        orders_date.length >= 0 &&
                        locations !== null &&
                        locations !== undefined && (
                            <>
                                <div className="d-flex-row">
                                    <div className="f-column mx-auto">
                                        {ordersListPdf !== undefined &&
                                            ordersListPdf !== null &&
                                            ordersListPdf.length >= 0 &&
                                            ordersListPdfChunck !== undefined &&
                                            ordersListPdfChunck.length > 0 && (
                                                <>
                                                    {ordersListPdfChunck.map(
                                                        (element, index) => (
                                                            <>
                                                                {
                                                                    <div
                                                                        key={
                                                                            "exportlot" +
                                                                            index
                                                                        }
                                                                    >
                                                                        <a
                                                                            href={
                                                                                "https://admin.shopify.com/store/velomad/apps/order-printer-emailer/export?from_modal=true&ids=" +
                                                                                element.join(
                                                                                    ","
                                                                                ) +
                                                                                "&order_type=orders&template_ids=563174"
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <button className="btn-orderpro">
                                                                                Export
                                                                                Factures{" "}
                                                                                <BsFiletypePdf />
                                                                                lot{" "}
                                                                                {index +
                                                                                    1}
                                                                            </button>
                                                                        </a>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        <div
                                            className="button"
                                            onClick={() =>
                                                handleClickScrollToTotaux()
                                            }
                                        >
                                            <BiArrowFromTop /> Voir les totaux
                                            et reste dûs
                                        </div>
                                        <button
                                            onClick={(e) => handleSubmit(e)}
                                            className="btn "
                                        >
                                            Demande d'export Commandes CSV
                                        </button>
                                        {urlCsvOrdersDate !== undefined && (
                                            <a
                                                href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button className="btn-export-csv ">
                                                    Obtenir le CSV d'export{" "}
                                                    <BsFiletypePdf />{" "}
                                                    <BsBoxArrowUpRight />
                                                </button>
                                            </a>
                                        )}
                                    </div>
                                    <div className="f-column mx-auto">
                                        <p>
                                            {" "}
                                            Chiffre d'affaire TTC Généré :{" "}
                                            {Number(sumAmountTTC).toFixed(2)}
                                        </p>
                                        <p>
                                            Chiffre d'affaire HT Généré :{" "}
                                            {Number(
                                                (
                                                    Number(
                                                        sumAmountTTC
                                                    ).toFixed(2) -
                                                    Number(
                                                        sumAmountTVA
                                                    ).toFixed(2)
                                                ).toFixed(2)
                                            ).toFixed(2)}
                                        </p>{" "}
                                        <p>
                                            Chiffre d'affaire TTC Encaissé :{" "}
                                            {Number(
                                                Number(sumAmountTTC).toFixed(
                                                    2
                                                ) -
                                                    Number(sumAmountDu).toFixed(
                                                        2
                                                    )
                                            ).toFixed(2)}
                                        </p>{" "}
                                        <p>
                                            Reste Dû Client TTC :{" "}
                                            {Number(sumAmountDu).toFixed(2)}{" "}
                                        </p>
                                    </div>
                                </div>

                                <table id="my-orders">
                                    <thead>
                                        <tr id="td-header">
                                            <td>N°Commande</td>
                                            <td>Créée le </td>
                                            <td>MAJ le</td>
                                            <td>PDV</td>
                                            <td>TAG</td>
                                            <td>Nom # Prenom </td>
                                            <td>Mail</td>
                                            <td>Payée ?</td>
                                            <td>Expédiée ?</td>
                                            <td>Terminée ?</td>
                                            <td>Montant Total TTC</td>
                                            <td>TVA</td>
                                            <td>dont Remise</td>
                                            <td>Reste Dû TTC</td>
                                            <td>Moyen Paiement</td>
                                            <td>Remboursement TTC</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders_date !== null &&
                                            orders_date !== undefined &&
                                            orders_date.length >= 0 && (
                                                <>
                                                    {orders_date.map(
                                                        (order) => (
                                                            <tr
                                                                key={
                                                                    "commande" +
                                                                    order.id
                                                                }
                                                            >
                                                                <td>
                                                                    {order.name}{" "}
                                                                    <br />
                                                                    <span className="d-row-space">
                                                                        <a
                                                                            href={
                                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                                order.id
                                                                            }
                                                                            rel="noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            <BiLogoShopify className="text-shopify" />
                                                                        </a>
                                                                        <a
                                                                            href={
                                                                                "https://admin.shopify.com/store/velomad/apps/order-printer-emailer/print/orders?id=" +
                                                                                order.id
                                                                            }
                                                                            rel="noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            <BsFiletypePdf />
                                                                        </a>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {order.created_at
                                                                        .split(
                                                                            "+"
                                                                        )[0]
                                                                        .replace(
                                                                            "T",
                                                                            " "
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {order.updated_at
                                                                        .split(
                                                                            "+"
                                                                        )[0]
                                                                        .replace(
                                                                            "T",
                                                                            " "
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {locations !==
                                                                        undefined &&
                                                                    locations !==
                                                                        null ? (
                                                                        <>
                                                                            {locations.map(
                                                                                (
                                                                                    location
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            location.name +
                                                                                            location.id
                                                                                        }
                                                                                    >
                                                                                        {order.location_id ===
                                                                                            location.id && (
                                                                                            <span>
                                                                                                {
                                                                                                    location.name
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                order.source_name
                                                                            }
                                                                        </>
                                                                    )}
                                                                    {order.location_id ===
                                                                        null && (
                                                                        <>
                                                                            {
                                                                                order.source_name
                                                                            }
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {order.tags}
                                                                </td>
                                                                <td>
                                                                    {order !==
                                                                        null &&
                                                                        order.customer !==
                                                                            null && (
                                                                            <a
                                                                                href={
                                                                                    "https://admin.shopify.com/store/velomad/customers/" +
                                                                                    order
                                                                                        .customer
                                                                                        .id
                                                                                }
                                                                                rel="noreferrer"
                                                                                target="_blank"
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    order
                                                                                        .customer
                                                                                        .last_name
                                                                                }{" "}
                                                                                #{" "}
                                                                                {
                                                                                    order
                                                                                        .customer
                                                                                        .first_name
                                                                                }
                                                                            </a>
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        order.email
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        order.financial_status
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {order.fulfillment_status ===
                                                                    "fulfilled" ? (
                                                                        <>
                                                                            expédiée
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            à
                                                                            traiter
                                                                            <MdLocalShipping className="text-red" />
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {order.closed_at ===
                                                                    null
                                                                        ? "pending"
                                                                        : "closed"}
                                                                </td>
                                                                <td className="AmountTTC">
                                                                    {order.cancel_reason ===
                                                                        null &&
                                                                    order.financial_status !==
                                                                        "refunded" &&
                                                                    order.financial_status !==
                                                                        "avoid"
                                                                        ? order.current_total_price
                                                                        : 0}
                                                                </td>
                                                                <td className="AmountTVA">
                                                                    {order.cancel_reason ===
                                                                        null &&
                                                                    order.financial_status !==
                                                                        "refunded" &&
                                                                    order.financial_status !==
                                                                        "avoid"
                                                                        ? order.current_total_tax
                                                                        : 0}
                                                                </td>
                                                                <td className="AmountDiscount">
                                                                    {order.cancel_reason ===
                                                                        null &&
                                                                    order.financial_status !==
                                                                        "refunded" &&
                                                                    order.financial_status !==
                                                                        "avoid"
                                                                        ? order.total_discounts
                                                                        : 0}
                                                                </td>
                                                                <td className="AmountDu">
                                                                    {order.cancel_reason ===
                                                                        null &&
                                                                    order.cancelled_at ===
                                                                        null &&
                                                                    order.financial_status !==
                                                                        "refunded" &&
                                                                    order.financial_status !==
                                                                        "avoid" &&
                                                                    parseFloat(
                                                                        order.total_outstanding
                                                                    ) > 0 ? (
                                                                        <span className="bg-error">
                                                                            {
                                                                                order.total_outstanding
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        0.0
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        order.payment_gateway_names
                                                                    }
                                                                </td>
                                                                <td className="Refund">
                                                                    {order.cancelled_at !==
                                                                        null ||
                                                                    order.financial_status ===
                                                                        "refunded" ||
                                                                    order.financial_status ===
                                                                        "avoid" ? (
                                                                        <span className="bg-error">
                                                                            {order
                                                                                .refunds
                                                                                .length >
                                                                                0 &&
                                                                                order
                                                                                    .refunds[0]
                                                                                    .transactions
                                                                                    .length >
                                                                                    0 &&
                                                                                order
                                                                                    .refunds[0]
                                                                                    .transactions[0]
                                                                                    .amount}
                                                                        </span>
                                                                    ) : (
                                                                        0
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        <tr id="td-footer">
                                            <td>{orders_date.length}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                {Number(sumAmountTTC).toFixed(
                                                    2
                                                )}
                                            </td>
                                            <td>
                                                {Number(sumAmountTVA).toFixed(
                                                    2
                                                )}
                                            </td>
                                            <td>
                                                {Number(
                                                    sumAmountDiscount
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                {Number(sumAmountDu).toFixed(2)}
                                            </td>
                                            <td></td>
                                            <td>
                                                {Number(sumRefund).toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div
                                    className="button mb-4"
                                    onClick={() => handleClickScrollToStart()}
                                >
                                    <BiArrowToTop /> Retour en Haut
                                </div>
                            </>
                        )}{" "}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
