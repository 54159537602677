/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";

import {
    getCollectionVelos,
    getLocations,
    dicoProductsVelo,
} from "../../Redux/actions/stats.actions";
import DateSelected from "./DateSelected";
import GraphPieChartVelosType from "./graphPieChartVelosType";
import TableVelo from "./tableVelo";

export default function EtatVelos() {
    document.title = "VELOMAD - Stats ";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.user.accessToken);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const orders = useSelector((state) => state.Stats.orders_date);

    const newDicoProductsVelo = useSelector(
        (state) => state.Stats.dicoProductsVelo
    ); // [sku: {quantity: 1, name: "velo", sku: "velo", product: 123, orderName: ["5509126947097#SHOPI1390#quantity:1#location:88647401753"]}

    const dataCollectionVelos = useSelector(
        (state) => state.Stats.collection_velos
    );
    //const locations = useSelector((state) => state.Stats.locations);
    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);

    const handleSubmit = (e) => {
        e.preventDefault();

        document.getElementById("watchOrderName").checked = true;
        let table = undefined;
        if (e.target.innerText.includes("Vélos")) {
            table = document.getElementById("my-velos");
        }
        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        if (e.target.innerText.includes("Vélos")) {
            formData.append(
                "Title",
                "Ventes_Velos_DU_" + dateStart + "_AU_" + dateEnd
            );
        }

        async function PostCreateCSV(formData) {
            if (e.target.innerText.includes("Vélos")) {
                setUrlCsvOrdersDate(undefined);
            }

            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    if (e.target.innerText.includes("Vélos")) {
                        setUrlCsvOrdersDate(data);
                    }
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateCSV(formData);
    };

    useEffect(() => {
        dispatch(getCollectionVelos());
        dispatch(getLocations(accessToken));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (orders !== null && orders !== undefined && orders.length > 0) {
            dispatch(dicoProductsVelo(dataCollectionVelos, orders));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, dataCollectionVelos]);

    return (
        <main>
            <h1 id="selectVelos">Liste des Velos Vendus</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    <DateSelected />
                    {orders !== null &&
                        orders !== undefined &&
                        orders.length >= 0 && (
                            <>
                                <div className="text-center f-column">
                                    <button
                                        className="btn"
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Demande d'export Vélos
                                    </button>
                                    {urlCsvOrdersDate !== undefined && (
                                        <a
                                            href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <button className="btn-export-csv ">
                                                Obtenir le CSV d'export{" "}
                                                <BsFiletypePdf />{" "}
                                                <BsBoxArrowUpRight />
                                            </button>
                                        </a>
                                    )}
                                </div>

                                <TableVelo />

                                {newDicoProductsVelo !== undefined &&
                                    newDicoProductsVelo !== null && (
                                        <GraphPieChartVelosType
                                            data={newDicoProductsVelo}
                                        />
                                    )}
                                <div className="mb-4"></div>
                            </>
                        )}{" "}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
