import { useDispatch, useSelector } from "react-redux";
import "./maps.css";
import "./sav.css";
import "./reprise.css";
import VeloSelected from "../Components/Sav/VeloSelected";
import Coordonnees from "../Components/User/Coordonnees";
import VeloToChoice from "../Components/Sav/VeloToChoice";
import {
    erasePieceSAV,
    getPricesRepriseVelos,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../Redux/actions/velo.actions";
import AboutVeloReprise from "../Components/User/AboutVeloReprise";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";
import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";
import mascotte from "../Assets/mascotte_velomad.png";
import { useEffect } from "react";

export default function PageReprise(props) {
    document.title = "Découvrez notre formulaire de reprise";
    document.description =
        "Nous reprenons votre Velomad, remplissez le formulaire pour avoir un retour de la part de nos équipes.";
    const openNavBurger = useSelector((state) => state.style.openNavBurger);

    const parcoursSavTitle = useSelector(
        (state) => state.veloDetails.parcoursSavTitle
    );
    const parcoursSavStep = useSelector(
        (state) => state.veloDetails.parcoursSavStep
    );
    const dispatch = useDispatch();
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );

    useEffect(() => {
        dispatch(getPricesRepriseVelos());
    }, [dispatch]);

    return (
        <>
            {openNavBurger === false && (
                <>
                    <main className="main-reprise ">
                        <div className="banner-sav banner-reprise"></div>
                        <div className="banner-title">
                            <h1>Demande de Reprise</h1>
                        </div>
                        <div className="found-map-title text-center">
                            <img
                                src={mascotte}
                                alt="Mascotte VeloMad"
                                className="mascotte-velomad"
                            />
                            <p>
                                Complétez ce formulaire pour recevoir une offre
                                de reprise, ça ne vous prendra pas plus de 5min
                                !<br /> Vous aurez besoin de votre facture
                                d’achat, une photo du kilométrage de votre vélo
                                et des photos de votre vélo.{" "}
                            </p>
                            <p>
                                {" "}
                                Nous reviendrons vers vous dans les 48h avec une
                                offre de rachat.
                            </p>
                        </div>

                        <div className="parcours dimension-main ">
                            <div>
                                <h2>
                                    {parcoursSavTitle !== null &&
                                        parcoursSavTitle}
                                </h2>
                            </div>
                            <div className="parcours_line">
                                <div
                                    className={
                                        parcoursSavStep === 1
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dispatch(
                                            setParcoursSavTitle(
                                                "Choisissez votre gamme"
                                            )
                                        );
                                        dispatch(setParcoursSavStep(1));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    1. Gamme
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 2
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Choisissez votre version"
                                                )
                                            );
                                        dispatch(setParcoursSavStep(2));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    2. Modèle
                                </div>
                                <div
                                    className={
                                        parcoursSavStep === 3
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Etat du Velomad"
                                                )
                                            );
                                        dispatch(setParcoursSavStep(3));
                                        dispatch(erasePieceSAV());
                                    }}
                                >
                                    3. Etat
                                </div>

                                <div
                                    className={
                                        parcoursSavStep === 6
                                            ? "parcoursSelected"
                                            : "parcoursElement"
                                    }
                                    onClick={() => {
                                        dataVeloSelect !== null &&
                                            dispatch(
                                                setParcoursSavTitle(
                                                    "Vos Coordonnées"
                                                )
                                            );
                                        dispatch(erasePieceSAV());
                                        dispatch(setParcoursSavStep(6));
                                    }}
                                >
                                    4. Coordonnées
                                </div>
                            </div>
                        </div>
                        <div className="dimension-main ">
                            {parcoursSavStep === 1 && <VeloToChoice />}

                            {parcoursSavStep === 2 && <VeloSelected />}

                            {parcoursSavStep === 3 && (
                                <AboutVeloReprise type="reprise" />
                            )}

                            {parcoursSavStep === 4 && (
                                <Coordonnees type={props.type} />
                            )}
                        </div>
                    </main>
                    <SingeFooterShopifyVelomad />
                    <FooterShopifyVelomad />
                </>
            )}
        </>
    );
}
