import LoadingK from "../LoadingK";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import OrderDetailPresta from "./OrderDetailPresta";
import AddressPresta from "./AddressPresta";
import GenerateInvoicePresta from "./GenerateInvoice";
import { setIdCustomer } from "../../Redux/actions/admin.actions";

import { SiPrestashop } from "react-icons/si";
import { useSelector } from "react-redux";

export default function SearchPresta() {
    const accessToken = useSelector((store) => store.user.accessToken);
    const dispatch = useDispatch();
    const [resultCustomerPresta, setResultCustomerPresta] = useState(undefined);
    const [idCustomerPresta, setIdCustomerPresta] = useState(undefined);
    const [resultOrdersCustomerPresta, setResultOrdersCustomerPresta] =
        useState(undefined);
    const mailCustomerSearch = useSelector(
        (state) => state.AdminStaff.mailCustomerSearch
    );

    const nameCustomerSearch = useSelector(
        (state) => state.AdminStaff.nameCustomerSearch
    );
    useEffect(() => {
        if (mailCustomerSearch !== null && mailCustomerSearch !== undefined) {
            SearchCustomerMailPresta();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailCustomerSearch]);

    useEffect(() => {
        if (nameCustomerSearch !== null && nameCustomerSearch !== undefined) {
            SearchCustomerNamePresta();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameCustomerSearch]);
    async function SearchCustomerMailPresta() {
        try {
            setResultCustomerPresta(null);
            setResultOrdersCustomerPresta(undefined);
            setIdCustomerPresta(undefined);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_mail/prestashop/${mailCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerPresta(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerPresta(undefined);
                toast.error(
                    "Panne de recherche dans la base de données Prestashop",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande Prestashop😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    async function SearchCustomerNamePresta() {
        try {
            setResultCustomerPresta(null);
            setResultOrdersCustomerPresta(undefined);
            setIdCustomerPresta(undefined);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_customer_by_name/prestashop/${nameCustomerSearch}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultCustomerPresta(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultCustomerPresta(undefined);
                toast.error(
                    "Panne de recherche dans la base de données Prestashop",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        } catch {
            toast.error("Oups! Impossible d'envoyer la demande Prestashop😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    async function SearchOrdersCustomerPresta() {
        try {
            setResultOrdersCustomerPresta(null);
            const response = await fetch(
                `https://api.levelomad.com/api/erp/v1/search_orders_curstomer/prestashop/${idCustomerPresta}/`,
                {
                    method: "get",
                    headers: new Headers({
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setResultOrdersCustomerPresta(data);
            } else if (response.status === 500) {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 401) {
                toast.error(
                    "Votre session est périmée, merci de vous relogguer...",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 500) {
                setResultOrdersCustomerPresta(undefined);
                toast.error(
                    "Panne de Connexion en direct avec API Prestashop Commande",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        } catch {
            toast.error(
                "Oups! Impossible d'envoyer la demande Prestashop liste des commandes du clients 😔",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    }

    const handleClickScrollOrders = () => {
        setTimeout(() => {
            const element = document.getElementsByClassName("orders")[0];
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };

    useEffect(() => {
        if (idCustomerPresta !== null && idCustomerPresta !== undefined) {
            SearchOrdersCustomerPresta();
            dispatch(setIdCustomer(idCustomerPresta));
            handleClickScrollOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCustomerPresta, setIdCustomerPresta]);

    return (
        <>
            {resultCustomerPresta !== undefined && (
                <div className="mb-4">
                    <div className="title-result mx-auto">
                        Résultat Prestashop
                        <SiPrestashop className="icon-presta" /> :
                    </div>
                    {resultCustomerPresta === null ? (
                        <div className="mx-auto">
                            <LoadingK />
                        </div>
                    ) : (
                        <div>
                            {resultCustomerPresta !== undefined &&
                            resultCustomerPresta.length === 0 ? (
                                <div className="text-center">
                                    Aucun Résultat
                                </div>
                            ) : (
                                <>
                                    <div className="text-center">
                                        <small>
                                            Merci de cliquer sur le profil
                                            Prestashop à extraire
                                        </small>
                                    </div>
                                    {resultCustomerPresta !== undefined &&
                                    resultCustomerPresta !== null &&
                                    resultCustomerPresta.length >= 1 ? (
                                        <>
                                            {resultCustomerPresta.map(
                                                (element) => (
                                                    <div
                                                        className={
                                                            element.id_customer ===
                                                            idCustomerPresta
                                                                ? "result-presta-selected"
                                                                : "result-presta"
                                                        }
                                                        key={
                                                            "presta" +
                                                            element.id_customer
                                                        }
                                                        onClick={(e) => {
                                                            setIdCustomerPresta(
                                                                element.id_customer
                                                            );
                                                        }}
                                                    >
                                                        <span>
                                                            Client N°{" "}
                                                            {
                                                                element.id_customer
                                                            }
                                                        </span>
                                                        <span>
                                                            Nom :{" "}
                                                            {element.lastname}
                                                            {" - Prénom : "}
                                                            {
                                                                element.firstname
                                                            }{" "}
                                                            <br />
                                                            {element.email}
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Client depuis :{" "}
                                                            {
                                                                element.date_add.split(
                                                                    "T"
                                                                )[0]
                                                            }
                                                        </span>
                                                        <span>
                                                            Dernière Connexion :{" "}
                                                            {
                                                                element.date_upd.split(
                                                                    "T"
                                                                )[0]
                                                            }
                                                        </span>
                                                        {element.note !==
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span>
                                                                    Note :{" "}
                                                                    {
                                                                        element.note
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {element.siret !==
                                                            null ||
                                                            (element.company !==
                                                                null && (
                                                                <>
                                                                    <br />
                                                                    <span>
                                                                        Siret :{" "}
                                                                        {
                                                                            element.siret
                                                                        }{" "}
                                                                        -
                                                                        Company
                                                                        :{" "}
                                                                        {
                                                                            element.company
                                                                        }
                                                                    </span>
                                                                </>
                                                            ))}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        "aie"
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            {resultOrdersCustomerPresta === null ? (
                <div className="mx-auto">
                    <LoadingK />
                </div>
            ) : (
                <div>
                    {resultOrdersCustomerPresta !== undefined &&
                    resultOrdersCustomerPresta.length === 0 ? (
                        <div className="orders text-center">
                            Aucune Commande Prestashop
                        </div>
                    ) : (
                        <>
                            {resultOrdersCustomerPresta !== undefined &&
                                resultOrdersCustomerPresta.length > 0 &&
                                resultOrdersCustomerPresta.map((cmde) => (
                                    <div
                                        className="orders"
                                        key={"prestaOrder" + cmde.id}
                                    >
                                        <h3>
                                            Commande N°{cmde.id_order}{" "}
                                            <SiPrestashop className="icon-presta" />{" "}
                                            Ref :{cmde.reference}
                                        </h3>
                                        <div className="order-details">
                                            <div className="order-details-div">
                                                <h3>Détails Commande </h3>
                                                <div>
                                                    <div>
                                                        Commande Confirmée ?{" "}
                                                        {cmde.valid === 1 ? (
                                                            <span>Oui</span>
                                                        ) : (
                                                            <span>Non</span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        Créée le :{" "}
                                                        {cmde.date_add}
                                                    </div>
                                                    <div>
                                                        MAJ le : {cmde.date_upd}
                                                    </div>
                                                    <div>
                                                        Facturée le :{" "}
                                                        {cmde.invoice_date}
                                                    </div>
                                                    <div>
                                                        Livrée le :{" "}
                                                        {cmde.delivery_date}
                                                    </div>
                                                    <GenerateInvoicePresta
                                                        cmde={cmde}
                                                    />
                                                </div>
                                                <hr />
                                                <h3>Détails Articles </h3>

                                                <OrderDetailPresta
                                                    idOrder={cmde.id_order}
                                                />
                                                <h3>Détails Expédition </h3>
                                                <div>
                                                    Adresse de livraison N°{" "}
                                                    {cmde.id_address_delivery}
                                                    <AddressPresta
                                                        idAddress={
                                                            cmde.id_address_delivery
                                                        }
                                                    />
                                                </div>
                                            </div>{" "}
                                            <div className="order-details-div">
                                                {" "}
                                                <h3>Détails Transaction</h3>
                                                <div>
                                                    Moyen de paiement :{" "}
                                                    {cmde.payment}
                                                </div>
                                                <hr />
                                                <div>
                                                    Adresse de Facturation N°{" "}
                                                    {cmde.id_address_invoice}
                                                    <AddressPresta
                                                        idAddress={
                                                            cmde.id_address_invoice
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
