import "./veloselect.css";
import { useDispatch, useSelector } from "react-redux";
import {
    selectModele,
    setParcoursSavStep,
    setParcoursSavTitle,
    setSku,
} from "../../Redux/actions/velo.actions";
import { erasePanier } from "../../Redux/actions/panierSav.actions";
import { useEffect, useState } from "react";
import LoadingCycle from "./LoaderCycle";

export default function VeloSelected() {
    const dispatch = useDispatch();
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );

    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const selectSku = useSelector((state) => state.veloDetails.modele);
    const [dataSpecialCollectionVelos] = useState({
        YBVMWA5003: "YBVMWA5001",
        YBVMWA5004: "YBVMWA5002",
        YBVMWA5503: "YBVMWA5501",
        YBVMWA5504: "YBVMWA5502",
        YBVMWB4503: "YBVMWB4501",
        YBVMWB4504: "YBVMWB4502",
        YBVMWB5003: "YBVMWB5003",
        YBVMWB5004: "YBVMWB5002",
        YCVMWA5005: "YCVMWA5001",
        YCVMWA5505: "YCVMWA5501",
        YCVMWB4505: "YCVMWB4501",
        YCVMWB5005: "YCVMWB5001",
    });

    return (
        <>
            {dataVeloSelect !== undefined && dataVeloSelect !== null ? (
                <>
                    <div className="text-center">
                        {dataVeloSelect !== undefined &&
                            dataVeloSelect !== null &&
                            dataVeloSelect.image !== null &&
                            dataVeloSelect.image.src !== null && (
                                <img
                                    src={dataVeloSelect.image.src}
                                    alt={dataVeloSelect.title}
                                    className="velo_select-img"
                                />
                            )}
                    </div>

                    {dataVeloSelect !== undefined &&
                        dataVeloSelect["variants"] !== undefined &&
                        dataVeloSelect["variants"].length > 0 &&
                        dataVeloSelect["variants"].map((element) => (
                            <div key={"variants" + element.id}>
                                <div
                                    className={
                                        selectSku !== null &&
                                        selectSku !== undefined &&
                                        selectSku !== undefined &&
                                        selectSku.includes(element.title)
                                            ? "velo_product_modele selected"
                                            : "velo_product_modele "
                                    }
                                    onClick={() => {
                                        dispatch(selectModele(null));
                                        setTimeout(() => {
                                            dispatch(
                                                selectModele(
                                                    dataVeloSelect.title +
                                                        " # " +
                                                        element.title
                                                )
                                            );
                                            dispatch(
                                                setSku(
                                                    element.sku.split("/")[0]
                                                )
                                            );
                                        }, 500);
                                        setTimeout(() => {
                                            if (
                                                selectSku !== null &&
                                                selectSku !== undefined
                                            ) {
                                                dispatch(erasePanier());
                                                dispatch(
                                                    setParcoursSavTitle(
                                                        "Etat du Velomad"
                                                    )
                                                );
                                                dispatch(setParcoursSavStep(3));
                                            }
                                        }, 1000);
                                        setTimeout(() => {
                                            const element =
                                                document.getElementById(
                                                    "btn-next"
                                                );
                                            if (element) {
                                                // 👇 Will scroll smoothly to the top of the next section
                                                element.scrollIntoView({
                                                    behavior: "smooth",
                                                });
                                            }
                                        }, 1100);
                                    }}
                                >
                                    {element.sku !== null &&
                                        element.sku.split("/").length >= 1 && (
                                            <>{element.sku.split("/")[0]} </>
                                        )}{" "}
                                    <br /> # {element.title} # <br />
                                    {element.sku !== null &&
                                        element.sku.split("/").length >= 2 && (
                                            <small>
                                                {" - "}
                                                {element.sku.split("/")[1]}{" "}
                                            </small>
                                        )}
                                </div>
                            </div>
                        ))}

                    {dataVeloSelect !== undefined &&
                        dataVeloSelect["variants"] !== undefined &&
                        dataVeloSelect["variants"].length > 0 &&
                        dataVeloSelect["variants"].map((element) => (
                            <div key={"variants" + element.id}>
                                {dataSpecialCollectionVelos.hasOwnProperty(
                                    element.sku !== null &&
                                        element.sku.split("/").length >= 2 &&
                                        element.sku.split("/").length >= 2 &&
                                        element.sku
                                            .split("/")[0]
                                            .replace(" ", "")
                                ) && (
                                    <div
                                        className={
                                            selectSku !== null &&
                                            selectSku !== undefined &&
                                            selectSku !== undefined &&
                                            selectSku.includes(element.title)
                                                ? "velo_product_modele selected"
                                                : "velo_product_modele "
                                        }
                                        onClick={() => {
                                            dispatch(selectModele(null));
                                            setTimeout(() => {
                                                dispatch(
                                                    selectModele(
                                                        dataVeloSelect.title +
                                                            " # " +
                                                            element.title
                                                    )
                                                );
                                                dispatch(
                                                    setSku(
                                                        element.sku.split(
                                                            "/"
                                                        )[0]
                                                    )
                                                );
                                            }, 500);
                                            setTimeout(() => {
                                                if (
                                                    selectSku !== null &&
                                                    selectSku !== undefined
                                                ) {
                                                    dispatch(erasePanier());
                                                    dispatch(
                                                        setParcoursSavTitle(
                                                            "Etat du Velomad"
                                                        )
                                                    );
                                                    dispatch(
                                                        setParcoursSavStep(3)
                                                    );
                                                }
                                            }, 1000);
                                            setTimeout(() => {
                                                const element =
                                                    document.getElementById(
                                                        "btn-next"
                                                    );
                                                if (element) {
                                                    // 👇 Will scroll smoothly to the top of the next section
                                                    element.scrollIntoView({
                                                        behavior: "smooth",
                                                    });
                                                }
                                            }, 1100);
                                        }}
                                    >
                                        {
                                            dataSpecialCollectionVelos[
                                                element.sku !== null &&
                                                    element.sku.split("/")
                                                        .length >= 2 &&
                                                    element.sku.split("/")
                                                        .length >= 2 &&
                                                    element.sku
                                                        .split("/")[0]
                                                        .replace(" ", "")
                                            ]
                                        }
                                        <br /> # {element.title} #
                                        <br />
                                        {element.sku !== null &&
                                            element.sku.split("/").length >=
                                                2 && (
                                                <small>
                                                    {" - "}
                                                    {
                                                        element.sku.split(
                                                            "/"
                                                        )[1]
                                                    }{" "}
                                                </small>
                                            )}
                                    </div>
                                )}
                            </div>
                        ))}

                    {selectSku !== null && selectSku !== undefined && (
                        <div
                            className="btn-link-web margin-btn margin-btn-center"
                            id="btn-next"
                            onClick={() => {
                                dispatch(
                                    setParcoursSavTitle("Etat du Velomad")
                                );
                                dispatch(setParcoursSavStep(3));
                            }}
                        >
                            <button>Suivant</button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <LoadingCycle />
                    <div className="text-center">
                        Chargement de la liste de modèles en cours ...
                    </div>
                </>
            )}
            {(dataVeloSelect === null || dataVeloSelect === undefined) && (
                <div className=" margin-30">
                    Merci de sélectionner une gamme dans l'onglet 1 précédent
                </div>
            )}
        </>
    );
}
