export const setMailCustomer = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_MAIL_CUSTOMER",
            mailCustomerSearch: value,
        });
    };
};
export const setPhoneCustomer = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_PHONE_CUSTOMER",
            phoneCustomerSearch: value,
        });
    };
};
export const setNameCustomer = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_NAME_CUSTOMER",
            nameCustomerSearch: value,
        });
    };
};

export const setIdCustomer = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_ID_CUSTOMER",
            idCustomerSearch: value,
        });
    };
};

export const setIdOrderCustomer = (value) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_ID_ORDER_CUSTOMER",
            idOrderCustomerSearch: value,
        });
    };
};
