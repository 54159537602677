const initialState = {
    resultShopiCustomerByMail: null,
    resultProductsPrestashop: null,
    resultOrdersByProductPrestashop: null,
    statsParrainage: null,
};

export default function Bdd(state = initialState, action) {
    switch (action.type) {
        case "resultShopiCustomerByMail":
            return {
                ...state,
                resultShopiCustomerByMail: action.resultShopiCustomerByMail,
            };

        case "resultProductsPrestashop":
            return {
                ...state,
                resultProductsPrestashop: action.resultProductsPrestashop,
            };

        case "resultOrdersByProductPrestashop":
            return {
                ...state,
                resultOrdersByProductPrestashop:
                    action.resultOrdersByProductPrestashop,
            };
        case "statsParrainage":
            return {
                ...state,
                statsParrainage: action.statsParrainage,
            };

        default:
            return state;
    }
}
