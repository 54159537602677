import "./maps.css";
import "./sav.css";
import "./parrainage.css";
import "../Components/User/form.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import LoadingCycle from "../Components/Sav/LoaderCycle";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";
import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";

export default function PageParrainage() {
    document.title = "Parrainez-vos amis";
    document.description =
        "Invitez vos amis que vous souhaitez parrainer pour leur futur achat de vélo. Profitez de 100€ chacun après l’achat de votre filleul(e).";
    const openNavBurger = useSelector((state) => state.style.openNavBurger);

    const [launchInvitation, setLaunchInvitation] = useState(false);
    const [step, setStep] = useState(1);
    const [mailParrain, setMailParrain] = useState("");
    const [inputfMail, setInputMail] = useState(false);

    const [verifMail, setVerifMail] = useState(false);
    const [mailFilleul, setMailFilleul] = useState([]);
    const [confirmRGPD, setConfirmRGPD] = useState(false);

    const dataChecked = (element) => {
        element.classList.remove("bg-error");
        element.classList.add("form-checked");
    };

    // set red background  and set attribute data-error
    const dataError = (element) => {
        element.focus();
        element.classList.add("bg-error");
        element.classList.remove("form-checked");
    };
    const checkValueEmail = (element) => {
        // control data with regex mail
        if (
            element !== null &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element.value)
        ) {
            setVerifMail(false);
            setInputMail("");
            element.setAttribute(
                "data-error",
                "Vous devez saisir une adresse mail valide."
            );
            dataError(element);
        } else {
            dataChecked(element);
            setVerifMail(true);
            setInputMail(element.value);
            if (step === 1) {
                setMailParrain(element.value);
            }
        }
    };
    const checkConfirm = (element) => {
        if (element.target.checked === true) {
            setConfirmRGPD(true);
            dataChecked(document.getElementById("confirmFormLabel"));
        } else {
            setConfirmRGPD(false);
            dataError(document.getElementById("confirmFormLabel"));
        }
    };
    const handleSubmit = () => {
        const formData = new FormData();

        formData.append("parrain", mailParrain);
        formData.append("filleuls", mailFilleul);

        async function PostCreateSav(formData) {
            try {
                setStep(3);

                const response = await fetch(
                    `https:///api.levelomad.com/api/parrainage/v1/create_new_link/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 500) {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.success("Envoi Réussie ! ✅", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {}
            setStep(4);
        }
        PostCreateSav(formData);
    };
    return (
        <>
            {openNavBurger === false && (
                <>
                    <main className="main-sav ">
                        <div className="banner-parrainage"></div>
                        <div className="banner-title">
                            <h1>
                                Demande de
                                <br />
                                Parrainage
                            </h1>
                        </div>
                        <div className=" dimension-main ">
                            {launchInvitation === false ? (
                                <>
                                    <div className="parcours dimension-main">
                                        <h2>Invitez votre entrourage</h2>{" "}
                                    </div>
                                    <div className="align-sav">
                                        <p>
                                            Invitez vos amis, votre famille ou
                                            encore vos collègues à rejoindre
                                            notre programme de parrainage
                                            Velomad et profitez chacun
                                            d'avantages exclusives.
                                        </p>
                                        <p>
                                            Vous avez un Velomad et vous
                                            souhaitez devenir parrain. Saisissez
                                            votre adresse puis celle de vos
                                            potentiels filleuls et profitez de
                                            récompenses exclusives.
                                        </p>
                                        <p>
                                            Ensemble, pédalons vers un avenir
                                            plus vert et sain.
                                            <button
                                                className={
                                                    "velo_product ms-3 align-sav my-3"
                                                }
                                                onClick={() =>
                                                    setLaunchInvitation(true)
                                                }
                                            >
                                                Inviter mes amis
                                            </button>
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {step === 1 && (
                                        <div>
                                            <div className="parcours">
                                                <h2>Pour Commencer</h2>
                                            </div>{" "}
                                            <div className="align-sav">
                                                <p>
                                                    Saissisez le mail du PARRAIN
                                                    ayant déjà réalisé une
                                                    commande sur notre site
                                                </p>
                                                <input
                                                    type="email"
                                                    placeholder="Email du parrain"
                                                    className="form-control"
                                                    maxLength={300}
                                                    onBlur={(e) =>
                                                        checkValueEmail(
                                                            e.target
                                                        )
                                                    }
                                                />
                                                {!verifMail && (
                                                    <p className="text-error">
                                                        Merci de saisir une
                                                        adresse mail valide
                                                        avant de poursuivre.
                                                    </p>
                                                )}

                                                {!verifMail ? (
                                                    <button
                                                        className={
                                                            "velo_product mx-0"
                                                        }
                                                        onMouseOver={(e) =>
                                                            checkValueEmail(
                                                                document.querySelector(
                                                                    "input[type=email]"
                                                                )
                                                            )
                                                        }
                                                    >
                                                        En attente du mail
                                                        parrain
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={
                                                            "velo_product mx-0"
                                                        }
                                                        onMouseOver={(e) =>
                                                            checkValueEmail(
                                                                document.querySelector(
                                                                    "input[type=email]"
                                                                )
                                                            )
                                                        }
                                                        onClick={() =>
                                                            setStep(2)
                                                        }
                                                    >
                                                        Poursuivre
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {step === 2 && (
                                        <div>
                                            <div className="parcours">
                                                <h2>Ajout de Filleul:</h2>{" "}
                                            </div>
                                            <div className="align-sav">
                                                <p>
                                                    Saissisez les mails des
                                                    filleuls que vous souhaitez
                                                    inviter à rejoindre notre
                                                    programme de parrainage
                                                </p>
                                                <p>
                                                    Les filleuls recevront un
                                                    mail d'invitation si
                                                    l'adresse email du parrain
                                                    est bien présente dans notre
                                                    base de données.
                                                </p>
                                                {mailFilleul.length <= 5 && (
                                                    <>
                                                        <input
                                                            type="email"
                                                            placeholder="Email du filleul"
                                                            className="form-control"
                                                            maxLength={300}
                                                            onBlur={(e) =>
                                                                checkValueEmail(
                                                                    e.target
                                                                )
                                                            }
                                                        />
                                                        {!verifMail && (
                                                            <p className="text-error">
                                                                Merci de saisir
                                                                une adresse mail
                                                                valide avant de
                                                                poursuivre.
                                                            </p>
                                                        )}

                                                        {!verifMail ? (
                                                            <button
                                                                className={
                                                                    "velo_product mx-0"
                                                                }
                                                                onMouseOver={(
                                                                    e
                                                                ) =>
                                                                    checkValueEmail(
                                                                        document.querySelector(
                                                                            "input[type=email]"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                En attente
                                                                validation mail
                                                                du filleul
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className={
                                                                    "velo_product mx-0"
                                                                }
                                                                onMouseOver={() =>
                                                                    checkValueEmail(
                                                                        document.querySelector(
                                                                            "input[type=email]"
                                                                        )
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    setMailFilleul(
                                                                        [
                                                                            ...mailFilleul,
                                                                            inputfMail,
                                                                        ]
                                                                    );
                                                                    document.querySelector(
                                                                        "input[type=email]"
                                                                    ).value =
                                                                        "";
                                                                }}
                                                            >
                                                                Ajouter à la
                                                                liste
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                                <hr className="mt-40" />
                                                <div>
                                                    <h4>
                                                        Liste des filleuls à
                                                        inviter (max : 5 par
                                                        demandes):{" "}
                                                    </h4>
                                                    {mailFilleul.length ===
                                                        0 && (
                                                        <p>
                                                            Aucun mail saisie
                                                            pour l'instant
                                                        </p>
                                                    )}
                                                    <ul>
                                                        {mailFilleul.map(
                                                            (mail, index) => (
                                                                <li key={index}>
                                                                    {mail}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <hr className="mt-40" />
                                                <div className="confirmForm ">
                                                    <input
                                                        id="confirmForm"
                                                        type="checkbox"
                                                        className=""
                                                        onClick={(e) => {
                                                            checkConfirm(e);
                                                        }}
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="confirmForm"
                                                        id="confirmFormLabel"
                                                        className="w-75"
                                                    >
                                                        Je donne mon accord
                                                        ainsi que mes filleuls
                                                        pour que nos données
                                                        personnelles soient
                                                        utilisées dans le cadre
                                                        de ma demande.
                                                    </label>
                                                </div>
                                                {mailFilleul.length > 0 &&
                                                confirmRGPD === true ? (
                                                    <button
                                                        className={
                                                            "velo_product mx-0"
                                                        }
                                                        onClick={() => {
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        Inviter mes amis
                                                    </button>
                                                ) : (
                                                    <>
                                                        <p className={" mx-0"}>
                                                            Merci de saisir au
                                                            moins un mail de
                                                            filleul et de
                                                            valider les
                                                            conditions
                                                            d'utilisation pour
                                                            valider votre
                                                            demande.
                                                        </p>
                                                        <button
                                                            className={
                                                                "velo_product mx-0"
                                                            }
                                                            type="disabled"
                                                        >
                                                            Inviter mes amis
                                                        </button>
                                                    </>
                                                )}{" "}
                                            </div>
                                        </div>
                                    )}
                                    {step === 3 && (
                                        <div>
                                            <div className="parcours">
                                                <h2>
                                                    Envoi de votre demande...
                                                </h2>{" "}
                                            </div>
                                            <p className="my-3 align-sav">
                                                Merci de patienter quelques
                                                instants, nous consultons notre
                                                base de données pour vérifier
                                                les mails saisis.
                                            </p>
                                            <LoadingCycle />
                                        </div>
                                    )}
                                    {step === 4 && (
                                        <div>
                                            <div className="parcours">
                                                <h2>Demande Envoyée</h2>{" "}
                                            </div>
                                            <p className="my-3 align-sav">
                                                Merci de votre participation !
                                            </p>
                                            <p className="align-sav">
                                                Les filleuls recevront un mail
                                                d'invitation si l'adresse email
                                                du parrain est bien présente
                                                dans notre base de données.
                                            </p>
                                            <button
                                                className={"velo_product mx-0"}
                                                onClick={() => {
                                                    setMailFilleul([]);
                                                    setStep(2);
                                                }}
                                            >
                                                Inviter d'autres amis
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </main>
                    <SingeFooterShopifyVelomad />
                    <FooterShopifyVelomad />
                </>
            )}
        </>
    );
}
