import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./gestionMap.css";
import "./stats.css";

import { ObtainStatsParrainage } from "../Redux/actions/bdd.actions";

export default function GestionParrainage() {
    document.title = "VELOMAD | Stats Parrainage";
    document.description = "Intranet du VELOMAD";
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);
    const stats = useSelector((store) => store.Bdd.statsParrainage);

    useEffect(() => {
        setTimeout(() => {
            dispatch(ObtainStatsParrainage(accessToken, authUser));
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <h1>Demande de Parrainage</h1>

                    <div>
                        <table>
                            <thead className="td-header">
                                <tr>
                                    <td>Parrain</td>
                                    <td>Crée</td>
                                    <td>MAJ</td>
                                    <td>Nbre Filleul</td>
                                    <td>Nbre Parrainage validé</td>
                                    <td>N°Carte Kdo</td>
                                </tr>
                            </thead>
                            <tbody>
                                {stats !== null &&
                                    stats !== undefined &&
                                    stats.parrains !== null &&
                                    stats.parrains !== undefined &&
                                    stats.parrains.map((item) => (
                                        <tr
                                            key={item.id}
                                            className="elementMap"
                                        >
                                            <td>{item.mail}</td>
                                            <td>{item.created}</td>
                                            <td>{item.updated}</td>
                                            <td>{item.nbre_filleuls}</td>
                                            <td>
                                                {item.nbre_parrainage_valide}
                                            </td>
                                            <td>{item.gift_card}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}{" "}
        </main>
    );
}
