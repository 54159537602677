import "./form.css";
import { useDispatch, useSelector } from "react-redux";

import {
    setEtatState,
    setGarantieState,
    setKilometrage,
} from "../../Redux/actions/user.actions";
import {
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import { useEffect, useState } from "react";

export default function AboutVeloReprise(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const modele = useSelector((state) => state.veloDetails.modele);
    const sku = useSelector((state) => state.veloDetails.sku);

    const etatState = useSelector((state) => state.user.etat);
    const garantieState = useSelector((state) => state.user.garantie);
    const kilometrage = useSelector((state) => state.user.kilometrage);

    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );

    const pricesReprises = useSelector(
        (state) => state.veloDetails.prices_reprises
    );
    const [filteredPricesReprises, setFilteredPricesReprises] = useState([]);
    const [gamme, setGamme] = useState(null);
    const [etat, setEtat] = useState(null);
    useEffect(() => {
        if (etatState !== null) {
            if (etatState.toLowerCase().includes("parfait")) {
                setEtat("Parfait");
            } else if (etatState.toLowerCase().includes("bon")) {
                setEtat("Très Bon");
            } else if (etatState.includes("correct")) {
                setEtat("Correct");
            }
        }
    }, [etatState]);
    useEffect(() => {
        if (pricesReprises !== undefined && pricesReprises !== null) {
            setFilteredPricesReprises(
                pricesReprises.filter(
                    (e) =>
                        e.kmMin <= kilometrage &&
                        e.kmMax >= kilometrage &&
                        e.gamme === gamme &&
                        e.etat === etat
                )
            );
        }
    }, [pricesReprises, kilometrage, gamme, etat]);

    useEffect(() => {
        if (dataVeloSelect !== null) {
            if (dataVeloSelect.title.toLowerCase().includes("v2")) {
                setGamme("V2");
            } else if (dataVeloSelect.title.toLowerCase().includes("cargo")) {
                setGamme("V2");
            } else if (dataVeloSelect.title.includes("+")) {
                setGamme("V+");
            } else {
                setGamme("V1");
            }
        }
    }, [dataVeloSelect]);

    return (
        <>
            {modele !== undefined ? (
                <>
                    <div className="etat_small">Modèle sélectionné :</div>
                    <div> {sku}</div>
                    <div>
                        {modele !== null &&
                            modele !== undefined &&
                            modele
                                .replace("sav", "")
                                .split("#")
                                .map((element, index) => (
                                    <div key={index}>
                                        {element.replace(" - ", " ")}
                                    </div>
                                ))}
                    </div>
                    <div className="etat_small">Garantie :</div>
                    <div className="statut_garantie">
                        <div
                            onClick={() => dispatch(setGarantieState(true))}
                            className={
                                garantieState !== null && garantieState === true
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Sous Garantie{" "}
                            {props.type !== "reprise" && <>- 0€</>}
                        </div>
                        <div
                            onClick={() => dispatch(setGarantieState(false))}
                            className={
                                garantieState !== null &&
                                garantieState === false
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Hors Garantie{" "}
                            {props.type !== "reprise" && <>- Facturé</>}
                        </div>
                    </div>
                    <div className="etat_small">Etat d'Usure :</div>
                    <div className="statut_garantie ">
                        <div
                            onClick={() =>
                                dispatch(setEtatState(" Parfait état"))
                            }
                            className={
                                etatState !== null &&
                                etatState === " Parfait état"
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Parfait
                        </div>
                        <div
                            onClick={() =>
                                dispatch(setEtatState("Très bon état"))
                            }
                            className={
                                etatState !== null &&
                                etatState === "Très bon état"
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Très bon
                        </div>
                        <div
                            onClick={() =>
                                dispatch(setEtatState("État correct"))
                            }
                            className={
                                etatState !== null &&
                                etatState === "État correct"
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Correct
                        </div>
                    </div>
                    <div>
                        <small className="parcoursSelected my-3-block">
                            Remarques :
                        </small>
                        <br />
                        <small>
                            “Parfait : batterie et moteur parfaitement
                            fonctionnels, pas de rayures sur le cadre, pneus et
                            freins en parfait état
                        </small>
                        <br />
                        <small>
                            “Très bon : Batterie et moteur fonctionnels,
                            quelques petites rayures sur le cadre, quelques
                            traces sur les pneus
                        </small>
                        <br />
                        <small>
                            “Correct” : Batterie et moteur en état correct,
                            rayures sur le cadre, pneus en état d’usage, freins
                            à réviser
                        </small>
                    </div>
                    <div className="etat_small">Kilometrage :</div>
                    <input
                        type="number"
                        min={0}
                        max={99999}
                        name="kilometrage"
                        id="kilometrage"
                        className="form-control"
                        placeholder="XXXX"
                        defaultValue={kilometrage}
                        onChange={(e) =>
                            dispatch(setKilometrage(e.target.value))
                        }
                    />

                    {filteredPricesReprises !== undefined &&
                        filteredPricesReprises.length === 1 &&
                        kilometrage !== null && (
                            <div>
                                <div className="etat_small">Estimation :</div>
                                {filteredPricesReprises[0].prixReprise} €
                            </div>
                        )}
                    {etatState !== null &&
                    garantieState !== null &&
                    kilometrage !== null ? (
                        <div
                            className="btn-link-web margin-btn"
                            onClick={() => {
                                dataVeloSelect !== null &&
                                    dispatch(
                                        setParcoursSavTitle("Vos Coordonnées")
                                    );
                                dispatch(setParcoursSavStep(4));
                            }}
                        >
                            <button>Suivant</button>
                        </div>
                    ) : (
                        <div className=" margin-30">
                            Merci de sélectionner l'état, la garantie et de
                            saisir le kilométrage avant de continuer
                        </div>
                    )}
                </>
            ) : (
                <div className=" margin-30">
                    Merci de sélectionner une version de vélo dans l'onglet 2
                    précédent
                </div>
            )}
        </>
    );
}
