import "./piecesSav.css";
import "./piecesSav.css";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

import {
    erasePieceSAV,
    setFoundPiecesSav,
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import {
    addPiece,
    addPieceWithVariant,
} from "../../Redux/actions/panierSav.actions";
import { useEffect } from "react";
import { useFetchGetProductById } from "../../Utils/FetchSav";

export default function PieceInput(props) {
    const dispatch = useDispatch();
    const garantieState = useSelector((state) => state.user.garantie);

    const { piece } = useFetchGetProductById(props.piece.id);
    const handleClick = (e) => {
        dispatch(addPiece(props.piece));
        dispatch(setParcoursSavTitle("Panier"));
        dispatch(setParcoursSavStep(5));
    };
    const handleClickVariant = (e, variant) => {
        dispatch(addPieceWithVariant(props.piece, variant));
        dispatch(setParcoursSavTitle("Panier"));
        dispatch(setParcoursSavStep(5));
        dispatch(erasePieceSAV());
    };

    useEffect(() => {
        dispatch(setFoundPiecesSav(true));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {piece !== undefined &&
            piece.variants !== undefined &&
            piece.variants.length > 0 ? (
                <div className="pieceSav" key={"divPiece" + props.piece.id}>
                    <div>
                        {piece !== undefined && <h4>{piece.title} </h4>}

                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(piece.body_html),
                            }}
                        ></div>
                        <hr />
                    </div>
                    <div className="listing-variant-piece">
                        {piece !== undefined &&
                            piece.variants.length > 0 &&
                            piece.variants.map((variant) => (
                                <div key={variant.id}>
                                    {piece !== undefined &&
                                        piece.options.length > 0 &&
                                        piece.options[0].name !== "Title" && (
                                            <span>
                                                {piece.options[0].name} :{" "}
                                                {variant.option1 !==
                                                    "Default Title" &&
                                                    variant.option1}
                                            </span>
                                        )}{" "}
                                    {piece.images !== undefined &&
                                        piece.images.map((img) => (
                                            <>
                                                {img.id ===
                                                    variant.image_id && (
                                                    <div>
                                                        <img
                                                            src={img.src}
                                                            alt={img.title}
                                                            className="velo_select-img"
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    <div>
                                        Prix : <br />
                                        {garantieState === true ? (
                                            <>
                                                <span className="text-barre">
                                                    {(
                                                        variant.price * 0.85
                                                    ).toFixed(2)}
                                                    €{" "}
                                                </span>{" "}
                                                <span className="text-black-price">
                                                    0 EUR
                                                </span>{" "}
                                                <span>TTC</span>
                                            </>
                                        ) : (
                                            <>
                                                {(variant.price * 0.85).toFixed(
                                                    2
                                                )}
                                                € TTC
                                            </>
                                        )}{" "}
                                    </div>
                                    <div> Poid : {variant.weight} kg </div>
                                    <div
                                        className="btn-panier"
                                        onClick={(e) => {
                                            handleClickVariant(e, variant);
                                        }}
                                    >
                                        Ajouter au panier
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <div className="pieceSav">
                    {" "}
                    <div>
                        {piece !== undefined && <h4>{piece.title} </h4>}
                        <hr />
                        {piece !== undefined &&
                            piece.body_html !== undefined &&
                            piece.body_html && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            piece.body_html
                                        ),
                                    }}
                                ></div>
                            )}

                        {piece !== undefined && <span>piece.title</span>}
                    </div>
                    <div
                        className="btn-panier"
                        onClick={(e) => {
                            handleClick(e);
                        }}
                    >
                        Ajouter au panier
                    </div>
                </div>
            )}
        </>
    );
}
