import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";

export function useFetchGetListSav() {
    const [dataListSav, setData] = useState(undefined);
    const accessToken = useSelector((store) => store.user.accessToken);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    "https://api.levelomad.com/api/erp/v1/obtains_sav/",
                    {
                        method: "get",
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,

                            "content-type": "application/json",
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                } else if (data.status === 500) {
                    toast.error(
                        "Le serveur distant API Logs List Staff est en erreur",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error(
                    "Oups! Le serveur distant API Logs List Staff est injoignable ",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { dataListSav };
}

export function useFetchGetProductById(idProduct) {
    const [piece, setData] = useState(undefined);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.levelomad.com/api/shopify/v1/product_by_id/${idProduct}/`,
                    {
                        method: "get",
                        headers: new Headers({
                            "content-type": "application/json",
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                } else if (data.status === 500) {
                    toast.error("Le serveur distant API est en erreur", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error("Oups! Le serveur distant API   est injoignable ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        if (idProduct !== undefined && idProduct !== null) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { piece };
}
