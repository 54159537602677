/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";

export default function WidgetSumHTStock({
    newLinesItems,
    items,
    linkInvenProd,
    available,
    inventoryDetails,
    inventStore,
    locationIdSelected,
}) {
    const [sumNumber, setSumNumber] = useState(0);
    useEffect(() => {
        if (Object.keys(newLinesItems).length > 0) {
            let sum = 0;
            Object.keys(newLinesItems).map((key) => (
                <>
                    {parseInt(locationIdSelected, 20) ===
                    parseInt(78563311897, 20) ? (
                        <>
                            {newLinesItems[key].order.location_id === null &&
                                newLinesItems[key].product ===
                                    linkInvenProd[items.inventory_item_id][
                                        "product_id"
                                    ] &&
                                newLinesItems[key].sku ===
                                    linkInvenProd[items.inventory_item_id][
                                        "sku"
                                    ] &&
                                (sum =
                                    sum +
                                    parseInt(newLinesItems[key].quantity))}
                        </>
                    ) : (
                        <>
                            {parseInt(
                                newLinesItems[key].order.location_id,
                                20
                            ) === parseInt(locationIdSelected, 20) &&
                                newLinesItems[key].product ===
                                    linkInvenProd[items.inventory_item_id][
                                        "product_id"
                                    ] &&
                                newLinesItems[key].sku ===
                                    linkInvenProd[items.inventory_item_id][
                                        "sku"
                                    ] &&
                                (sum =
                                    sum +
                                    parseInt(newLinesItems[key].quantity))}
                        </>
                    )}
                </>
            ));
            setSumNumber(sum);
        }
    }, [newLinesItems, items, linkInvenProd, locationIdSelected]);
    return (
        <>
            {parseFloat(
                inventoryDetails[inventStore.toString()][
                    parseInt(items.inventory_item_id)
                ].cost
            ) >= 0
                ? Number(
                      parseFloat(
                          (sumNumber + available) *
                              parseFloat(
                                  inventoryDetails[inventStore.toString()][
                                      parseInt(items.inventory_item_id)
                                  ].cost
                              )
                      ).toFixed(2)
                  )
                : "???"}
        </>
    );
}
