/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingCycle from "../Sav/LoaderCycle";
import { toast } from "react-toastify";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { BiLogoShopify } from "react-icons/bi";
import DateSelected from "./DateSelected";
import { MdLocalShipping } from "react-icons/md";
import { FaEuroSign } from "react-icons/fa";

export default function EtatPrecommandes() {
    document.title = "VELOMAD - Stats - Etats des Précommandes";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);

    const orders = useSelector((state) => state.Stats.orders_date);
    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);
    const [newLinesItems, setNewLinesItems] = useState({});
    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);
    const [listTags, setListTags] = useState([]);
    const handleSubmit = (e, tags) => {
        e.preventDefault();
        let table = undefined;

        table = document.getElementById(tags);
        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );
        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );
        const obj = {
            table: [thead].concat(tbody),
        };
        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        formData.append(
            "Title",
            tags + "_Velos_DU_" + dateStart + "_AU_" + dateEnd
        );

        async function PostCreateCSV(formData) {
            setUrlCsvOrdersDate(undefined);

            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    setUrlCsvOrdersDate(data);
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateCSV(formData);
    };
    useEffect(() => {
        const new_line_items = {};
        const tags = [];
        if (orders !== undefined && orders !== null) {
            orders.map((order) => {
                if (
                    order.cancelled_at === null &&
                    order.financial_status !== "refunded" &&
                    order.financial_status !== "avoid" &&
                    order.fulfillment_status !== "fulfilled" &&
                    order.closed_at !== "null"
                ) {
                    order.line_items.map((item) => {
                        let productId = "";
                        if (item.product_id === null) {
                            productId = item.name;
                        } else {
                            productId = item.product_id;
                        }
                        new_line_items[item.id] = {
                            name: item.name,
                            quantity: item.quantity,
                            price: item.price,
                            sku: item.sku,
                            product: productId,
                            item: item,
                            order: order,
                        };
                    });
                    if (order.tags !== "") {
                        order.tags.split(", ").map((tag) => {
                            if (!tags.includes(tag) && !tag.includes("SAV")) {
                                tags.push(tag);
                            }
                        });
                    }

                    // detect if refund
                    if (order.refunds.length > 0) {
                        order.refunds.map((refund) => {
                            if (refund.refund_line_items.length > 0) {
                                refund.refund_line_items.map((refund_item) => {
                                    if (
                                        new_line_items.hasOwnProperty(
                                            refund_item.line_item_id
                                        )
                                    ) {
                                        new_line_items[
                                            refund_item.line_item_id
                                        ].quantity =
                                            new_line_items[
                                                refund_item.line_item_id
                                            ].quantity - refund_item.quantity;
                                    }
                                });
                            }
                        });
                    }

                    // detect if fulfilled success ! not cancelled
                    if (order.fulfillments.length > 0) {
                        order.fulfillments.map((fulfillment) => {
                            if (fulfillment.line_items.length > 0) {
                                fulfillment.line_items.map(
                                    (fulfillment_item) => {
                                        if (
                                            new_line_items.hasOwnProperty(
                                                fulfillment_item.id
                                            ) &&
                                            fulfillment.status !== "cancelled"
                                        ) {
                                            new_line_items[
                                                fulfillment_item.id
                                            ].quantity =
                                                new_line_items[
                                                    fulfillment_item.id
                                                ].quantity -
                                                fulfillment_item.quantity;
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            });
        }
        setNewLinesItems(new_line_items);
        setListTags(tags);
    }, [orders]);

    return (
        <main>
            <h1>Tableau par Tags</h1>
            {dateStart !== null && dateEnd !== null ? (
                <>
                    <DateSelected />

                    {listTags.length > 0 && (
                        <div>
                            {listTags.map((tag) => (
                                <div>
                                    <h2>#{tag}</h2>
                                    {orders !== null &&
                                        orders !== undefined &&
                                        orders.length >= 0 && (
                                            <>
                                                <div className="text-center f-column">
                                                    <button
                                                        onClick={(e) =>
                                                            handleSubmit(e, tag)
                                                        }
                                                    >
                                                        Demande d'export
                                                        Produits
                                                    </button>
                                                    {urlCsvOrdersDate !==
                                                        undefined && (
                                                        <a
                                                            href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <button className="btn">
                                                                Obtenir le CSV
                                                                d'export{" "}
                                                                <BsFiletypePdf />{" "}
                                                                <BsBoxArrowUpRight />
                                                            </button>
                                                        </a>
                                                    )}
                                                </div>
                                                <table
                                                    className="table-center"
                                                    id={tag}
                                                >
                                                    <thead>
                                                        <tr className="td-header">
                                                            <td>TAG</td>
                                                            <td>Produits</td>
                                                            <td>Sku</td>
                                                            <td>Quantité</td>
                                                            <td>
                                                                Commandes
                                                                Associées
                                                            </td>
                                                            <td>Mail</td>
                                                            <td>
                                                                Date Création
                                                            </td>
                                                            <td>
                                                                Date Cloture
                                                            </td>
                                                            <td>Lieu</td>
                                                            <td>ResteDU TTC</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(
                                                            newLinesItems
                                                        ).length > 0 ? (
                                                            Object.keys(
                                                                newLinesItems
                                                            ).map(
                                                                (
                                                                    key,
                                                                    index
                                                                ) => (
                                                                    <>
                                                                        {console.log(
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                        )}
                                                                        {parseFloat(
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                                .quantity
                                                                        ) !==
                                                                            parseFloat(
                                                                                0
                                                                            ) &&
                                                                            newLinesItems[
                                                                                key
                                                                            ]
                                                                                .price >
                                                                                800 &&
                                                                            !newLinesItems[
                                                                                key
                                                                            ].name.includes(
                                                                                "ivraison"
                                                                            ) &&
                                                                            !newLinesItems[
                                                                                key
                                                                            ].name.includes(
                                                                                "frais"
                                                                            ) &&
                                                                            !newLinesItems[
                                                                                key
                                                                            ].name.includes(
                                                                                "Frais"
                                                                            ) &&
                                                                            !newLinesItems[
                                                                                key
                                                                            ].name.includes(
                                                                                "xpéd"
                                                                            ) &&
                                                                            !newLinesItems[
                                                                                key
                                                                            ].name.includes(
                                                                                "xped"
                                                                            ) &&
                                                                            newLinesItems[
                                                                                key
                                                                            ].order.tags.includes(
                                                                                tag
                                                                            ) && (
                                                                                <tr
                                                                                    key={
                                                                                        tag +
                                                                                        key
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ]
                                                                                                .order
                                                                                                .tags
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <a
                                                                                            href={
                                                                                                "https://velo-pdv.fr/admin/products/" +
                                                                                                newLinesItems[
                                                                                                    key
                                                                                                ]
                                                                                                    .product
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            {
                                                                                                newLinesItems[
                                                                                                    key
                                                                                                ]
                                                                                                    .name
                                                                                            }
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ]
                                                                                                .sku
                                                                                        }
                                                                                    </td>
                                                                                    <td className="quantity-precommand">
                                                                                        {
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ]
                                                                                                .quantity
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <a
                                                                                            href={
                                                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                                                newLinesItems[
                                                                                                    key
                                                                                                ]
                                                                                                    .order
                                                                                                    .id
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            <BiLogoShopify />
                                                                                            {
                                                                                                newLinesItems[
                                                                                                    key
                                                                                                ].order.name.split(
                                                                                                    "#"
                                                                                                )[1]
                                                                                            }
                                                                                        </a>
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .item
                                                                                            .fulfillment_status ===
                                                                                            "fulfilled" && (
                                                                                            <MdLocalShipping className="text-green" />
                                                                                        )}{" "}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .item
                                                                                            .fulfillment_status ===
                                                                                            "partial" && (
                                                                                            <MdLocalShipping className="text-paid" />
                                                                                        )}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .item
                                                                                            .fulfillment_status ===
                                                                                            null && (
                                                                                            <MdLocalShipping className="text-red" />
                                                                                        )}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .financial_status ===
                                                                                            "paid" && (
                                                                                            <FaEuroSign className="text-green" />
                                                                                        )}{" "}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .financial_status ===
                                                                                            "partially_paid" && (
                                                                                            <FaEuroSign className="text-paid" />
                                                                                        )}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .financial_status ===
                                                                                            "pending" && (
                                                                                            <FaEuroSign className="text-red" />
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ]
                                                                                                .order
                                                                                                .customer
                                                                                                .email
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ].order.created_at.split(
                                                                                                "T"
                                                                                            )[0]
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .closed_at !==
                                                                                            null &&
                                                                                            newLinesItems[
                                                                                                key
                                                                                            ].order.closed_at.split(
                                                                                                "T"
                                                                                            )[0]}
                                                                                    </td>
                                                                                    <td>
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .location_id ===
                                                                                            88642617625 &&
                                                                                            "#paris"}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .location_id ===
                                                                                            88647401753 &&
                                                                                            "#rouen"}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .location_id ===
                                                                                            90150437145 &&
                                                                                            "#b2b"}
                                                                                        {newLinesItems[
                                                                                            key
                                                                                        ]
                                                                                            .order
                                                                                            .location_id ===
                                                                                            null &&
                                                                                            "#site"}{" "}
                                                                                    </td>
                                                                                    <td>
                                                                                        {index ===
                                                                                            0 && (
                                                                                            <span
                                                                                                className={
                                                                                                    "restedue" +
                                                                                                    tag
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    newLinesItems[
                                                                                                        key
                                                                                                    ]
                                                                                                        .order
                                                                                                        .total_outstanding
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                        {index >
                                                                                            0 &&
                                                                                            newLinesItems[
                                                                                                Object.keys(
                                                                                                    newLinesItems
                                                                                                )[
                                                                                                    index -
                                                                                                        1
                                                                                                ]
                                                                                            ]
                                                                                                .order
                                                                                                .id !==
                                                                                                newLinesItems[
                                                                                                    key
                                                                                                ]
                                                                                                    .order
                                                                                                    .id && (
                                                                                                <span
                                                                                                    className={
                                                                                                        "restedue" +
                                                                                                        tag
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        newLinesItems[
                                                                                                            key
                                                                                                        ]
                                                                                                            .order
                                                                                                            .total_outstanding
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                    </>
                                                                )
                                                            )
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <LoadingCycle />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="td-header">
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        )}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <p className="text-center">
                    Merci de sélectionner vote Période d'extraction de données
                    (max : 30j conseillé)
                </p>
            )}
        </main>
    );
}
