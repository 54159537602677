import axios from "axios";
import { toast } from "react-toastify";

export const getCollectionVelos = () => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/shopify/v1/collections/velos/")
            .then((res) => {
                return dispatch({
                    type: "GET_COLLECTION_VELOS",
                    collection_velos: res.data.products,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_COLLECTION_VELOS",
                        collection_velos: null,
                    });
                } else {
                    toast.error("Impossible de se connecter au serveur", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    };
};

export const selectVelo = (velo_select) => {
    return (dispatch) => {
        return dispatch({
            type: "SELECT_VELO",
            velo: velo_select,
        });
    };
};

export const setFoundPiecesSav = (status) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_FOUND_PIECES_SAV",
            foundPiecesSav: status,
        });
    };
};

export const selectModele = (sku) => {
    return (dispatch) => {
        return dispatch({
            type: "SELECT_MODELE",
            modele: sku,
        });
    };
};

export const setSku = (sku) => {
    return (dispatch) => {
        return dispatch({
            type: "SELECT_SKU",
            sku: sku,
        });
    };
};
export const setPieceSelectID = (id) => {
    return (dispatch) => {
        if (id !== 0) {
            return dispatch({
                type: "SET_PIECE_SAV_SELECT_ID",
                pieceSelectId: id,
            });
        } else {
            return dispatch({
                type: "SET_PIECE_SAV_SELECT_ID",
                pieceSelectId: null,
            });
        }
    };
};

export const getProductSelectVelo = (idProduct) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/shopify/v1/product_by_id/${idProduct}/`
            )
            .then((res) => {
                return dispatch({
                    type: "GET_PRODUCT_SELECT_VELO",
                    product_select_velo: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_PRODUCT_SELECT_VELO",
                        product_select_velo: null,
                    });
                } else {
                    toast.error(
                        "Impossible de récupérer la liste des pièces, actualiser votre page",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};
export const eraseProductSelectVelo = () => {
    return (dispatch) => {
        return dispatch({
            type: "GET_PRODUCT_SELECT_VELO",
            product_select_velo: null,
        });
    };
};

export const erasePieceSAV = () => {
    return (dispatch) => {
        return dispatch({
            type: "GET_PIECES_SAV",
            pieces_sav: null,
        });
    };
};
export const getPiecesSav = (searchCat) => {
    return async (dispatch) => {
        return await axios
            .get(
                `https://api.levelomad.com/api/shopify/v1/collections/SAV/${searchCat}/`
            )
            .then((res) => {
                return dispatch({
                    type: "GET_PIECES_SAV",
                    pieces_sav: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_PIECES_SAV",
                        pieces_sav: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter, Serveur Pièces SAV HS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const getPiecesSavById = (idProduct) => {
    return async (dispatch) => {
        if (idProduct !== undefined) {
            return await axios
                .get(
                    `https://api.levelomad.com/api/shopify/v1/product_by_id/${idProduct}/`
                )
                .then((res) => {
                    return dispatch({
                        type: "GET_PIECE_SAV_SELECT_BY_ID",
                        pieceSelect: res.data,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warn(error.response.data.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        } else if (error.response.status === 500) {
                            toast.error(
                                "Le serveur distant API est en erreur",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                        }
                        return dispatch({
                            type: "GET_PIECE_SAV_SELECT_BY_ID",
                            pieceSelect: null,
                        });
                    } else {
                        toast.error(
                            "Impossible de se connecter, Serveur PRODUCT HS",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                });
        } else {
            return dispatch({
                type: "GET_PIECE_SAV_SELECT_BY_ID",
                pieceSelect: undefined,
            });
        }
    };
};

export const getProductSavB2C = (idProduct) => {
    return async (dispatch) => {
        if (idProduct !== undefined) {
            return await axios
                .get(
                    `https://api.levelomad.com/api/shopify/v1/product_by_id/${idProduct}/`
                )
                .then((res) => {
                    return dispatch({
                        type: "GET_PRODUCT_TO_CREATE_SAV_B2C",
                        productSavB2C: res.data,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warn(error.response.data.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        } else if (error.response.status === 500) {
                            toast.error(
                                "Le serveur distant API est en erreur",
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                }
                            );
                        }
                        return dispatch({
                            type: "GET_PRODUCT_TO_CREATE_SAV_B2C",
                            productSavB2C: null,
                        });
                    } else {
                        toast.error(
                            "Impossible de se connecter, GET_PRODUCT_TO_CREATE_SAV_B2C",
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                    }
                });
        } else {
            return dispatch({
                type: "GET_PRODUCT_TO_CREATE_SAV_B2C",
                productSavB2C: undefined,
            });
        }
    };
};

export const getCatSav = () => {
    return async (dispatch) => {
        return await axios
            .get(`https://api.levelomad.com/api/shopify/v1/collections/SAV/`)
            .then((res) => {
                return dispatch({
                    type: "GET_CAT_SAV",
                    catSAV: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_CAT_SAV",
                        catSAV: null,
                    });
                } else {
                    toast.error(
                        "Impossible de se connecter, Serveur Cat SAV HS",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            });
    };
};

export const setParcoursSavTitle = (title) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_PARCOURS_SAV_TITLE",
            parcoursSavTitle: title,
        });
    };
};

export const setParcoursSavStep = (step) => {
    return (dispatch) => {
        return dispatch({
            type: "SET_PARCOURS_SAV_STEP",
            parcoursSavStep: step,
        });
    };
};

export const getPricesRepriseVelos = () => {
    return async (dispatch) => {
        return await axios
            .get("https://api.levelomad.com/api/reprise/v1/get_price_reprise/")
            .then((res) => {
                return dispatch({
                    type: "GET_PRICES_REPRISE_VELOS",
                    prices_reprises: res.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warn(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (error.response.status === 500) {
                        toast.error("Le serveur distant API est en erreur", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    return dispatch({
                        type: "GET_PRICES_REPRISE_VELOS",
                        prices_reprises: null,
                    });
                } else {
                    toast.error("Impossible de se connecter au serveur", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    };
};
