import "./gestion.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function GestionHome() {
    document.title = "VELOMAD | Intranet ERP";
    document.description = "Intranet du VELOMAD";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const authUser = useSelector((store) => store.user.authUser);
    const accessToken = useSelector((store) => store.user.accessToken);

    return (
        <main>
            {accessToken !== null && authUser !== null ? (
                <>
                    <div className="text-center">
                        <img
                            src={
                                "https://levelomad.com/cdn/shop/files/Group_79.png?v=1685631931&width=150"
                            }
                            alt="Logo - VeloMad"
                        />
                    </div>
                    <h1> L'Intranet</h1>
                    <div className="list_home">
                        <div className="d-column">
                            <Link to="/gestion/sav">
                                <div className="button">Mes Sav</div>
                            </Link>
                            <Link to="/gestion/users">
                                <div className="button">Comptes Staff</div>
                            </Link>
                            <Link to="/gestion/maps">
                                <div className="button">Gestion Carte</div>
                            </Link>
                            <Link to="/gestion/reprise">
                                <div className="button">Gestion Reprise</div>
                            </Link>
                        </div>
                        <div className="d-column">
                            <Link to="/gestion/bdd">
                                <div className="button">
                                    Recherche Bases de Données
                                </div>
                            </Link>
                            <Link to="/gestion/shopify/customers">
                                <div className="button">
                                    Mes Clients Shopify
                                </div>
                            </Link>
                            <Link to="/gestion/shopify/parrainage">
                                <div className="button">Parrainage</div>
                            </Link>
                            <Link to="/gestion/stats">
                                <div className="button">Statistiques</div>
                            </Link>
                        </div>
                    </div>{" "}
                </>
            ) : (
                <div>Vous n'êtes pas autorisé à consulter cette page</div>
            )}
        </main>
    );
}
