/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import "../../Pages/sav.css";
import "../Stats/stats.css";
import GraphRepartRepair from "./graphRepartRepair";

export default function TableReparations(props) {
    const [dicoRepairProduct, setDicoRepairProduct] = useState(undefined);
    const [totalquantity, setTotalquantity] = useState(0);
    const [totalprice, setTotalprice] = useState(0);

    useEffect(() => {
        const dicoRepairProduct = {};

        if (
            props.location !== undefined &&
            props.productsRepairByLocations !== undefined
        ) {
            props.productsRepairByLocations[props.location].map((item) => {
                if (dicoRepairProduct.hasOwnProperty(item.product)) {
                    dicoRepairProduct[item.product] = {
                        product: dicoRepairProduct[item.product].product,
                        quantity:
                            dicoRepairProduct[item.product].quantity +
                            item.quantity,
                        name: dicoRepairProduct[item.product].name,
                        price: dicoRepairProduct[item.product].price,
                        order: [
                            ...dicoRepairProduct[item.product].order,
                            item.order,
                        ],
                    };
                } else {
                    if (item.quantity > 0) {
                        dicoRepairProduct[item.product] = {
                            product: item.product,
                            quantity: item.quantity,
                            name: item.name.replace("Réparation - ", ""),
                            price: item.price,
                            order: [item.order],
                        };
                    }
                }
            });
        }
        setDicoRepairProduct(dicoRepairProduct);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.productsRepairByLocations, props.location]);

    useEffect(() => {
        if (dicoRepairProduct !== undefined) {
            setTimeout(() => {
                setTotalquantity(
                    document.getElementsByClassName("quantity" + props.location)
                        .length > 0 &&
                        Array.from(
                            document.getElementsByClassName(
                                "quantity" + props.location
                            )
                        ).reduce(
                            (acc, element) =>
                                acc + parseFloat(element.innerText),
                            0
                        )
                );
                setTotalprice(
                    document.getElementsByClassName("total" + props.location)
                        .length > 0 &&
                        Array.from(
                            document.getElementsByClassName(
                                "total" + props.location
                            )
                        )
                            .reduce(
                                (acc, element) =>
                                    acc + parseFloat(element.innerText),
                                0
                            )
                            .toFixed(2)
                );
            }, 800);
        }
    }, [dicoRepairProduct]);
    return (
        <div className="div-table-reparation-div">
            <h2>{props.title}</h2>
            {props.productsRepairByLocations !== null &&
                props.productsRepairByLocations !== undefined && (
                    <>
                        <table id="my-repairs">
                            <thead>
                                <tr id="td-header">
                                    <td>Commande</td>
                                    <td>PdV</td>
                                    <td>Réparations</td>
                                    <td>Prix Unit.</td>
                                    <td>Quantité</td>
                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.productsRepairByLocations !== null &&
                                    props.productsRepairByLocations !==
                                        undefined &&
                                    props.location !== undefined && (
                                        <>
                                            {dicoRepairProduct !== undefined &&
                                                Object.keys(
                                                    dicoRepairProduct
                                                ).map((lineItems) => (
                                                    <tr key={lineItems}>
                                                        <td>
                                                            {dicoRepairProduct[
                                                                lineItems
                                                            ].order !==
                                                                undefined &&
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].order.map(
                                                                    (ord) => (
                                                                        <>
                                                                            {
                                                                                <a
                                                                                    href={
                                                                                        "https://admin.shopify.com/store/velomad/orders/" +
                                                                                        ord.id
                                                                                    }
                                                                                    rel="noreferrer"
                                                                                    target="_blank"
                                                                                >
                                                                                    {
                                                                                        ord.name
                                                                                    }
                                                                                </a>
                                                                            }
                                                                            <br />
                                                                        </>
                                                                    )
                                                                )}
                                                        </td>
                                                        <td>{props.title}</td>
                                                        <td>
                                                            {
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].name
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].price
                                                            }
                                                        </td>
                                                        <td
                                                            className={
                                                                "quantity" +
                                                                props.location
                                                            }
                                                        >
                                                            {
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].quantity
                                                            }
                                                        </td>
                                                        <td
                                                            className={
                                                                "total" +
                                                                props.location
                                                            }
                                                        >
                                                            {(
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].price *
                                                                dicoRepairProduct[
                                                                    lineItems
                                                                ].quantity
                                                            ).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </>
                                    )}
                            </tbody>
                            <tfoot className="td-footer">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {totalquantity !== 0 && totalquantity}
                                    </td>
                                    <td>{totalprice !== 0 && totalprice}</td>
                                </tr>
                            </tfoot>
                        </table>

                        <GraphRepartRepair
                            dicoRepairProduct={dicoRepairProduct}
                            title={props.title}
                        />
                    </>
                )}
        </div>
    );
}
