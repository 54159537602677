const initialState = [];

export default function panierSav(state = initialState, action) {
    switch (action.type) {
        case "ADD_PIECE_SAV":
            return [...state, action.piece];

        case "REMOVE_PIECE_SAV":
            const newState = [];
            for (let i = 0; i < state.length; i++) {
                if (state[i].id !== action.piece.id) {
                    newState.push(state[i]);
                }
            }
            if (newState.length === 0) {
                return initialState;
            }
            return newState;

        case "ERASE_PANIER":
            return initialState;

        default:
            return state;
    }
}
