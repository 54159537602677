import "./form.css";
import { useDispatch, useSelector } from "react-redux";

import {
    setEtatState,
    setGarantieState,
} from "../../Redux/actions/user.actions";
import {
    setParcoursSavStep,
    setParcoursSavTitle,
} from "../../Redux/actions/velo.actions";
import { useEffect } from "react";

export default function AboutVelo(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 360);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const modele = useSelector((state) => state.veloDetails.modele);
    const sku = useSelector((state) => state.veloDetails.sku);

    const etatState = useSelector((state) => state.user.etat);
    const garantieState = useSelector((state) => state.user.garantie);
    const dataVeloSelect = useSelector(
        (state) => state.veloDetails.product_select_velo
    );
    return (
        <>
            {modele !== undefined ? (
                <>
                    <div className="etat_small">Modèle sélectionné :</div>
                    <div> {sku}</div>
                    <div>
                        {modele !== null &&
                            modele !== undefined &&
                            modele
                                .replace("sav", "")
                                .split("#")
                                .map((element, index) => (
                                    <div key={index}>
                                        {element.replace(" - ", " ")}
                                    </div>
                                ))}
                    </div>
                    <div className="etat_small">Garantie :</div>
                    <div className="statut_garantie">
                        <div
                            onClick={() => dispatch(setGarantieState(true))}
                            className={
                                garantieState !== null && garantieState === true
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Sous Garantie{" "}
                            {props.type !== "reprise" && <>- 0€</>}
                        </div>
                        <div
                            onClick={() => dispatch(setGarantieState(false))}
                            className={
                                garantieState !== null &&
                                garantieState === false
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Hors Garantie{" "}
                            {props.type !== "reprise" && <>- Facturé</>}
                        </div>
                    </div>
                    <div className="etat_small">Etat d'Usure :</div>
                    <div className="statut_garantie ">
                        <div
                            onClick={() => dispatch(setEtatState("0km"))}
                            className={
                                etatState !== null && etatState === "0km"
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Neuf - 0km
                        </div>
                        <div
                            onClick={() => dispatch(setEtatState("utilisé"))}
                            className={
                                etatState !== null && etatState === "utilisé"
                                    ? "velo_product selected"
                                    : "velo_product"
                            }
                        >
                            Utilisé - Déjà roulé
                        </div>
                        {props.type !== "reprise" && (
                            <div
                                onClick={() =>
                                    dispatch(setEtatState("litige_transport"))
                                }
                                className={
                                    etatState !== null &&
                                    etatState === "litige_transport"
                                        ? "velo_product selected"
                                        : "velo_product"
                                }
                            >
                                Litige transport
                            </div>
                        )}
                    </div>
                    {etatState !== null && garantieState !== null && (
                        <div
                            className="btn-link-web margin-btn"
                            onClick={() => {
                                dataVeloSelect !== null &&
                                    dispatch(
                                        setParcoursSavTitle(
                                            "Choisissez vos pièces SAV"
                                        )
                                    );
                                dispatch(setParcoursSavStep(4));
                            }}
                        >
                            <button>Suivant</button>
                        </div>
                    )}
                </>
            ) : (
                <div className=" margin-30">
                    Merci de sélectionner une version de vélo dans l'onglet 2
                    précédent
                </div>
            )}
        </>
    );
}
