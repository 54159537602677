import "./footer.css";
import logoLitiboost from "../../Assets/logo_chouette_litiboost_copyright.png";

export default function Footer(props) {
    return (
        <footer>
            <a href="https://levelomad.com" rel="noreferrer noopener">
                <img
                    src={
                        "https://levelomad.com/cdn/shop/files/Group_78_2.png?v=1685629169&width=150"
                    }
                    alt="Logo - VeloMad"
                    className="logo_velo"
                />
            </a>

            <a
                href="https://litiboost.fr"
                target="_blank"
                rel="noopener noreferrer"
                className="litiboost"
            >
                {props.section} by LiTiBoost
                <img
                    src={logoLitiboost}
                    alt="LitiBoost.fr"
                    title="Logo LitiBoost.fr createur de l'application"
                />
            </a>
        </footer>
    );
}
